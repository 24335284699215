import React, { useEffect } from "react";
import { Col, Form, SelectPicker } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { usePropertyStore } from "../../contexts/PropertyStoreContext";
import {
  getRatePlansByPropertyId,
  selectRateplans,
  selectSelectedRateplan,
  setSelectedRateplan,
  selectLoading,
} from "../../slices/rateplanSlice";
import "./rateplan.scss";

const RatePlan = ({ ...props }) => {
  const { selectedProperty } = usePropertyStore();
  const rateplans = useSelector(selectRateplans);
  const selectedRateplanDetail = useSelector(selectSelectedRateplan);
  const loadingRateplans = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedProperty?.propertyId) {
      dispatch(getRatePlansByPropertyId(selectedProperty.propertyId));
    }
  }, [dispatch, selectedProperty]);

  const handleRatePlanSelect = (_, ratePlan) => {
    dispatch(setSelectedRateplan(ratePlan));
  };

  const rateplansData = rateplans?.map((item) => ({
    label: item.name,
    value: item.id,
    data: item,
  }));

  return (
    <Col {...props}>
      <Form.Group className="rateplan-container">
        <Loader loading={loadingRateplans} />
        <Form.ControlLabel className="rateplan">Rate plan</Form.ControlLabel>
        <SelectPicker
          placeholder="Choose rate plan"
          data={rateplansData}
          onSelect={handleRatePlanSelect}
          value={selectedRateplanDetail?.value}
          style={{ width: 200 }}
          cleanable={false}
          data-testid="rate-plan-picker"
          // after switching back to certain property and coming back to first property select picker does not re-render even though the selected rateplan detail value changes so we added the key for re-rendering
          key={`${selectedProperty?.propertyId} - ${selectedRateplanDetail?.value}`}
        />
      </Form.Group>
    </Col>
  );
};

export default RatePlan;
