import { Schema } from "rsuite";

const getAllDatesBetween = ([startDate, endDate]) => {
  const dateArray = [];
  const offSet = new Date().getTimezoneOffset();
  let currentDate = new Date(startDate);
  let endingDate = new Date(endDate);

  currentDate.setMinutes(currentDate.getMinutes() + offSet);
  endingDate.setMinutes(endingDate.getMinutes() + offSet);

  while (currentDate <= endingDate) {
    dateArray.push(new Date(currentDate)); // Add the current date
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return dateArray;
};

const { ArrayType, NumberType, BooleanType } = Schema.Types;
export const model = Schema.Model({
  bulkDates: ArrayType()
    .minLength(2, "Please select both start and end date.")
    .isRequired("This field is required."),
  roomTypes: ArrayType()
    .minLength(1, "Please select at least 1 roomtype")
    .isRequired("This field is required."),
  allotment: NumberType().min(1, "Minimum allotment is 1"),
  rate: NumberType().min(1, "Minimum rate is 1"),
  availability: BooleanType().isRequired("This field is required."),
});

const dateToYMD = (inputDate) => {
  const date = new Date(inputDate);
  const d = date.getDate();
  const m = date.getMonth() + 1; //Month from 0 to 11
  const y = date.getFullYear();
  return `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
};

export const getBulkUpdatePayload = (formValue, rateplanId, propertyId) => {
  const { bulkDates, roomTypes, allotment, rate, availability } = formValue;
  const formattedRate = Number(rate) ? Number(rate).toFixed(2) : rate;
  const dates = [dateToYMD(bulkDates[0]), dateToYMD(bulkDates[1])];
  const allDates = getAllDatesBetween(dates);
  const productUpdates = [];
  allDates.forEach((date) => {
    roomTypes.forEach((roomtypeId) => {
      productUpdates.push({
        rateplanId,
        roomtypeId,
        updates: [
          {
            date: dateToYMD(date),
            inventory: allotment,
            price: formattedRate,
            isOpen: availability,
          },
        ],
      });
    });
  });

  const payload = {
    propertyId: propertyId,
    dateRange: {
      startDate: dates[0],
      endDate: dates[1],
    },
    productUpdates,
  };

  return payload;
};
