import React, { useCallback, useEffect, useState } from "react";
import { Button, CheckPicker } from "rsuite";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ShowHideColumnModal from "./ReportShowHideColumnModal";
import {
  getDemandClients,
  getRatePlansByProperty,
  getRoomsByProperty,
} from "../../api";
import FilterIcon from "../../assets/img/filter.svg";

const ResReportFilters = ({
  propertyId,
  isUploadSidePanelOpen,
  isOffline,
  setIsUploadSidePanelOpen,
  exportReservation,
  isExportLoading,
  setColumns,
  setFilters,
}) => {
  const [demandClients, setDemandClients] = useState([]);
  const [rateplans, setRateplans] = useState([]);
  const [roomtypes, setRoomtypes] = useState([]);
  const [selectedDemandClients, setSelectedDemandClients] = useState([]);
  const [selectedRateplans, setSelectedRateplans] = useState([]);
  const [selectedRoomtypes, setSelectedRoomtypes] = useState([]);
  const [isShowHideColumn, setIsShowHideColumn] = useState(false);

  const getRateplans = useCallback(async () => {
    try {
      const response = await getRatePlansByProperty(propertyId);
      setRateplans(
        response.data.data.map((item) => ({
          label: item.name,
          value: item.id, // Assuming `value` should be lowercase
        }))
      );
    } catch (error) {
      toast.error(`Failed to fetch Rateplans`);
    }
  }, [propertyId]);

  const getRoomtypes = useCallback(async () => {
    try {
      const response = await getRoomsByProperty(propertyId);
      setRoomtypes(
        response.data.data.map((item) => ({
          label: item.displayName,
          value: item.displayName,
        }))
      );
    } catch (error) {
      toast.error(`Failed to fetch Roomtypes`);
    }
  }, [propertyId]);

  const getClients = useCallback(async () => {
    try {
      const response = await getDemandClients();
      setDemandClients(
        response.data.data.map((item) => ({
          label: item.clientName,
          value: item.id, // Assuming `value` should be lowercase
        }))
      );
    } catch (error) {
      toast.error(`Failed to fetch Roomtypes`);
    }
  }, []);

  useEffect(() => {
    getRateplans();
    getRoomtypes();
    getClients();
  }, [getRateplans, getRoomtypes, getClients]);

  const handleDropdownChange = (setPicker, key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setPicker(value);
  };

  const dropdownItem = (setData, key, item) => {
    return (
      <div
        title={item}
        className="rs-tag rs-tag-md rs-tag-default rs-tag-closable"
      >
        <span className="rs-tag-text">{item}</span>
        <button
          type="button"
          className="rs-tag-icon-close rs-btn-close"
          aria-label="Remove"
          tabIndex="-1"
          onClick={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              [key]: [],
            }));
            setData([]);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="currentColor"
            aria-hidden="true"
            focusable="false"
            className="rs-icon"
            aria-label="close"
            data-category="application"
          >
            <path d="m2.784 2.089.069.058 5.146 5.147 5.146-5.147a.5.5 0 0 1 .765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 0 1-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 0 1-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 0 1 .638-.765"></path>
          </svg>
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="filters">
        <span>
          <img className="icon" src={FilterIcon} alt="filter" />
          <span>Filter results</span>
        </span>
        <CheckPicker
          creatable
          data={demandClients}
          className="dropdown"
          menuStyle={{ width: 220 }}
          placeholder="Demand client"
          value={selectedDemandClients}
          onChange={handleDropdownChange(setSelectedDemandClients, "clients")}
          renderValue={(value, items) => {
            if (value.length > 1) {
              return dropdownItem(
                setSelectedDemandClients,
                "clients",
                `${value.length} clients selected`
              );
            }
            return dropdownItem(
              setSelectedDemandClients,
              "clients",
              items.map((item) => item.label).join(", ")
            );
          }}
        />
        <CheckPicker
          creatable
          data={rateplans}
          className="dropdown"
          menuStyle={{ width: 220 }}
          placeholder="Rate plan"
          value={selectedRateplans}
          onChange={handleDropdownChange(setSelectedRateplans, "rateplans")}
          renderValue={(value, items) => {
            if (value.length > 1) {
              return dropdownItem(
                setSelectedRateplans,
                "rateplans",
                `${value.length} rates selected`
              );
            }
            return dropdownItem(
              setSelectedRateplans,
              "rateplans",
              items.map((item) => item.label).join(", ")
            );
          }}
        />
        <CheckPicker
          creatable
          data={roomtypes}
          className="dropdown"
          menuStyle={{ width: 220 }}
          placeholder="Room types"
          value={selectedRoomtypes}
          onChange={handleDropdownChange(setSelectedRoomtypes, "roomtypes")}
          renderValue={(value, items) => {
            if (value.length > 1) {
              return dropdownItem(
                setSelectedRoomtypes,
                "roomtypes",
                `${value.length} rooms selected`
              );
            }
            return dropdownItem(
              setSelectedRoomtypes,
              "roomtypes",
              items.map((item) => item.label).join(", ")
            );
          }}
        />
        <div className="button-section">
          <Button appearance="ghost" onClick={() => setIsShowHideColumn(true)}>
            Show / hide columns
          </Button>
          {isOffline ? (
            <Button
              appearance="primary"
              onClick={() => setIsUploadSidePanelOpen(!isUploadSidePanelOpen)}
            >
              Upload PMS ID / CXL ID
            </Button>
          ) : (
            <Button
              appearance="primary"
              loading={isExportLoading}
              onClick={() => exportReservation()}
            >
              Download Report
            </Button>
          )}
        </div>
      </div>
      <ShowHideColumnModal
        isOffline={isOffline}
        isShowHideColumn={isShowHideColumn}
        setIsShowHideColumn={setIsShowHideColumn}
        settingColumn={setColumns}
      />
    </>
  );
};

ResReportFilters.propTypes = {
  propertyId: PropTypes.number.isRequired,
  isUploadSidePanelOpen: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  setColumns: PropTypes.func.isRequired,
  exportReservation: PropTypes.func.isRequired,
  isExportLoading: PropTypes.bool.isRequired,
  setIsUploadSidePanelOpen: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default ResReportFilters;
