import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { FlexboxGrid, ButtonGroup, Button } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import cx from "classnames";
import CalenderView from "../../components/calender-view/CalenderView";
import RatePlan from "../../components/ratePlan/RatePlan";
import ManageAri from "../../components/manageari-filters/ManageAri";
import TabularView from "../../components/tabular-view/TabularView";
import { usePropertyStore } from "../../contexts/PropertyStoreContext";
import ariSelectRateplan from "../../assets/img/ari-select-rateplan.png";
import { OFFLINE_CRS_ID } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedRateplan } from "../../slices/rateplanSlice";
import { ReactComponent as UploadIcon } from "../../assets/img/upload.svg";
import { AriProvider } from "../../contexts/AriContext";
import { fetchRoomtypes } from "../../slices/roomtypeSlice";
import { selectManageARIDateRange } from "../../slices/ariDateRangeSlice";
import "./ari.scss";

const SHE_VIEW_MODE = {
  QUICK_VIEW: "QUICK_VIEW",
  MANAGE_ARI: "MANAGE_ARI",
};

const getDefaultValueMode = () =>
  localStorage.getItem("viewMode") ?? SHE_VIEW_MODE.QUICK_VIEW;

const getLandingViewMode = (isOffline, modeInParams) => {
  if (!isOffline) {
    return SHE_VIEW_MODE.QUICK_VIEW;
  }
  if (modeInParams) {
    return modeInParams;
  }
  return getDefaultValueMode();
};

const Ari = () => {
  const { selectedProperty } = usePropertyStore();
  let navigate = useNavigate();
  let [params] = useSearchParams();
  const isOffline = selectedProperty?.crsId === OFFLINE_CRS_ID;
  const [activeView, setActiveView] = useState(
    getLandingViewMode(isOffline, params.get("viewMode"))
  );
  const selectedRateplan = useSelector(selectSelectedRateplan);
  const manageAriDateRange = useSelector(selectManageARIDateRange);
  const dispatch = useDispatch();

  // This will handle left navigation click on manageARI as current view
  useEffect(() => {
    const viewMode = getLandingViewMode(isOffline, params.get("viewMode"));
    setActiveView(viewMode);
    navigate(`/ari?viewMode=${viewMode}`, {
      replace: true,
    });
  }, [isOffline, params, navigate]);

  useEffect(() => {
    if (selectedProperty?.propertyId) {
      if (
        selectedProperty?.crsId !== OFFLINE_CRS_ID &&
        activeView === SHE_VIEW_MODE.MANAGE_ARI
      ) {
        setActiveView(SHE_VIEW_MODE.QUICK_VIEW);
        navigate(`/ari?viewMode=${SHE_VIEW_MODE.QUICK_VIEW}`, {
          replace: true,
        });
      }
    }
  }, [navigate, selectedProperty, activeView]);

  useEffect(() => {
    if (selectedProperty?.propertyId) {
      dispatch(fetchRoomtypes(selectedProperty.propertyId));
    }
  }, [selectedProperty, dispatch]);

  const handleViewChange = (view) => {
    if (view !== activeView) {
      setActiveView(view);
      navigate(`/ari?viewMode=${view}`, { replace: true });
      localStorage.setItem("viewMode", view);
    }
  };

  const renderPageView = useCallback(() => {
    if (
      !selectedRateplan ||
      (activeView === SHE_VIEW_MODE.MANAGE_ARI && !manageAriDateRange)
    ) {
      return (
        <img
          src={ariSelectRateplan}
          alt="select rateplan"
          className="mx-auto d-block"
        />
      );
    }
    if (activeView === SHE_VIEW_MODE.QUICK_VIEW) {
      return (
        <CalenderView
          propertyId={selectedProperty?.propertyId}
          rateplan={selectedRateplan}
          isOffline={isOffline}
        />
      );
    } else {
      return <TabularView rateplan={selectedRateplan} />;
    }
  }, [
    selectedRateplan,
    activeView,
    manageAriDateRange,
    selectedProperty?.propertyId,
    isOffline,
  ]);

  return (
    <Container
      id="she-home"
      data-heap-id="she-home"
      fluid
      className="she-container"
    >
      <p className="page-header" data-testid="ari-header">
        {activeView === SHE_VIEW_MODE.QUICK_VIEW
          ? "Availability, Rates & Inventory"
          : "Manage ARI"}
      </p>

      <FlexboxGrid
        justify="space-between"
        className={cx({
          "quick-view-active": activeView === SHE_VIEW_MODE.QUICK_VIEW,
          "manage-ari-active": activeView !== SHE_VIEW_MODE.QUICK_VIEW,
        })}
      >
        <FlexboxGrid.Item>
          {activeView === SHE_VIEW_MODE.QUICK_VIEW ? (
            <RatePlan data-testid="calender-rate-plan" />
          ) : (
            <ManageAri />
          )}
        </FlexboxGrid.Item>
        {isOffline && (
          <FlexboxGrid.Item>
            <ButtonGroup className="toggle-container">
              <Button
                className={cx({
                  "active-view": activeView === SHE_VIEW_MODE.QUICK_VIEW,
                })}
                onClick={() => handleViewChange(SHE_VIEW_MODE.QUICK_VIEW)}
                startIcon={<SearchIcon />}
              >
                Quick view
              </Button>
              <Button
                className={cx({
                  "active-view": activeView === SHE_VIEW_MODE.MANAGE_ARI,
                })}
                onClick={() => handleViewChange(SHE_VIEW_MODE.MANAGE_ARI)}
                startIcon={<UploadIcon />}
              >
                Manage ARI
              </Button>
            </ButtonGroup>
          </FlexboxGrid.Item>
        )}
      </FlexboxGrid>
      <AriProvider>{renderPageView()}</AriProvider>
    </Container>
  );
};

export default Ari;
