import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import TabsHT from "../../components/tabs-ht/TabsHT";
import { usePropertyStore } from "../../contexts/PropertyStoreContext";
import { useAriContext } from "../../contexts/AriContext";
import { OFFLINE_CRS_ID } from "../../constants";
import TabsFilter from "./TabsFilter";
import SoldOutBadge from "./SoldOutBadge";
import Loader from "../../components/Loader";
import {
  selectLoading,
  selectRoomtypes,
  selectSelectedRoomtype,
  setSelectedRoomtype,
} from "../../slices/roomtypeSlice";
import "./roomtypeTabs.scss";

const RoomtypeTabs = ({
  rateplanId,
  startDate,
  endDate,
  render,
  onTabSelect,
  includeNumberOfRoomsSold = false,
  onConfirmTabChange,
}) => {
  const [filteredRooms, setFilteredRooms] = useState([]);
  const { selectedProperty } = usePropertyStore();
  const { ariData, refetchAriData, loading } = useAriContext();
  const dispatch = useDispatch();

  const rooms = useSelector(selectRoomtypes);
  const loadingRooms = useSelector(selectLoading);
  const selectedRoomtype = useSelector(selectSelectedRoomtype);
  const [tabsData, setTabsData] = useState({});

  const isOffline = selectedProperty?.crsId === OFFLINE_CRS_ID;

  useEffect(() => {
    refetchAriData({
      propertyId: selectedProperty?.propertyId,
      rateplanId,
      rooms,
      startDate,
      endDate,
      includeNumberOfRoomsSold,
    });
  }, [
    rateplanId,
    rooms,
    startDate,
    endDate,
    selectedProperty,
    refetchAriData,
    includeNumberOfRoomsSold,
  ]);

  const roomsList = filteredRooms?.length > 0 ? filteredRooms : rooms;

  const handleRoomChange = (index) => {
    const roomId = roomsList[index].id;

    return new Promise((resolve) => {
      if (onConfirmTabChange) {
        onConfirmTabChange(() => {
          dispatch(setSelectedRoomtype(roomId));
          resolve();
        });
      } else {
        dispatch(setSelectedRoomtype(roomId));
        resolve();
      }
      if (onTabSelect) {
        onTabSelect();
      }
    });
  };

  useEffect(() => {
    const tabsDataMap = {};
    (filteredRooms?.length > 0 ? filteredRooms : rooms).forEach(({ id }) => {
      tabsDataMap[id] = ariData?.filter(({ roomtypeId }) => roomtypeId === id);
    });
    setTabsData(tabsDataMap);
  }, [filteredRooms, ariData, rooms]);

  if (loadingRooms) {
    return <Loader loading={loadingRooms} />;
  }

  return (
    <>
      {loading && <Loader loading={loading} />}
      <TabsHT
        initiallyActiveTab={
          roomsList.findIndex(({ id }) => id === selectedRoomtype) || 0
        }
        className={classNames("tab-content", { "with-tag-picker": !isOffline })}
      >
        <></>
        <TabsHT.TabList>
          {roomsList.map(({ id, displayName }) => {
            const currentTabData = ariData?.filter(
              ({ roomtypeId }) => roomtypeId === id
            );
            const isSoldOut = currentTabData?.some(
              ({ inventory, date, isOpen }) =>
                !inventory && isOpen && !dayjs(date).isBefore(dayjs(), "day")
            );
            return (
              <TabsHT.Tab onClick={handleRoomChange} key={id}>
                <SoldOutBadge
                  isSoldOut={isSoldOut}
                  className={"tab-soldOut-icon"}
                />
                {displayName}
              </TabsHT.Tab>
            );
          })}
        </TabsHT.TabList>
      </TabsHT>
      {selectedRoomtype ? render(tabsData[selectedRoomtype]) : null}
      {!isOffline && (
        <TabsFilter {...{ rooms, ariData, filteredRooms, setFilteredRooms }} />
      )}
    </>
  );
};

RoomtypeTabs.propTypes = {
  rateplanId: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  render: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func,
  includeNumberOfRoomsSold: PropTypes.bool,
  onConfirmTabChange: PropTypes.func,
};

export default RoomtypeTabs;
