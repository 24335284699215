import PropTypes from "prop-types";
import MessagePopOver from "../../message-popOver/MessagePopOver";
import { useIsOfflineProperty } from "../../../contexts/PropertyStoreContext";
import { formatCurrency, formatNumberOfDays } from "../../../constants/utils";
import { getTaxPopOvermessage } from "./TaxInfo";

const formatOpenStatus = (value) => {
  if (value === "N/A") {
    return value;
  } else if (value) {
    return "Yes";
  } else {
    return "No";
  }
};

const getDataValue = (data, key) => {
  if (key) {
    return data?.[key] != null ? data?.[key] : "N/A";
  }
  return data;
};

const OFFLINE_LABELS = [
  {
    label: "Min Lead Time",
    key: "minLeadTime",
    formatter: formatNumberOfDays,
  },
  {
    label: "Max Lead Time",
    key: "maxLeadTime",
    formatter: formatNumberOfDays,
  },
  {
    label: "Min Length of Stay",
    key: "minLos",
    formatter: formatNumberOfDays,
  },
  {
    label: "Max Length of Stay",
    key: "maxLos",
    formatter: formatNumberOfDays,
  },
  {
    label: "Min LoS Stay Through",
    key: "minLOSStayThrough",
    formatter: formatNumberOfDays,
  },
  {
    label: "Max LoS Stay Through",
    key: "maxLOSStayThrough",
    formatter: formatNumberOfDays,
  },
  {
    label: "Is Open To Arrival",
    key: "isOpenToArrival",
    formatter: formatOpenStatus,
  },
  {
    label: "Is Open To Departure",
    key: "isOpenToDeparture",
    formatter: formatOpenStatus,
  },
];

const ALL_LABELS = [
  {
    label: "Inventory",
    key: "inventory",
    formatter: (value) => value ?? "Sold Out!",
  },
  {
    label: "Availability",
    formatter: ({ isOpen, inventory }) => {
      if (!inventory) {
        if (inventory === 0) {
          return <b className="text-danger">Sold Out!</b>;
        } else {
          return <span>N/A</span>;
        }
      }
      return isOpen ? "Open" : "Close";
    },
  },
  {
    label: "Rate",
    key: "price",
    formatter: (value, currency) =>
      value ? formatCurrency(value, currency) : "",
  },
  {
    label: "Tax",
    key: "sourceTax",
    formatter: (value, currency, adultPrices = []) =>
      value ? (
        <span>
          {formatCurrency(value, currency)}
          <MessagePopOver message={getTaxPopOvermessage(adultPrices)} />
        </span>
      ) : (
        ""
      ),
  },
  ...OFFLINE_LABELS,
];

const AriList = ({ data, currency }) => {
  const isOffline = useIsOfflineProperty();

  return (
    <div className="container ari-list">
      {(isOffline ? OFFLINE_LABELS : ALL_LABELS).map(
        ({ label, key, formatter }) => {
          return (
            <div key={key ?? label} className="row">
              <p className="col-8 ari-list__label">{label}</p>
              <div className="col-4 ari-list__value">
                {(() => {
                  const value = getDataValue(data, key);
                  return formatter
                    ? formatter(value, currency, data.adultPrices)
                    : value;
                })()}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

AriList.propTypes = {
  data: PropTypes.shape({
    inventory: PropTypes.number,
    isOpen: PropTypes.bool,
    maxLeadTime: PropTypes.number,
    maxLos: PropTypes.number,
    minLeadTime: PropTypes.number,
    minLos: PropTypes.number,
    price: PropTypes.number,
    adultPrices: PropTypes.arrayOf(PropTypes.number),
  }),
  currency: PropTypes.string,
};

export default AriList;
