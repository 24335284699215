import { useEffect, useMemo, useState } from "react";
import { IconButton, InputPicker } from "rsuite";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { ReactComponent as SquareLeft } from "../../assets/img/square-left.svg";
import { ReactComponent as SquareRight } from "../../assets/img/square-right.svg";

const MONTH_FORMAT = "MMMM YYYY";

const CalendarNavigation = ({ currentMonth, setCurrentMonth }) => {
  const now = useMemo(() => dayjs(), []);
  const [monthOptions, setMonthOptions] = useState([]);
  const maxNoOfMonths = useMemo(() => (now.date() === 1 ? 12 : 13), [now]);

  useEffect(() => {
    const options = [];
    for (let index = 0; index < maxNoOfMonths; index++) {
      const month = now.add(index, "month").format(MONTH_FORMAT);
      options.push({ label: month, value: index });
    }

    setMonthOptions(options);
  }, [now, maxNoOfMonths]);

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");
    setCurrentMonth(plus);
  };

  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");
    setCurrentMonth(minus);
  };

  const handleChangeMonth = (value) => {
    const newMonth = now.add(value, "month");
    setCurrentMonth(newMonth);
  };

  const selectedMonthValue = useMemo(
    () => parseInt(Math.round(currentMonth.diff(now, "month", true))),
    [currentMonth, now]
  );

  const isPrevMonthDisabled = selectedMonthValue === 0;
  const isNextMonthDisabled = selectedMonthValue === maxNoOfMonths - 1;

  return (
    <div className="navigation">
      <IconButton
        aria-label="Previous month"
        className="icon"
        disabled={isPrevMonthDisabled}
        onClick={prevMonth}
        icon={<SquareLeft />}
      />
      <InputPicker
        data={monthOptions}
        value={selectedMonthValue}
        onChange={handleChangeMonth}
        cleanable={false}
      >
        {monthOptions}
      </InputPicker>
      <IconButton
        aria-label="Next month"
        className="icon"
        disabled={isNextMonthDisabled}
        onClick={nextMonth}
        icon={<SquareRight />}
      />
    </div>
  );
};

CalendarNavigation.propTypes = {
  currentMonth: PropTypes.object.isRequired,
  setCurrentMonth: PropTypes.func,
};

export default CalendarNavigation;
