import { getRoomsByProperty } from "../api";
import { createAppSlice } from "./createAppSlice";

const initialState = {
  roomtypes: [],
  loading: false,
  error: null,
  selectedRoomtype: null,
};

const roomtypeSlice = createAppSlice({
  name: "roomtype",
  initialState,
  reducers: (create) => ({
    setSelectedRoomtype(state, action) {
      state.selectedRoomtype = action.payload;
    },
    fetchRoomtypes: create.asyncThunk(
      async (propertyId, { getState }) => {
        const existingRoomtypes = roomtypeSlice
          .getSelectors()
          .selectRoomtypes(getState()[roomtypeSlice.name]);
        if (existingRoomtypes?.some((item) => item.propertyId === propertyId)) {
          return;
        }

        const response = await getRoomsByProperty(propertyId);
        return response.data.data;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          if (action.payload?.length > 0) {
            state.roomtypes = action.payload;
            state.selectedRoomtype = action.payload[0].id;
          }
        },
        rejected: (state, action) => {
          state.error = action.error;
        },
        settled: (state, _) => {
          state.loading = false;
        },
      }
    ),
  }),
  selectors: {
    selectRoomtypes: (state) => state.roomtypes,
    selectSelectedRoomtype: (state) => state.selectedRoomtype,
    selectLoading: (state) => state.loading,
  },
});

export const { fetchRoomtypes, setSelectedRoomtype } = roomtypeSlice.actions;

export const { selectRoomtypes, selectSelectedRoomtype, selectLoading } =
  roomtypeSlice.selectors;

export default roomtypeSlice;
