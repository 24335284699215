import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./infinite-scroll.scss";

const InfiniteScroll = ({
  onLoadMore,
  isLoading = false,
  hasMore = true,
  children,
  loadingComponent,
  root,
  threshold,
}) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore && !isLoading) {
          onLoadMore();
        }
      },
      { root, rootMargin: "100px", threshold: threshold || 0.1 }
    );

    const currentLoader = loaderRef.current;
    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [hasMore, isLoading, onLoadMore, root, threshold]);

  return (
    <>
      {children}
      {isLoading && loadingComponent}
      <div ref={loaderRef} className="loader-component"></div>
    </>
  );
};

InfiniteScroll.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  children: PropTypes.node.isRequired,
  loadingComponent: PropTypes.node,
  root: PropTypes.instanceOf(Element),
  threshold: PropTypes.number,
};

export default InfiniteScroll;
