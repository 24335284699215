import PropTypes from "prop-types";
import cx from "classnames";

const SoldOutBadge = ({ isSoldOut, className }) => {
  if (!isSoldOut) {
    return null;
  }

  return (
    <span
      className={cx("badge", "rounded-circle", "badge__sold-out", className)}
      data-testid="sold-out-badge"
    >
      <span className="visually-hidden">Sold out!</span>
    </span>
  );
};

SoldOutBadge.propTypes = {
  isSoldOut: PropTypes.bool,
  className: PropTypes.string,
};

export default SoldOutBadge;
