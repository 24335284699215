import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

export const DATE_FILTER_OPTIONS = [
  {
    label: "last 7 days",
    value: "LAST_7_DAYS",
  },
  {
    label: "last 30 days",
    value: "LAST_30_DAYS",
  },
  {
    label: "last 90 days",
    value: "LAST_90_DAYS",
  },
  {
    label: "last 365 days",
    value: "LAST_365_DAYS",
  },
];

export const calculateDates = (selectedOption) => {
  const endDate = new Date();
  let startDate = new Date();

  // Calculate start date based on selected option
  switch (selectedOption) {
    case "LAST_7_DAYS":
      startDate.setDate(endDate.getDate() - 6); // Subtract 6 days to get 7 days ago
      break;
    case "LAST_30_DAYS":
      startDate.setDate(endDate.getDate() - 29); // Subtract 29 days to get 30 days ago
      break;
    case "LAST_90_DAYS":
      startDate.setDate(endDate.getDate() - 89); // Subtract 89 days to get 90 days ago
      break;
    case "LAST_365_DAYS":
      startDate.setDate(endDate.getDate() - 364); // Subtract 364 days to get 365 days ago
      break;
    default:
      break;
  }

  // Format dates to 'yyyy-mm-dd'
  const formattedStartDate = startDate.toISOString().split("T")[0];
  const formattedEndDate = endDate.toISOString().split("T")[0];

  return { startDate: formattedStartDate, endDate: formattedEndDate };
};

export const formatDate = (inputDate) => {
  if (!inputDate) return null;

  const date = new Date(inputDate);
  const d = date.getDate();
  const m = date.getMonth() + 1; //Month from 0 to 11
  const y = date.getFullYear();
  return `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
};

export const calculatePreviousDateRange = (
  currentStartDate,
  currentEndDate
) => {
  const startDate = new Date(currentStartDate);
  const endDate = new Date(currentEndDate);
  const prevEndDate = new Date(startDate.getTime() - 1); // Previous end date is one day before the current start date
  const prevStartDate = new Date(prevEndDate.getTime() - (endDate - startDate)); // Previous start date is the same duration before the previous end date as the current date range

  // Format dates to 'yyyy-MM-dd'
  const formattedPrevStartDate = prevStartDate.toISOString().split("T")[0];
  const formattedPrevEndDate = prevEndDate.toISOString().split("T")[0];

  return { startDate: formattedPrevStartDate, endDate: formattedPrevEndDate };
};

export const getRoundToTwoDecimals = (value, noOfDecimals = 2) => {
  if (isNaN(value)) return value;
  return (
    Math.round(value * Math.pow(10, noOfDecimals)) / Math.pow(10, noOfDecimals)
  );
};

export const deepClone = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    return obj;
  }
};

export const getPowerBIPropertyFilter = (propertyCode) => ({
  $schema: "https://powerbi.com/product/schema#basic",
  target: {
    table: "dimProperty",
    column: "code",
  },
  operator: "In",
  values: [propertyCode],
  displaySettings: { isLockedInViewMode: true },
});

export const formatNumberOfDays = (value) => {
  if (value === "N/A") {
    return value;
  }
  if (isNaN(value)) {
    return "Unlimited";
  }
  const dayLabel = value > 1 ? "days" : "day";
  return `${value} ${dayLabel}`;
};

export const safeParseJson = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
};

export const getDiscountValueFromRule = (rule, currency) => {
  if (!rule) return null;
  const { discountVO = {} } = rule.ruleVO || {};
  const {
    percentDiscountAmount,
    fixedDiscountAmount,
    fixedDiscountMap,
    percentDiscountMap,
    mCompDiscountType,
    discountType,
  } = discountVO;

  let discountAmount;
  if (discountType && discountType === 1) {
    discountAmount = getDiscountForType1(
      mCompDiscountType,
      fixedDiscountMap,
      percentDiscountMap,
      currency
    );
  } else if (discountType && discountType === 4) {
    if (mCompDiscountType && mCompDiscountType === 1) {
      discountAmount = formatCurrency(fixedDiscountAmount, currency);
    } else if (mCompDiscountType && mCompDiscountType === 2) {
      discountAmount = `${percentDiscountAmount}%`;
    }
  }

  return discountAmount;
};

const getDiscountForType1 = (
  mCompDiscountType,
  fixedDiscountMap,
  percentDiscountMap,
  currency
) => {
  let discountAmount;
  if (mCompDiscountType && mCompDiscountType === 1) {
    if (fixedDiscountMap) {
      const fixedDiscountValues = Object.values(fixedDiscountMap);
      const totalFixedDiscountAmount = fixedDiscountValues.reduce(
        (previous, current) => current + previous
      );
      const amount = (
        totalFixedDiscountAmount / fixedDiscountValues.length
      ).toFixed(2);
      discountAmount = `${formatCurrency(amount, currency)}*`;
    }
  } else if (mCompDiscountType && mCompDiscountType === 2) {
    if (percentDiscountMap === null) {
      const percentDiscountValues = Object.values(percentDiscountMap);
      const totalPercentDiscountAmount = percentDiscountValues.reduce(
        (previous, current) => current + previous
      );
      discountAmount = `${(
        totalPercentDiscountAmount / percentDiscountValues.length
      ).toFixed(2)}%*`;
    }
  }
  return discountAmount;
};

export const getAppliesToRule = (rule) => {
  if (!rule) return {};
  const { ruleVO = {} } = rule;
  const { stayVO = {}, bookingVO = {}, discountVO } = ruleVO;
  const { discountTypeDescription } = discountVO || {};
  const { stayStartDate, stayEndDate, minLengthOfStay, maxLengthOfStay } =
    stayVO;
  const { bookingStartDate, bookingEndDate, minBookingLeadTime } = bookingVO;

  return {
    bookingStartDate,
    bookingEndDate,
    stayStartDate,
    stayEndDate,
    minLengthOfStay,
    maxLengthOfStay,
    minBookingLeadTime,
    discountTypeDescription,
  };
};

export function formatDateString(dateString) {
  const date = dayjs(dateString);
  const today = dayjs();
  const isToday = date.isSame(today, "day");
  const timeString = date.format("h.mm a");

  return isToday ? `Today, ${timeString}` : date.format("MMM D");
}

export const formatCurrency = (value, currency) => {
  if (isNaN(value) || !currency) return value;

  // Create our number formatter.
  const formatter = new Intl.NumberFormat(
    navigator.language.includes("en") ? "en-US" : navigator.language,
    {
      style: "currency",
      currency,
      // trailingZeroDisplay: "stripIfInteger",
      // It will only stop printing
      // the fraction when the input
      // amount is a round number (int)
      // already.
    }
  );

  return formatter.format(value);
};

export const getLoggedInUserId = () => {
  const token = localStorage.getItem("ROCP_idToken");
  return Number(jwtDecode(token)["_id"]);
};
