import React, { useCallback, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { Button, Col, Drawer, Message, Row } from "rsuite";
import { getReservations, getReservationsExport, repushVcc } from "../../api";
import Table from "../../components/common/table/Table";
import { formatDate } from "../../constants/utils";
import { usePropertyStore } from "../../contexts/PropertyStoreContext";
import ResReportForm from "./resReportForm";
import UploadPanel from "./uploadPms";
import ResReportFilters from "./ResReportFilters";
import {
  DEFAULT_COLUMNS,
  INIT_FORM_VALUE,
  DEFAULT_PAGE_SIZE,
  cancelledData,
  searchFields,
} from "./utils";
import "./resReport.scss";
import { OFFLINE_CRS_ID } from "../../constants";

const { Column, HeaderCell, Cell } = Table;

const ResReport = () => {
  const { selectedProperty } = usePropertyStore();

  const [isOffline, setIsOffline] = useState(
    selectedProperty.crsId === OFFLINE_CRS_ID
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [formValue, setFormValue] = useState(INIT_FORM_VALUE);
  const [filters, setFilters] = useState({
    clients: [],
    rateplans: [],
    roomtypes: [],
  });
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);
  const [isEdit, setIsEdit] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [vccRepushed, setVccRepushed] = useState([]);
  const [loadingRepush, setLoadingRepush] = useState(null);
  const [isUploadSidePanelOpen, setIsUploadSidePanelOpen] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [filteredFields, setFilteredFields] = useState([]);
  const [reservationsPage, setReservationsPage] = useState({
    size: DEFAULT_PAGE_SIZE,
    number: 0,
    totalElements: 10,
    totalPages: 1,
  });

  const getFormData = useCallback(() => {
    if (isEdit || isDrawerOpen) return;
    const formData = {
      page: {
        size: DEFAULT_PAGE_SIZE,
        number: reservationsPage.number,
      },
      propertyIds: [selectedProperty?.propertyId],
    };

    const {
      lastName,
      firstName,
      htConfirmationCode,
      pmsId,
      sourceRoomConfirmationNumber,
      cancelledReservation,
      checkIn,
      checkOut,
      bookingDate,
    } = formValue;

    if (lastName) {
      formData.guestLastName = lastName;
    }
    if (firstName) {
      formData.guestFirstName = firstName;
    }
    if (htConfirmationCode) {
      formData.confirmationCode = htConfirmationCode;
    }
    if (pmsId) {
      formData.pmsId = pmsId;
    }
    if (sourceRoomConfirmationNumber) {
      formData.sourceRoomConfirmationNumber = sourceRoomConfirmationNumber;
    }
    if (cancelledReservation) {
      formData.cancelled = cancelledReservation;
    }
    if (checkIn) {
      formData.checkinStartDate = formatDate(checkIn[0]);
      formData.checkinEndDate = formatDate(checkIn[1]);
    }
    if (checkOut) {
      formData.checkoutStartDate = formatDate(checkOut[0]);
      formData.checkoutEndDate = formatDate(checkOut[1]);
    }
    if (bookingDate) {
      formData.bookingStartDate = formatDate(bookingDate[0]);
      formData.bookingEndDate = formatDate(bookingDate[1]);
    }
    if (filters.clients.length) {
      formData.demandClientIds = filters.clients;
    }
    if (filters.rateplans.length) {
      formData.rateplanIds = filters.rateplans;
    }
    if (filters.roomtypes.length) {
      formData.roomTypes = filters.roomtypes;
    }

    return formData;
  }, [
    isEdit,
    isDrawerOpen,
    formValue,
    reservationsPage.number,
    selectedProperty?.propertyId,
    filters,
  ]);

  const getReservationReport = useCallback(async () => {
    setIsLoading(true);
    try {
      const formData = getFormData();
      if (!formData) return;
      // calling api
      const response = await getReservations(formData);
      const result = response.data;
      const pageSize = response.data.page.size;
      if (result?.content) {
        const data = result.content.map((item) => {
          const repushLoading = false;
          const statusClass =
            String(item.status).toLowerCase() === "active"
              ? "active"
              : "cancelled";
          const isRepush = item.payablePaymentType !== "DIRECT_BILL";
          let repushVcc = null;
          if (isRepush) {
            repushVcc = vccRepushed.includes(item.htConfirmationCode) ? (
              <Message className="repush-success" showIcon type="success">
                Requested
              </Message>
            ) : (
              <Button
                className="repush"
                loading={loadingRepush === item.htConfirmationCode}
                onClick={() => setLoadingRepush(item.htConfirmationCode)}
              >
                Repush VCC
              </Button>
            );
          }
          return {
            ...item,
            status: (
              <span className={`status ${statusClass}`}>{item.status}</span>
            ),
            payable: item.payable ? Number(item.payable).toFixed(2) : "",
            payableTotal: item.payableTotal
              ? Number(item.payableTotal).toFixed(2)
              : "",
            tax: item.tax ? Number(item.tax).toFixed(2) : "",
            statusValue: item.status,
            repushLoading,
            repushVcc,
          };
        });
        data.forEach((item, index) => {
          item.serialNumber = index + 1 + reservationsPage.number * pageSize;
        });
        setReservations(data);
      }
      if (result?.page) {
        setReservationsPage(result.page);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [getFormData, vccRepushed, loadingRepush, reservationsPage.number]);

  const exportReservation = async () => {
    setIsExportLoading(true);
    try {
      const formData = getFormData();
      if (!formData) return;
      columns.forEach((column) => {
        if (column.exportKey) {
          formData[column.exportKey] = true;
        }
      });
      // calling api
      const response = await getReservationsExport(formData);
      let filename = response.headers["content-disposition"];

      if (!filename) {
        toast.success("Exported file will be mailed!");
        setIsExportLoading(false);
        return;
      }
      filename = filename?.split("filename=")[1]?.replace(/"/g, "");
      const url = window.URL.createObjectURL(
        new Blob([response.body], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsExportLoading(false);
    }
  };

  const handleRepushVcc = useCallback(
    async (confirmationCode) => {
      setLoadingRepush(confirmationCode);
      try {
        const token = localStorage.getItem("ROCP_idToken");
        const username = jwtDecode(token).username;
        await repushVcc(confirmationCode, username);
        toast.success("Successfully repushed");
        setVccRepushed([...vccRepushed, confirmationCode]);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoadingRepush(null);
      }
    },
    [vccRepushed]
  );

  useEffect(() => {
    setIsEdit(true);
    setIsOffline(selectedProperty.crsId === OFFLINE_CRS_ID);
    setFilters({
      clients: [],
      rateplans: [],
      roomtypes: [],
    });
  }, [selectedProperty]);

  useEffect(() => {
    if (loadingRepush) {
      handleRepushVcc(loadingRepush);
    }
  }, [loadingRepush, handleRepushVcc]);

  useEffect(() => {
    if (!isEdit) getReservationReport();
  }, [getReservationReport, isEdit]);

  const onPageChange = (page, pageSize) => {
    setReservationsPage((prevState) => ({
      ...prevState,
      number: page - 1,
      size: pageSize,
    }));
  };

  const formSubmitted = (formValues) => {
    setIsEdit(false);
    setIsDrawerOpen(false);
    const searchedFields = [];
    for (const key in formValues) {
      if (formValues[key]) {
        let val = formValues[key];
        if (key === "cancelledReservation") {
          val = cancelledData[formValues[key]].label;
        }
        if (["checkIn", "checkOut", "bookingDate"].includes(key)) {
          val = `${formatDate(formValues[key][0])} - ${formatDate(formValues[key][1])}`;
        }
        const obj = { ...searchFields[key], value: val };
        searchedFields.push(obj);
      }
    }
    setFilteredFields(searchedFields);
  };

  const searchedData = filteredFields.map((field, idx) => {
    const step = idx + 1;
    return (
      <Col key={step} className="searched-field">
        <div className="icon">{<field.icon />}</div>
        <div className="data">
          <span className="label">{field.label}</span>
          <span className="field">{field.value}</span>
        </div>
      </Col>
    );
  });

  return (
    <Container
      id="res-report"
      data-heap-id="res-report"
      fluid
      className="res-container"
    >
      {isEdit ? (
        <ResReportForm
          isLoading={isLoading}
          isOffline={isOffline}
          formSubmitted={formSubmitted}
          cancelledData={cancelledData}
          setReservationsPage={setReservationsPage}
          initFormValue={INIT_FORM_VALUE}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      ) : (
        <>
          <Drawer
            placement="top"
            open={isDrawerOpen}
            closeButton={false}
            onClose={() => setIsEdit(false)}
          >
            <Drawer.Body className="res-drawer">
              <ResReportForm
                isLoading={isLoading}
                isOffline={isOffline}
                formSubmitted={formSubmitted}
                cancelledData={cancelledData}
                setReservationsPage={setReservationsPage}
                initFormValue={INIT_FORM_VALUE}
                formValue={formValue}
                setFormValue={setFormValue}
                setIsDrawerOpen={setIsDrawerOpen}
                source="drawer"
              />
            </Drawer.Body>
          </Drawer>

          <p className="page-header"> Reservation Report</p>
          <Row>
            {searchedData}
            <Col xs={2}>
              <Button appearance="ghost" onClick={() => setIsDrawerOpen(true)}>
                Edit search
              </Button>
            </Col>
          </Row>
          <div className="reservation-data">
            <div className="reservation-data-body">
              <ResReportFilters
                propertyId={selectedProperty?.propertyId}
                isOffline={isOffline}
                isUploadSidePanelOpen={isUploadSidePanelOpen}
                exportReservation={exportReservation}
                isExportLoading={isExportLoading}
                setIsUploadSidePanelOpen={setIsUploadSidePanelOpen}
                setFilters={setFilters}
                setColumns={setColumns}
              />
              <Table
                data={reservations}
                loading={isLoading}
                paginated
                onPageChange={onPageChange}
                pageSizeOptions={[10, 20, 30]}
                total={reservationsPage.totalElements}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                currentPage={reservationsPage.number + 1}
                rowDisabledHandler={(rowData, index) =>
                  index > -1 && !rowData?.active
                }
              >
                {columns.map((column) => {
                  const { label, dataKey, width, header, ...rest } = column;
                  return (
                    <Column key={label} width={width}>
                      <HeaderCell>{header}</HeaderCell>
                      <Cell dataKey={dataKey} {...rest}>
                        {(rowData) => {
                          let title = rowData[dataKey];
                          if (dataKey === "repushVcc") {
                            title = "Repush VCC";
                          }
                          if (dataKey === "status") {
                            title = rowData["statusValue"];
                          }
                          return <div title={title}>{rowData[dataKey]}</div>;
                        }}
                      </Cell>
                    </Column>
                  );
                })}
              </Table>
              <UploadPanel
                isUploadSidePanelOpen={isUploadSidePanelOpen}
                exportReservation={exportReservation}
                isExportLoading={isExportLoading}
                getReservationReport={getReservationReport}
                setIsUploadSidePanelOpen={setIsUploadSidePanelOpen}
                className={`upload-panel ${isUploadSidePanelOpen ? "show" : ""}`}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default ResReport;
