import React, { useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import TabsContext from "./TabsContext";
import "./tabs.scss";

const Tab = ({ children, className, index, onClick, style }) => {
  const ref = useRef(null);
  const { activeTab, focusedTab, setFocusedTab, setActiveTab } =
    useContext(TabsContext);
  const isActive = activeTab === index;

  /**
   * Focus the tab based on focusedTab provided by the context
   */
  useEffect(() => {
    if (focusedTab === index) {
      ref.current.focus({ preventScroll: true });
    }
  }, [focusedTab, index]);

  /**
   * Set the tab as active when clicked
   */
  const onTabClick = (index) => {
    if (onClick) {
      onClick(index).then(() => {
        setActiveTab(index);
        setFocusedTab(index);
      });
    } else {
      setActiveTab(index);
      setFocusedTab(index);
    }
  };

  /**
   * Set the tab as active when enter is pressed
   * @param event
   */
  const onKeyDown = (event) => {
    const isEnter = event.key === "Enter" || event.keyCode === 13;
    const isSpace = event.key === " " || event.keyCode === 32;
    if (isEnter || isSpace) {
      if (onClick) {
        onClick();
      }
      setActiveTab(index);
    }
  };

  /**
   * Handle the focus event
   */
  const onFocus = (event) => {
    event.preventDefault();
    if (focusedTab !== index) {
      setFocusedTab(index);
    }
  };

  return (
    <li
      tabIndex={isActive ? 0 : -1}
      id={`tab-${index}-button`}
      className={classNames("ht-tab", isActive && "active", className)}
      style={style}
      onClick={() => onTabClick(index)}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      ref={ref}
    >
      <span>{children}</span>
    </li>
  );
};
Tab.componentName = "Tab";

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Tab;
