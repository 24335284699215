import React, { isValidElement } from "react";
import { flatten } from "lodash";

/**
 * Get the child element based on it's index
 * @param index The index of the child
 * @param parent The parent element
 */
export const getChildByIndex = (index, parent) => {
  if (index !== null) {
    const element = parent.children[index];
    if (element !== null && element instanceof HTMLElement) {
      return element;
    }
  }
  return null;
};

/**
 * Check if the tab element is outside the right edge of the container
 * @param tab The tab element
 * @param container The container element
 * @param offset The scroll offset
 */
export const isElementOutsideRightEdge = (tab, container, offset) => {
  const tabElement = tab;
  const containerElement = container;
  const tabDistance = tabElement.offsetLeft + tabElement.offsetWidth;
  return tabDistance > containerElement.offsetWidth + offset;
};

/**
 * Check if the tab element is outside the left edge of the container
 * @param tab The tab element
 * @param offset The scroll offset
 */
export const isElementOutsideLeftEdge = (tab, offset) => {
  const tabElement = tab;
  const tabDistance = tabElement.offsetLeft;
  return tabDistance < offset;
};

/**
 * Returns the children as a flat array with keys assigned to each child
 * @param children  Children
 */
export const getChildrenAsArray = (children) => {
  if (children === undefined) {
    return [];
  }

  return Array.isArray(children) ? children : [children];
};

/**
 * Filters out a component from the children and returns it and the children without the filtered out component
 * @param children      Children
 * @param componentName Name of the component that should be filtered out
 */
export const getComponentFromChildren = (children, componentName) => {
  // copy and ensure that children is an array
  const childrenAsArray = [...getChildrenAsArray(children)];
  const componentIndex = childrenAsArray.findIndex((child) => {
    return isValidElement(child) && child.type?.componentName === componentName;
  });
  const component =
    componentIndex >= 0 ? childrenAsArray.splice(componentIndex, 1) : [];

  // return the component and the children without the removed component
  return [component, childrenAsArray];
};

/**
 * Get the child elements even if there is a parent container element.
 * @param children
 */
export const getChildElementsEvenIfContainersInbetween = (children) => {
  const arrayChildren = [...getChildrenAsArray(children)];
  const childrenHasContainer =
    arrayChildren.length === 1 &&
    React.isValidElement(arrayChildren[0]) &&
    Boolean(arrayChildren[0].props.children) &&
    (React.isValidElement(arrayChildren[0].props.children) ||
      Array.isArray(arrayChildren[0].props.children));

  if (childrenHasContainer && React.isValidElement(arrayChildren[0])) {
    return flatten(arrayChildren[0].props.children);
  }

  return flatten(arrayChildren);
};
