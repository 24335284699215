import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAuth } from "./AuthContext";
import { getProperties } from "../api";
import Loader from "../components/Loader";
import { OFFLINE_CRS_ID } from "../constants";
import { setSelectedRoomtype } from "../slices/roomtypeSlice";
import { resetDateRanges } from "../slices/ariDateRangeSlice";

export const PropertyStoreContext = React.createContext({});

export const PropertyStoreProvider = React.memo(function PropertyStoreProvider({
  children,
}) {
  const { idToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const dispatch = useDispatch();

  const getAllProperties = useCallback(() => {
    setLoading(true);
    getProperties().then(
      (res) => {
        setLoading(false);
        const properties = res?.data?.data;
        setProperties(properties);
        setSelectedProperty(properties[0] ?? null);
      },
      () => {
        setLoading(false);
        toast.error("Unable to load property, please try again later");
      }
    );
  }, []);

  useEffect(() => {
    if (idToken) {
      getAllProperties();
    }
  }, [idToken, getAllProperties]);

  const handlePropertyChange = useCallback(
    (property) => {
      dispatch(setSelectedRoomtype(null));
      dispatch(resetDateRanges());
      setSelectedProperty(property);
    },
    [dispatch]
  );

  const contextValue = useMemo(
    () => ({
      selectedProperty,
      setSelectedProperty: handlePropertyChange,
      properties,
    }),
    [properties, selectedProperty, handlePropertyChange]
  );

  return (
    <PropertyStoreContext.Provider value={contextValue}>
      {<Loader loading={loading} />}
      {children}
    </PropertyStoreContext.Provider>
  );
});

export const usePropertyStore = () => useContext(PropertyStoreContext);

export const useIsOfflineProperty = () => {
  const { selectedProperty: { crsId } = {} } = usePropertyStore();
  return crsId === OFFLINE_CRS_ID;
};
