import React from "react";
import { Row, Col } from "rsuite";
import cx from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as WarningIcon } from "./../../../assets/img/warning-info.svg";
import { ReactComponent as SuccessIcon } from "./../../../assets/img/green-tick.svg";
import "./right-panel.scss";

const AllotmentMessage = ({ allotmentError }) => {
  return allotmentError.message ? (
    <Row
      className={cx(
        "allotment-message",
        allotmentError.isError ? "allotment-warning" : "allotment-success"
      )}
    >
      <Col xs={3} className="warning-icon">
        {allotmentError.isError ? <WarningIcon /> : <SuccessIcon />}
      </Col>
      <Col xs={21} className="warning-text">
        {allotmentError.message}
      </Col>
    </Row>
  ) : null;
};

AllotmentMessage.propTypes = {
  allotmentError: PropTypes.shape({
    isBlocker: PropTypes.bool,
    isError: PropTypes.bool,
    message: PropTypes.string,
  }),
};

export default AllotmentMessage;
