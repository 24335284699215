export function dateToYMD(date) {
  const y = date.getFullYear();
  const m = date.getMonth() + 1; //Month from 0 to 11
  const d = date.getDate();
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}

export const FIELDS = [
  { key: "day", label: "Day", type: "text", width: 125 },
  { key: "date", label: "Date", type: "text", width: 120 },
  { key: "currency", label: "Currency", type: "text", width: 80 },
  {
    key: "price",
    label: "Rate (before tax and promo)",
    type: "inputDecimal",
    width: 120,
  },
  { key: "sourceTax", label: "Tax", type: "text", width: 90 },
  { key: "isOpen", label: "Availability", type: "toggle", width: 95 },
  { key: "inventory", label: "Allotment", type: "input", width: 90 },
  { key: "minLos", label: "Min LOS", type: "input", width: 80 },
  { key: "maxLos", label: "Max LOS", type: "input", width: 80 },
  { key: "minLeadTime", label: "Min LT", type: "input", width: 80 },
];

export const getDefaultCurrentData = (roomtypeId) => {
  return [
    {
      roomtypeId: roomtypeId,
      date: "unless excluded",
      day: "Applies to all rows",
      price: null,
      tax: null,
      isOpen: true,
      inventory: null,
      minLos: null,
      maxLos: null,
      minLeadTime: null,
      isCustomRow: true,
    },
  ];
};

export const hasAriTobeUpdate = (currentData, previousData) => {
  let hasChanged = false;
  currentData.sort((a, b) => (a.date > b.date ? 1 : -1));
  previousData.sort((a, b) => (a.date > b.date ? 1 : -1));
  if (currentData.length !== previousData.length) {
    hasChanged = true;
  } else {
    const keysToEvaluate = [
      "price",
      "isOpen",
      "inventory",
      "minLos",
      "maxLos",
      "minLeadTime",
    ];
    for (let i = 0; i < currentData.length; i++) {
      for (let key of keysToEvaluate) {
        if (hasFieldChanged(currentData[i][key], previousData[i][key], key))
          hasChanged = true;
      }
    }
  }
  return hasChanged;
};

const hasFieldChanged = (currentField, previousField, key) => {
  if (currentField == null) return false;

  if (key === "price") {
    return parseFloat(currentField.toString()) !== parseFloat(previousField);
  } else {
    return currentField !== previousField;
  }
};
