import { HStack, List, Popover, Text, Whisper } from "rsuite";
import PropTypes from "prop-types";
import InfoIcon from "../../assets/img/info.svg";
import { getAppliesToRule } from "../../constants/utils";
import dayjs from "dayjs";

const DiscountPopover = ({ rule, promo, discountValue }) => {
  const { ruleName } = promo;
  const {
    bookingStartDate,
    bookingEndDate,
    stayStartDate,
    stayEndDate,
    minLengthOfStay,
    maxLengthOfStay,
    minBookingLeadTime,
    discountTypeDescription,
  } = getAppliesToRule(rule);
  const speaker = (
    <Popover title={<p className="popover-title">{ruleName}</p>}>
      <List divider={false} size="xs">
        <List.Item>
          <HStack>
            <Text className="popover-content">Discount:</Text>
            <Text className="popover-content">{discountValue}</Text>
          </HStack>
        </List.Item>
        {bookingStartDate && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Booking Start Date:</Text>
              <Text className="popover-content">
                {dayjs(bookingStartDate).format("Do MMM YYYY")}
              </Text>
            </HStack>
          </List.Item>
        )}
        {bookingEndDate && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Booking End Date:</Text>
              <Text className="popover-content">
                {dayjs(bookingEndDate).format("Do MMM YYYY")}
              </Text>
            </HStack>
          </List.Item>
        )}
        {stayStartDate && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Stay Start Date:</Text>
              <Text className="popover-content">
                {dayjs(stayStartDate).format("Do MMM YYYY")}
              </Text>
            </HStack>
          </List.Item>
        )}
        {stayEndDate && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Stay End Date:</Text>
              <Text className="popover-content">
                {dayjs(stayEndDate).format("Do MMM YYYY")}
              </Text>
            </HStack>
          </List.Item>
        )}
        {(minLengthOfStay || minLengthOfStay === 0) && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Min Length of Stay:</Text>
              <Text className="popover-content">{minLengthOfStay}</Text>
            </HStack>
          </List.Item>
        )}
        {(maxLengthOfStay || maxLengthOfStay === 0) && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Max Length of Stay:</Text>
              <Text className="popover-content">{maxLengthOfStay}</Text>
            </HStack>
          </List.Item>
        )}
        {(minBookingLeadTime || minBookingLeadTime === 0) && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Min Booking Lead Time:</Text>
              <Text className="popover-content">{minBookingLeadTime}</Text>
            </HStack>
          </List.Item>
        )}
        {discountTypeDescription && (
          <List.Item>
            <HStack>
              <Text className="popover-content">Discount Type:</Text>
              <Text className="popover-content">{discountTypeDescription}</Text>
            </HStack>
          </List.Item>
        )}
      </List>
    </Popover>
  );

  return (
    <Whisper placement="autoVerticalEnd" trigger="hover" speaker={speaker}>
      <img src={InfoIcon} className="info-icon" alt="info" />
    </Whisper>
  );
};

DiscountPopover.propTypes = {
  rule: PropTypes.object.isRequired,
  promo: PropTypes.shape({
    ruleName: PropTypes.string,
  }),
  ruleName: PropTypes.string.isRequired,
  discountValue: PropTypes.string.isRequired,
};

export default DiscountPopover;
