import React from "react";
import { Container, Row, Col, Whisper, Tooltip } from "rsuite";
import PropTypes from "prop-types";
import PromotionSummary from "../../../components/promotion-summary/PromotionSummary";
import cx from "classnames";
import BulkUpdate from "../../bulk-update/bulkUpdate";
import CalendarGuide from "../../calendar-guide/calendarGuide";
const BulkUpdateRightPanel = ({
  rateplan,
  promos,
  className = "",
  isOffline,
  startDate,
  endDate,
}) => {
  const calendarGuideClassName = !isOffline ? "with-bulk-update" : "";
  return (
    <Container className={cx("right-panel-container", className)}>
      <Row className="right-panel-header-one">
        <Col className={cx("header-rateplan-name", "bulk-header")}>
          <Whisper
            trigger="hover"
            placement="topStart"
            speaker={<Tooltip>{rateplan?.label}</Tooltip>}
          >
            <span>{rateplan?.label}</span>
          </Whisper>
        </Col>
      </Row>
      <Row className="right-panel-header-two">
        <Col className="header-rateplan-name">
          <span>{rateplan?.data?.code}</span>
        </Col>
      </Row>
      <PromotionSummary promos={promos} rateplan={rateplan} />
      {isOffline ? (
        <BulkUpdate
          rateplan={rateplan}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}
      <CalendarGuide className={calendarGuideClassName} />
    </Container>
  );
};

BulkUpdateRightPanel.propTypes = {
  rateplan: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    data: PropTypes.shape({ code: PropTypes.string }),
  }),
  promos: PropTypes.array,
  className: PropTypes.string,
  isOffline: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default BulkUpdateRightPanel;
