import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Toggle } from "rsuite";
import dayjs from "dayjs";
import {
  useIsOfflineProperty,
  usePropertyStore,
} from "../../contexts/PropertyStoreContext";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import LoaderModal from "../loader-modal/LoaderModal";
import { updateARI } from "../../api";
import { selectSelectedRateplanCurrency } from "../../slices/rateplanSlice";
import { formatCurrency } from "../../constants/utils";
import { useAriContext } from "../../contexts/AriContext";
import { selectQuickViewDateRange } from "../../slices/ariDateRangeSlice";
import { selectRoomtypes } from "../../slices/roomtypeSlice";

const DATE_FORMAT = "ddd D";

const getOpenLabel = (isOpen) => {
  return isOpen ? "Open" : "Closed";
};

const InventoryStatus = ({ inventory, isOpen }) => {
  const formatInventoryStatus = (inventory, isOpen) => {
    if (!inventory) {
      return inventory === 0 ? "Sold out!" : "";
    }
    return getOpenLabel(isOpen);
  };
  return <span>{formatInventoryStatus(inventory, isOpen)}</span>;
};

const DateCell = ({
  isPastDay,
  date,
  isSelected,
  onDateClick,
  isCurrentMonth,
  isGreaterThanOneYear,
  ariData,
  dayDiff,
}) => {
  const currency = useSelector(selectSelectedRateplanCurrency);
  const { price, inventory, isOpen, minLeadTime } = ariData || {};
  const isRestricted = !isPastDay && dayDiff < minLeadTime;
  const rate =
    price === null || price === undefined
      ? "N/A"
      : formatCurrency(price, currency);
  let inventoryText = inventory ?? 0;
  if (isRestricted) {
    inventoryText = `Min. LT - ${minLeadTime} days`;
  }
  const isDisabled = isPastDay || isGreaterThanOneYear || !isCurrentMonth;

  const [isOpened, setIsOpened] = useState(isOpen);
  const [showModal, setShowModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const isOffline = useIsOfflineProperty();
  const { refetchAriData } = useAriContext();
  const { selectedProperty } = usePropertyStore();
  const currentMonth = useSelector(selectQuickViewDateRange);
  const rooms = useSelector(selectRoomtypes);

  useEffect(() => {
    setIsOpened(isOpen);
  }, [isOpen]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handleDateClick();
    }
  };

  const handleDateClick = () => {
    if (isCurrentMonth && !isDisabled) {
      onDateClick({ date, ariData });
    }
  };

  const handleToggleOpen = (_, event) => {
    event.stopPropagation();
    if (!isDisabled && !isRestricted) {
      setShowModal(true);
      setConfirmationMessage(
        `You’re about to ${isOpen ? "close" : "open"} availability for ${date.format("ddd Do MMMM")}. Are you happy to continue?`
      );
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setShowModal(false);
    setShowLoader(true);

    const payload = {
      propertyId: ariData.propertyId,
      dateRange: {
        startDate: date.format("YYYY-MM-DD"),
        endDate: date.format("YYYY-MM-DD"),
      },
      productUpdates: [
        {
          rateplanId: ariData.rateplanId,
          roomtypeId: ariData.roomtypeId,
          updates: [{ ...ariData, isOpen: !isOpened }],
        },
      ],
    };

    updateARI(payload)
      .then(() => {
        setTimeout(() => {
          const startDate = currentMonth.startOf("month").format("YYYY-MM-DD");
          const endDate = currentMonth.endOf("month").format("YYYY-MM-DD");
          refetchAriData({
            propertyId: selectedProperty?.propertyId,
            rateplanId: ariData.rateplanId,
            rooms,
            startDate,
            endDate,
            includeNumberOfRoomsSold: true,
          });
          toast.success("Successfully updated.");
          onDateClick({ date, ariData: { ...ariData, isOpen: !isOpened } });
          setShowLoader(false);
          setIsOpened(!isOpened);
        }, 2000);
      })
      .catch(() => {
        setShowLoader(false);
        toast.error("Failed to save updates.");
      });
  };

  const status = useMemo(() => {
    if (isRestricted) {
      return "limited";
    }
    if (isDisabled) {
      return "disabled";
    }
    if (!isOpened) {
      return "closed";
    }
    if (!inventory) {
      return "unavailable";
    }
    return "available";
  }, [isDisabled, isRestricted, inventory, isOpened]);

  const displayDate = date.format(DATE_FORMAT);

  return (
    <div
      className={classNames("col cell", status, {
        selected: !isDisabled && isSelected,
        hidden: !isCurrentMonth,
      })}
      onClick={handleDateClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className="text-content">
        <span className="date">{displayDate}</span>
        <div className="available">
          <span
            className={classNames("badge rounded-circle", {
              "d-none": isPastDay || isGreaterThanOneYear,
            })}
          >
            <span className="visually-hidden">{status}</span>
          </span>
          <span className="inventory">{ariData ? inventoryText : ""}</span>
        </div>
        <div className="rate">{rate}</div>
      </div>
      <div className="toggle-wrapper">
        {ariData &&
          (isOffline ? (
            <Toggle
              size="sm"
              checked={isOpened}
              onChange={handleToggleOpen}
              disabled={isDisabled || isRestricted}
              locale={{ on: "Open", off: "Closed" }}
            >
              {getOpenLabel(isOpen)}
            </Toggle>
          ) : (
            <InventoryStatus inventory={inventory} isOpen={isOpened} />
          ))}
      </div>
      <ConfirmModal
        show={showModal}
        onClose={handleClose}
        onConfirm={handleConfirm}
        message={confirmationMessage}
        title="Please confirm update"
      />
      <LoaderModal showLoader={showLoader} setShowLoader={setShowLoader} />
    </div>
  );
};

DateCell.propTypes = {
  isPastDay: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(dayjs).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onDateClick: PropTypes.func.isRequired,
  isCurrentMonth: PropTypes.bool.isRequired,
  isGreaterThanOneYear: PropTypes.bool.isRequired,
  ariData: PropTypes.shape({
    price: PropTypes.number,
    inventory: PropTypes.number,
    isOpen: PropTypes.bool,
    minLeadTime: PropTypes.number,
    propertyId: PropTypes.number.isRequired,
    rateplanId: PropTypes.number.isRequired,
    roomtypeId: PropTypes.number.isRequired,
  }),
  dayDiff: PropTypes.number,
};

InventoryStatus.propTypes = {
  inventory: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
};

export default DateCell;
