import React, { useContext, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { getAri } from "../api";

export const AriContext = React.createContext({});

const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let start = new Date(startDate);
  while (start <= new Date(endDate)) {
    dates.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }
  return dates;
};

const createDummyEntry = (dateString, room, date, currencyCode) => ({
  roomtypeId: room.id,
  date: dateString,
  day: date.toLocaleDateString("en-US", { weekday: "long" }),
  price: null,
  sourceTax: "0.00",
  isOpen: true,
  inventory: 0,
  minLos: 0,
  maxLos: 28,
  minLeadTime: 0,
  currency: currencyCode,
});

const getSanitizeDataForMissingAris = (data, startDate, endDate, rooms) => {
  const dateRange = generateDateRange(startDate, endDate);
  const updatedData = [...data];
  dateRange.forEach((date) => {
    const dateString = date.toISOString().split("T")[0];
    rooms.forEach((room) => {
      const dateExists = data.some(
        (item) => item.date === dateString && item.roomtypeId === room.id
      );
      if (!dateExists) {
        updatedData.push(
          createDummyEntry(dateString, room, date, data[0]?.currency)
        );
      }
    });
  });

  return updatedData;
};

export const AriProvider = React.memo(function AriProvider({ children }) {
  const [ariData, setAriData] = useState([]);
  const [loading, setLoading] = useState(false);

  const refetchAriData = useCallback(
    ({
      propertyId,
      rateplanId,
      rooms,
      startDate,
      endDate,
      includeNumberOfRoomsSold = false,
    }) => {
      if (
        !propertyId ||
        !rateplanId ||
        !rooms?.length ||
        !startDate ||
        !endDate
      ) {
        return;
      }

      setLoading(true);
      getAri({
        propertyId,
        rateplanIds: [rateplanId],
        roomtypeIds: rooms.map(({ id }) => id),
        startDate,
        endDate,
        includeNumberOfRoomsSold,
      })
        .then((response) => {
          let data = (response?.data?.aris || []).map((ari) => ({
            ...ari,
            price: ari.price != null ? Number(ari.price).toFixed(2) : ari.price,
            sourceTax: Number(ari.sourceTax || 0).toFixed(2),
          }));
          data = getSanitizeDataForMissingAris(data, startDate, endDate, rooms);
          setAriData(data);
        })
        .catch(() => setAriData([]))
        .finally(() => setLoading(false));
    },
    []
  );

  // Memoize the value object to avoid re-creating it on every render
  const contextValue = useMemo(
    () => ({ ariData, refetchAriData, loading }),
    [ariData, refetchAriData, loading]
  );

  return (
    <AriContext.Provider value={contextValue}>{children}</AriContext.Provider>
  );
});

AriProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate that `children` is passed and is a React node
};

export const useAriContext = () => useContext(AriContext);
