import { HStack, List, Popover, Text, Whisper } from "rsuite";
import PropTypes from "prop-types";
import cx from "classnames";
import InfoIcon from "../../assets/img/info.svg";
import "./MessagePopOver.scss";

const MessagePopOver = ({
  message,
  placement = "topStart",
  className = "",
}) => {
  const speaker = (
    <Popover className="bg-white" title={<p>{}</p>}>
      <List divider={false} size="xs">
        <List.Item className="bg-white">
          <HStack className="bg-white">
            <Text className="info-value">{message}</Text>
          </HStack>
        </List.Item>
      </List>
    </Popover>
  );

  return (
    <Whisper placement={placement} trigger="hover" speaker={speaker}>
      <img src={InfoIcon} alt="info" className={cx(className, "img-align")} />
    </Whisper>
  );
};

MessagePopOver.propTypes = {
  message: PropTypes.string,
  placement: PropTypes.string,
  className: PropTypes.string,
};

export default MessagePopOver;
