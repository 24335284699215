import React, { useState, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import TabsContext from "./TabsContext";
import TabList from "./TabList";
import Tab from "./Tab";
import { getChildElementsEvenIfContainersInbetween } from "./tabUtils";
import "./tabs.scss";

const TabsHT = ({
  children,
  initiallyActiveTab = 0,
  small = false,
  className,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState(initiallyActiveTab);
  const [focusedTab, setFocusedTab] = useState(null);

  const childElements = getChildElementsEvenIfContainersInbetween(children);

  /**
   * Get the TabList from children
   */
  const tabList = childElements.filter((child) => {
    return (
      React.isValidElement(child) && child.type.componentName === "TabList"
    );
  });

  const defaultStoreValue = useMemo(
    () => ({ activeTab, setActiveTab, focusedTab, setFocusedTab }),
    [activeTab, focusedTab]
  ); // value is cached by useMemo

  return (
    <TabsContext.Provider value={defaultStoreValue}>
      <div
        {...rest}
        className={classNames("ht-tabs", small && "small", className)}
      >
        {tabList}
      </div>
    </TabsContext.Provider>
  );
};

TabsHT.propTypes = {
  children: PropTypes.node,
  initiallyActiveTab: PropTypes.number,
  small: PropTypes.bool,
  className: PropTypes.string,
};

// Using the Tabs component and its child components in the same namespace ensures that the group is considered as a single component.
// Tabs components do some child tab component processing and this might break in some preprocessed environments if the components were separate components, for example in mdx environment.
TabsHT.TabList = TabList;
TabsHT.Tab = Tab;

export default TabsHT;
