import { Col, Form, DateRangePicker } from "rsuite";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useRef } from "react";
import "./daterange.scss";

dayjs.extend(advancedFormat);

const { beforeToday } = DateRangePicker;

const DateRange = ({ value, setValue, ...props }) => {
  const containerRef = useRef(null);
  const handleDateChange = (selectedRange) => {
    if (!selectedRange) {
      setValue([]);
      return;
    }

    const [start, end] = selectedRange;

    // Check if the dates are valid
    if (!dayjs(start).isValid() || !dayjs(end).isValid()) {
      toast.error("Invalid date selected!"); // Show toast for invalid dates
      return;
    }

    // Check if the dates are in the past
    if (
      dayjs(start).isBefore(dayjs().startOf("day")) ||
      dayjs(end).isBefore(dayjs().startOf("day"))
    ) {
      toast.error("Past dates are not allowed!"); // Show toast for past dates
      return;
    }

    setValue(selectedRange); // Update state only for valid ranges
  };
  return (
    <Col {...props}>
      <Form.Group className="daterange-container">
        <Form.ControlLabel className="daterange">Date range</Form.ControlLabel>
        <div ref={containerRef}>
          <DateRangePicker
            value={value}
            onChange={handleDateChange}
            placeholder="Select date range"
            data-testid="date-range-picker"
            shouldDisableDate={beforeToday()} // Disable dates before today
            renderValue={(value) => {
              if (value && value.length === 2) {
                const [start, end] = value;
                return `${dayjs(start).format("Do MMM YYYY")} - ${dayjs(end).format("Do MMM YYYY")}`;
              }
              return "Select Date Range"; // Default placeholder when no range is selected
            }}
            ranges={[]}
            container={() => containerRef.current}
          />
        </div>
      </Form.Group>
    </Col>
  );
};

DateRange.propTypes = {
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  setValue: PropTypes.func,
};

export default DateRange;
