import React from "react";
import { Table as RsuiteTable } from "rsuite";
import classNames from "classnames";
import PropTypes from "prop-types";
import { HeaderCell, Cell } from "./cells";
import TableFooter from "./footer/TableFooter";
import "./Table.scss";

const { Column } = RsuiteTable;

const DEFAULT_PAGE_SIZE = 10;
function Table(
  props = {
    paginated: "",
    pageSizeOptions: [],
    defaultPageSize: DEFAULT_PAGE_SIZE,
    total: 0,
    currentPage: 1,
    onPageChange: (page, pageSize) => {},
    maxButtons: 3,
    rowDisabledHandler: (rowData, index) => false,
  }
) {
  const {
    data = [],
    children,
    loading = false,
    hover = true,
    fillHeight = false,
    height = 525,
    onRowClick,
    paginated = false, // Pagination is disabled by default
    pageSizeOptions,
    defaultPageSize = DEFAULT_PAGE_SIZE,
    total = data.length, // Default total to the length of the data
    onPageChange, // Callback for page change
    currentPage = 1,
    maxButtons = 3,
    rowClassName,
    rowDisabledHandler,
    ...rest
  } = props;

  // Determine initial page size
  const initialPageSize =
    pageSizeOptions && pageSizeOptions.length > 0
      ? pageSizeOptions[0]
      : defaultPageSize;

  // Handle page change
  const handlePageChangeInternal = (page) => {
    if (onPageChange) {
      onPageChange(page, initialPageSize);
    }
  };

  return (
    <div className="table-container">
      <div className="table-wrapper">
        <RsuiteTable
          loading={loading}
          height={height}
          hover={hover}
          fillHeight={fillHeight}
          data={data}
          onRowClick={onRowClick}
          rowClassName={(rowData, index) => {
            let customRowClass = rowClassName;
            if (typeof rowClassName === "function" && rowData) {
              customRowClass = rowClassName?.(rowData, index);
            }
            return classNames(
              rowDisabledHandler?.(rowData, index) ? "disabled-table-row" : "",
              customRowClass,
              {
                "clickable-row": !!onRowClick,
              }
            );
          }}
          {...rest}
        >
          {children}
        </RsuiteTable>
      </div>
      {paginated && (
        <TableFooter
          total={total}
          loading={loading}
          pageSize={initialPageSize}
          currentPage={currentPage}
          onPageChange={handlePageChangeInternal}
          maxButtons={maxButtons}
        />
      )}
    </div>
  );
}

Table.Column = Column;
Table.Cell = Cell;
Table.HeaderCell = HeaderCell;
Table.DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

Table.propTypes = {
  data: PropTypes.array,
  children: PropTypes.node,
  loading: PropTypes.bool,
  hover: PropTypes.bool,
  fillHeight: PropTypes.bool,
  height: PropTypes.number,
  onRowClick: PropTypes.func,
  paginated: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  defaultPageSize: PropTypes.number,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  maxButtons: PropTypes.number,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowDisabledHandler: PropTypes.func,
};

export default Table;
