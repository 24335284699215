function calculatePercentageChange(currentValue, previousValue) {
  if (previousValue === 0) {
    return currentValue === 0 ? 0 : 100; // Handle division by zero case
  }
  return (((currentValue - previousValue) / previousValue) * 100).toFixed(2);
}

// Function to calculate percentage change for each field between current and previous summary responses
export function calculatePercentageChanges(currentSummary, previousSummary) {
  const revenueChange = calculatePercentageChange(
    currentSummary.totalRevenue,
    previousSummary.totalRevenue
  );
  const nightsChange = calculatePercentageChange(
    currentSummary.totalNights,
    previousSummary.totalNights
  );
  const reservationsChange = calculatePercentageChange(
    currentSummary.totalReservations,
    previousSummary.totalReservations
  );
  const uniqueClientsChange = calculatePercentageChange(
    currentSummary.totalUniqueClients,
    previousSummary.totalUniqueClients
  );

  return {
    revenueChange,
    nightsChange,
    reservationsChange,
    uniqueClientsChange,
  };
}

export const EMPTY_DISCOUNT_DAY_WISE_MAP = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
};

export const PROMO_OBJECT = {
  propertyCode: "", //selected prorpertyCode
  rateRule: {
    dprExpiryDate: null,
    id: null,
    ruleVO: {
      descriptionVO: {
        propertyId: null, //not required
        code: "",
        rateRuleId: null,
        shortDesc: "",
        timeZone: null,
        name: null, //promo title
      },
      bookingVO: {
        channelOptions: ["Hotel Trader"],
        gdsChannelTypes: [],
        bookingStartDate: null, //booking window start "2024-01-15"
        bookingEndDate: null, //booking window end "2024-01-31"
        recurBkgRuleCalcdStartDate: null,
        recurBkgRuleEveryN: null,
        recurBkgRuleTimePeriodHours: null,
        recurBkgRuleTimePeriodMinutes: null,
        recurBkgRulePatternSpan: null,
        recurBkgRulePatternType: null,
        recurBkgRulePatternWeekDaysOfWeek: [],
        recurBkgRulePatternMonthDayNofMonth: null,
        recurBkgRulePatternMonthSelect1: null,
        recurBkgRulePatternMonthSelect2: null,
        recurBkgRuleEndpoint: null,
        recurBkgRuleEndAfterNrecurs: null,
        minBookingLeadTime: null, //lead time requirements
        maxBookingLeadTime: null, //lead time requirements
        bookingWindowChoice: 1,
        recurBkgRuleStartHours: null,
        recurBkgRuleStartMins: null,
        recurBkgRuleDurationHour: null,
        recurBkgRuleDurationMins: null,
        minNumberOfRoomsBooked: null,
        maxNumberOfRoomsBooked: null,
      },
      stayVO: {
        mustIncludeAny: false,
        blackOutDateRanges: [
          // {
          //   blackOutStartDate: null, //"2024-02-14",
          //   blackOutEndDate: null, //"2023-02-14",
          //   blackOutStayThruEnabled: null,
          // },
        ], //blackout dates
        dateFormat: null,
        checkInStayThruEnabled: true,
        checkOutStayThruEnabled: true,
        stayStartDate: null, //"2024-02-01"stay dates start
        stayEndDate: null, //"2024-02-29" stay dates end
        minLengthOfStay: null, // particular length of stay
        maxLengthOfStay: null, // particular length of stay
        mustArriveDaysOfWeek: [],
        mustIncludeOneDaysOfWeek: [],
      },
      discountVO: {
        steadyDiscount: true,
        numSteadyDaysToDiscount: null,
        nthNightToDiscount: null,
        nthNightDiscountIsRecurring: null,
        dateDiscountingStartDate: null,
        dateDiscountingEndDate: null,
        appliedOnType: null,
        appliedOnNumNights: null,
        appliedDaysOfWeek: [],
        appliedDates: [],
        rateRoomIdsAppliedTo: [],
        allowedRateRoomIds: null,
        addOnIds: [],
        mCompDiscountType: 2, //offer type  1: flat, 2: percent
        discountType: 4, //this is always 4
        fixedDiscountAmount: null, //fixed
        percentDiscountAmount: null, //percent
        fixedDiscountMap: EMPTY_DISCOUNT_DAY_WISE_MAP,
        percentDiscountMap: EMPTY_DISCOUNT_DAY_WISE_MAP,
      },
      geoRuleVO: {
        geoRules: [],
        includeOrExclude: "Exclude",
      },
      ipAddressVO: {
        ipAddressDescriptions: [],
        ipFilterOption: "exclude",
        ipAdresses: [],
      },
      geoRadiusVO: {
        applyFlag: "false",
        includeOrExclude: "include",
        radius: 0.0,
      },
    },
  },
  allowedRateRoomCodes: [],
};

export const DEFAULT_CALCULATION_TYPE_FOR_PEGS_OFFLINE = 0;
export const DEFAULT_CALCULATION_TYPE = 1;
export const PEGS_CRS_ID = 1;
