import React from "react";
import { Row, Col } from "rsuite";
import PropTypes from "prop-types";
import MessagePopOver from "../../message-popOver/MessagePopOver";
import "./right-panel.scss";

export const getTaxPopOvermessage = (adultPrices) => {
  let taxMsg = "N/A";

  if (adultPrices?.length) {
    taxMsg = `Calculated on a per-room, per-day basis for ${
      adultPrices.length === 1 ? "1 adult." : "2 adults sharing."
    }`;
  }

  return taxMsg;
};

const TaxInfo = ({ currency, sourceTax, adultPrices = [] }) => {
  return (
    <Row>
      <Col xs={12} className="row-label">
        {`Tax (${currency})`}
      </Col>
      <Col xs={12}>
        {sourceTax}
        <MessagePopOver message={getTaxPopOvermessage(adultPrices)} />
      </Col>
    </Row>
  );
};

TaxInfo.propTypes = {
  currency: PropTypes.string,
  sourceTax: PropTypes.string,
  adultPrices: PropTypes.arrayOf(PropTypes.number),
};

export default TaxInfo;
