import React, { useEffect, useMemo, useRef } from "react";
import { Input, InputGroup, InputNumber, InputPicker } from "rsuite";
import { Col, FormGroup, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
const offerTypes = [
  { label: "Percentage discount on overall price", value: 2 },
  { label: "Flat discount on overall price", value: 1 },
];
const calculationTypes = [
  { label: "Apply discount to total price (before tax)", value: 1 },
  { label: "Apply discount to adults only price (before tax)", value: 2 },
  {
    label: "Apply discount to base price (excludes additional adults)",
    value: 0,
  },
];
const discountTypes = [
  { label: "All days of the week", value: 4 },
  { label: "Select days of week", value: 1 },
];

const DAYS = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];
const dayMap = new Map([
  ["Sun", 1],
  ["Mon", 2],
  ["Tue", 3],
  ["Wed", 4],
  ["Thur", 5],
  ["Fri", 6],
  ["Sat", 7],
]);

function OfferType({
  onFieldChange,
  promo,
  setIsNextBtnDisabled,
  calculationType,
  setCalculationType,
}) {
  const offerTypeContainerRef = useRef(null);
  const calculationMethodContainerRef = useRef(null);

  const resetOfferTypeFields = () => {
    onFieldChange("rateRule.ruleVO.discountVO.fixedDiscountAmount", 0);
    onFieldChange("rateRule.ruleVO.discountVO.percentDiscountAmount", 0);

    DAYS.forEach((day) => {
      onFieldChange(
        `rateRule.ruleVO.discountVO.percentDiscountMap.${dayMap.get(day)}`,
        0
      );
      onFieldChange(
        `rateRule.ruleVO.discountVO.fixedDiscountMap.${dayMap.get(day)}`,
        0
      );
    });
  };

  const handleChange = (newValue) => {
    onFieldChange("rateRule.ruleVO.discountVO.mCompDiscountType", newValue);
    resetOfferTypeFields();
  };

  const handleDiscountTypeChange = (value) => {
    onFieldChange("rateRule.ruleVO.discountVO.discountType", value);
    resetOfferTypeFields();
  };

  const handleCalculationTypeChange = (value) => {
    setCalculationType(value);
  };

  const handleDiscountChange = (value) => {
    const newValue = +value;
    if (
      promo.rateRule.ruleVO.discountVO.mCompDiscountType === offerTypes[0].value
    ) {
      onFieldChange(
        "rateRule.ruleVO.discountVO.percentDiscountAmount",
        newValue
      );
    } else {
      onFieldChange("rateRule.ruleVO.discountVO.fixedDiscountAmount", newValue);
    }
  };

  const getDiscountValue = useMemo(() => {
    const {
      mCompDiscountType,
      discountType,
      percentDiscountMap,
      fixedDiscountMap,
      percentDiscountAmount,
      fixedDiscountAmount,
    } = promo.rateRule.ruleVO.discountVO;

    if (mCompDiscountType === offerTypes[0].value) {
      if (discountType === discountTypes[1].value) {
        return Object.values(percentDiscountMap).reduce(
          (total, discount) => total + discount,
          0
        );
      }
      return percentDiscountAmount ?? 0;
    }

    if (discountType === discountTypes[1].value) {
      return Object.values(fixedDiscountMap).reduce(
        (total, discount) => total + discount,
        0
      );
    }
    return fixedDiscountAmount ?? 0;
  }, [promo]);

  const handleDayChange = (value, day) => {
    if (value >= 0 && value <= 999) {
      const newValue = +value;
      if (
        promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
        offerTypes[0].value
      ) {
        onFieldChange(
          `rateRule.ruleVO.discountVO.percentDiscountMap.${dayMap.get(day)}`,
          newValue
        );
      } else {
        onFieldChange(
          `rateRule.ruleVO.discountVO.fixedDiscountMap.${dayMap.get(day)}`,
          newValue
        );
      }
    }
  };

  const renderDayInput = (day) => {
    return (
      <FormGroup>
        <Label for={`day-${day}`}>{day}</Label>
        <InputGroup className="day-input-container" inside>
          <Input
            id={`day-${day}`}
            className="day-input"
            defaultValue={0}
            type={"number"}
            onClick={(e) => e.target.select()}
            onChange={(val) => handleDayChange(val, day)}
            value={
              promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
              offerTypes[0].value
                ? promo.rateRule.ruleVO.discountVO.percentDiscountMap[
                    dayMap.get(day)
                  ]
                : promo.rateRule.ruleVO.discountVO.fixedDiscountMap[
                    dayMap.get(day)
                  ]
            }
          />
          <InputGroup.Addon>
            {promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
            offerTypes[0].value
              ? "%"
              : ""}
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    );
  };

  useEffect(() => {
    if (!getDiscountValue || getDiscountValue < 0) {
      setIsNextBtnDisabled(true);
    } else {
      setIsNextBtnDisabled(false);
    }
  }, [getDiscountValue, setIsNextBtnDisabled]);

  return (
    <div className="offer-type">
      <div className={"mt-4 position-relative"} ref={offerTypeContainerRef}>
        <Label for="offerType">Type</Label>
        <InputPicker
          id="offerType"
          value={promo.rateRule.ruleVO.discountVO.mCompDiscountType}
          onChange={(value) => handleChange(value)}
          placeholder="Choose an option"
          data={offerTypes}
          block
          container={() => offerTypeContainerRef.current}
        />
      </div>
      <div className={"mt-4"}>
        <Label for="offerType">Apply To</Label>
        <InputPicker
          id="offerType"
          value={promo.rateRule.ruleVO.discountVO.discountType}
          onChange={(value) => handleDiscountTypeChange(value)}
          placeholder="Choose an option"
          data={discountTypes}
          block
        />
      </div>
      {promo.rateRule.ruleVO.discountVO.discountType === 1 ? (
        <div className="mt-4">
          <Row>
            <Col md={6} className="d-flex justify-content-between">
              {DAYS.map((day) => renderDayInput(day))}
            </Col>
            <Col md={6} className="day-input-note">
              Note: 0
              {promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
              offerTypes[0].value
                ? "%"
                : ""}{" "}
              means no discount and full price is applied.
            </Col>
          </Row>
        </div>
      ) : (
        <div className={"mt-4"}>
          <Label for="discount">
            {promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
            offerTypes[0].value
              ? "Discount applied in %"
              : "Flat Discount"}
          </Label>
          <FormGroup>
            <InputNumber
              id="discount"
              name="discount"
              value={getDiscountValue}
              min={0}
              max={999}
              onChange={(value) => handleDiscountChange(value)}
              placeholder={
                promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
                offerTypes[0].value
                  ? "Discount applied in %"
                  : "Flat Discount"
              }
            />
          </FormGroup>
        </div>
      )}

      <div
        className={"mt-4 position-relative"}
        ref={calculationMethodContainerRef}
      >
        <Label for="calculationMethod">Calculation method</Label>
        <InputPicker
          id="calculationMethod"
          value={calculationType}
          onChange={handleCalculationTypeChange}
          placeholder="Choose an option"
          data={calculationTypes}
          cleanable={false}
          block
          container={() => calculationMethodContainerRef.current}
        />
      </div>
    </div>
  );
}

OfferType.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  promo: PropTypes.shape({
    rateRule: PropTypes.shape({
      ruleVO: PropTypes.shape({
        discountVO: PropTypes.shape({
          mCompDiscountType: PropTypes.number.isRequired,
          discountType: PropTypes.number.isRequired,
          percentDiscountMap: PropTypes.object.isRequired,
          fixedDiscountMap: PropTypes.object.isRequired,
          percentDiscountAmount: PropTypes.number,
          fixedDiscountAmount: PropTypes.number,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  setIsNextBtnDisabled: PropTypes.func.isRequired,
  calculationType: PropTypes.number.isRequired,
  setCalculationType: PropTypes.func.isRequired,
};

export default OfferType;
