import { Tag, TagPicker } from "rsuite";
import PropTypes from "prop-types";
import SoldOutBadge from "./SoldOutBadge";

const TabsFilter = ({ rooms, ariData, filteredRooms, setFilteredRooms }) => {
  const clearRoomFilter = () => {
    setFilteredRooms([]);
  };

  const handleTagSelect = (values, item) => {
    if (values.includes(item.id)) {
      setFilteredRooms((prev) => [...prev, item]);
    } else {
      setFilteredRooms((prev) => prev.filter((room) => room.id !== item.id));
    }
  };

  const renderValue = (values) => {
    return <Tag>{`Showing ${values.length}/${rooms.length} rooms`}</Tag>;
  };

  const renderMenuItem = (label, item) => {
    const isSoldOut = ariData?.some(
      ({ roomtypeId, inventory }) => roomtypeId === item.id && !inventory
    );
    return (
      <div className="room-type__menu-item">
        <span>{label}</span>
        <SoldOutBadge isSoldOut={isSoldOut} />
      </div>
    );
  };

  return (
    <TagPicker
      block
      data={rooms}
      labelKey="displayName"
      valueKey="id"
      className="roomtype-picker"
      searchable={false}
      value={filteredRooms.map(({ id }) => id)}
      onSelect={handleTagSelect}
      onClean={clearRoomFilter}
      placeholder="Filter room types"
      renderValue={renderValue}
      renderMenuItem={renderMenuItem}
    />
  );
};

TabsFilter.propTypes = {
  rooms: PropTypes.array.isRequired,
  ariData: PropTypes.arrayOf(
    PropTypes.shape({
      roomtypeId: PropTypes.number.isRequired,
      inventory: PropTypes.number.isRequired,
    })
  ),
  filteredRooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  setFilteredRooms: PropTypes.func.isRequired,
};

export default TabsFilter;
