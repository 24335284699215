import createAxiosInstance from "./axios";

let API_SERVER_PREFIX =
  process.env.REACT_APP_SERVER_DOMAIN +
  process.env.REACT_APP_SERVER_ENVIRONMENT;

const {
  getRequest,
  postRequest,
  putRequest,
  getFileRequest,
  postFileRequest,
  postFileUploadRequest,
} = createAxiosInstance(API_SERVER_PREFIX);

export const getProperties = () => getRequest(`BE/api/v1/properties`);

export const getPromotionsSummary = ({ startDate, endDate, propertyId }) =>
  getRequest(
    `BE/api/v1/promo-codes/summary?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`
  );

export const getPromotions = (pathParams) => {
  return getRequest(`BE/api/v1/promo-codes${pathParams}`);
};

export const searchPromotions = (pathParams) => {
  return getRequest(`BE/api/v1/promo-codes/search${pathParams}`);
};

export const getRatePlansByProperty = (propertyId) => {
  return getRequest(`BE/api/v1/rate-plans/${propertyId}`);
};

export const getRoomsByProperty = (propertyId) => {
  return getRequest(`BE/api/v1/room-types/${propertyId}`);
};

export const getPromo = (promoCodeId, propertyId) => {
  return getRequest(
    `BE/api/v1/promo-codes/${promoCodeId}/property/${propertyId}`
  );
};

export const savePromo = (promo, isDraft, calculationType) => {
  return postRequest(
    `BE/api/v1/promo-codes?calculationType=${calculationType}${isDraft ? "&draft=true" : ""}`,
    promo
  );
};

export const disablePromo = (promoId, propertyId) => {
  return putRequest(
    `BE/api/v1/promo-codes/disable?promoCodeId=${promoId}&propertyId=${propertyId}`,
    {}
  );
};

export const activatePromo = (promoId, propertyId) => {
  return getRequest(
    `BE/api/v1/promo-codes/${promoId}/property/${propertyId}/active`
  );
};

export const getPowerBiEmbedConfig = async () => {
  return await getRequest(`BE/api/v1/power-bi/embed-config`);
};

export const downloadPromotionsInXlsx = (pathParams) =>
  getFileRequest(`BE/api/v1/promo-codes/export${pathParams}`);

export const getStatusBreakdown = (id) => {
  return getRequest(`BE/api/v1/promo-codes/status-breakdown?propertyId=${id}`);
};

export const updateARI = async (payload) => {
  return await postRequest("ARI/ari/updateByProduct", payload);
};

export const getAri = ({
  propertyId,
  rateplanIds,
  roomtypeIds,
  startDate,
  endDate,
  includeNumberOfRoomsSold = false,
}) => {
  return postRequest(`BE/ari/property`, {
    propertyId,
    rateplanIds,
    roomtypeIds,
    dateRanges: [{ startDate, endDate }],
    includeNumberOfRoomsSold,
  });
};

export const getReservations = (data) => {
  return postRequest(`BE/api/get-reservations`, data);
};

export const getReservationsExport = (data) => {
  return postFileRequest(`BE/api/get-reservations/download`, data);
};

export const getDemandClients = () => {
  return getRequest(`BE/api/v1/clients`);
};

export const repushVcc = (confirmationCode, username) => {
  return getRequest(
    `BOOKING_ASYNC/bookings/vcc/repush/${confirmationCode}/user/${username}`
  );
};

export const uploadOfflineExcel = (payload) =>
  postFileUploadRequest(`BOOKING/admin/offlinePMSUpload`, payload);

export const uploadAriByFile = (userId, payload) =>
  postFileUploadRequest(`ARI/ari-excel/upload?userId=${userId}`, payload);

export const downloadAriTemplate = (propertyId) =>
  postFileRequest(`ARI/ari-excel/download-template?propertyId=${propertyId}`);

export const getPromotionsByRateplanId = (propertyId, rateplanId) =>
  getRequest(`ARI/promo/property/${propertyId}/rateplan/${rateplanId}`);

export const getAlerts = (page, size, propertyId) =>
  getRequest(
    `BE/alerts?pageNo=${page}&size=${size}&propertyId=${propertyId ?? 0}`
  );

export const markAlertRead = (alertId) =>
  postRequest(`BE/alerts/MARK_READ/${alertId}`);

export const deleteAlert = (alertId) =>
  postRequest(`BE/alerts/DELETE/${alertId}`);

export const markAlertUnread = (alertId) =>
  postRequest(`BE/alerts/MARK_UNREAD/${alertId}`);
