import { getRatePlansByProperty } from "../api";
import { createAppSlice } from "./createAppSlice";

const rateplanSlice = createAppSlice({
  name: "rateplan",
  initialState: {
    rateplans: [],
    loading: false,
    error: null,
    selectedRateplan: null,
  },
  reducers: (create) => ({
    setSelectedRateplan: (state, action) => {
      state.selectedRateplan = action.payload;
    },
    getRatePlansByPropertyId: create.asyncThunk(
      async (propertyId, { getState }) => {
        const existingRateplans = rateplanSlice
          .getSelectors()
          .selectRateplans(getState()[rateplanSlice.name]);
        if (existingRateplans?.some((item) => item.propertyId === propertyId)) {
          return;
        }

        const response = await getRatePlansByProperty(propertyId);
        return response.data.data;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          if (action.payload?.length > 0) {
            state.rateplans = action.payload;
            state.selectedRateplan = null;
          }
        },
        rejected: (state, action) => {
          state.error = action.error;
        },
        settled: (state, _) => {
          state.loading = false;
        },
      }
    ),
  }),
  selectors: {
    selectRateplans: (state) => state.rateplans,
    selectSelectedRateplan: (state) => state.selectedRateplan,
    selectLoading: (state) => state.loading,
    selectSelectedRateplanCurrency: (state) =>
      state.selectedRateplan?.data.currencyCode,
  },
});

export const { getRatePlansByPropertyId, setSelectedRateplan } =
  rateplanSlice.actions;

export const {
  selectRateplans,
  selectSelectedRateplan,
  selectLoading,
  selectSelectedRateplanCurrency,
} = rateplanSlice.selectors;

export default rateplanSlice;
