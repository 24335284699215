import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Col, Container, Row, Table, Toggle } from "rsuite";
import { downloadAriTemplate, updateARI, uploadAriByFile } from "../../api";
import cx from "classnames";
import RoomtypeTabs from "./../../components/roomtype-tabs/RoomtypeTabs";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import LoaderModal from "../loader-modal/LoaderModal";
import { toast } from "react-toastify";
import { usePropertyStore } from "../../contexts/PropertyStoreContext";
import "./tabularview.scss";
import UploadFiles from "../upload-file/uploadFiles";
import { jwtDecode } from "jwt-decode";
import Loader from "../../components/Loader";
import { useAriContext } from "../../contexts/AriContext";
import { selectManageARIDateRange } from "../../slices/ariDateRangeSlice";
import ariSelectRateplan from "../../assets/img/ari-select-rateplan.png";
import warning from "../../assets/img/warning.svg";
import {
  selectRoomtypes,
  selectSelectedRoomtype,
} from "../../slices/roomtypeSlice";
import MessagePopOver from "../message-popOver/MessagePopOver";
import {
  getDefaultCurrentData,
  FIELDS,
  dateToYMD,
  hasAriTobeUpdate,
} from "./TabularViewUtils";
import { getTaxPopOvermessage } from "../calender-view/right-panel/TaxInfo";

const { Column, HeaderCell, Cell } = Table;

const TabularView = ({ rateplan }) => {
  const defaultCurrency = rateplan.data.currencyCode;
  const { refetchAriData, loading } = useAriContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [headerMessage, setHeaderMessage] = useState(null);
  const [loaderDesc, setLoaderDesc] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const { selectedProperty } = usePropertyStore();
  const [files, setFiles] = useState([]);
  const [resetFiles, setResetFiles] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const dateRange = useSelector(selectManageARIDateRange);
  const rooms = useSelector(selectRoomtypes);
  const [navigationModal, setNavigationModal] = useState(false);
  const [changeRoomFunction, setChangeRoomFunction] = useState(null);
  const selectedRoomtype = useSelector(selectSelectedRoomtype);
  const [currentData, setCurrentData] = useState(
    getDefaultCurrentData(selectedRoomtype)
  );

  useEffect(() => {
    if (selectedRoomtype) {
      setCurrentData(getDefaultCurrentData(selectedRoomtype));
    }
  }, [selectedRoomtype, rateplan]);

  useEffect(() => {
    setSelectedRows([]); // Reset currentData on rateplan change
  }, [rateplan]);

  useEffect(() => {
    const notAllowed = currentData.some((row) => {
      if (row.isCustomRow) {
        return row.price != null && row.price <= 0 && row.price !== "";
      } else {
        return row.price == null || row.price <= 0;
      }
    });
    setErrorMessage(notAllowed);
  }, [currentData]);

  // Helper function to get a date range
  const getDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const stopDate = new Date(endDate);

    while (currentDate <= stopDate) {
      dates.push(new Date(currentDate)); // Push a copy of the current date
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }

    return dates;
  };

  // Helper function to build updates for a specific date
  const buildUpdateForDate = (dateYMD, roomRows, bulkUpdates, selectedRows) => {
    const excludedRow = roomRows.find((row) => row.date === dateYMD);

    if (excludedRow) {
      return {
        date: excludedRow.date,
        inventory: excludedRow.inventory,
        price: excludedRow.price,
        isOpen: excludedRow.isOpen,
        minLos: excludedRow.minLos,
        maxLos: excludedRow.maxLos,
        minLeadTime: excludedRow.minLeadTime,
      };
    }
    // If bulk updates are empty for the row, return null

    return {
      date: dateYMD,
      inventory: bulkUpdates?.inventory,
      price: bulkUpdates?.price,
      isOpen: bulkUpdates?.isOpen,
      minLos: bulkUpdates?.minLos,
      maxLos: bulkUpdates?.maxLos,
      minLeadTime: bulkUpdates?.minLeadTime,
    };
  };

  // Helper function to process updates for a specific roomtypeId
  const processRoomtypeUpdates = (
    roomtypeId,
    currentData,
    dateRanges,
    bulkUpdates,
    selectedRows,
    rateplan
  ) => {
    const roomRows = currentData
      .filter((row) => row.roomtypeId === roomtypeId && !row.isCustomRow)
      .map((row) => {
        return {
          ...row,
          price: parseFloat(Number(row.price).toFixed(2)),
        };
      });

    // Filter out null updates
    const updates = dateRanges
      .map((currentDate) => {
        const dateYMD = dateToYMD(currentDate);
        return buildUpdateForDate(dateYMD, roomRows, bulkUpdates, selectedRows);
      })
      .filter((update) => update !== null);

    return {
      rateplanId: rateplan.value,
      roomtypeId,
      updates,
    };
  };

  // Main buildPayload function
  const buildPayload = () => {
    const bulkUpdates = currentData.find((row) => row.isCustomRow);
    const payload = {
      propertyId: selectedProperty.propertyId,
      dateRange: {
        startDate: dateToYMD(dateRange[0]),
        endDate: dateToYMD(dateRange[1]),
      },
      productUpdates: [],
    };

    // Get unique roomtypeIds excluding the dummy row
    const roomtypeIds = Array.from(
      new Set(
        currentData.map((row) => row.roomtypeId).filter((id) => id !== "0")
      )
    );

    const dateRanges = getDateRange(dateRange[0], dateRange[1]);

    roomtypeIds.forEach((roomtypeId) => {
      const productUpdate = processRoomtypeUpdates(
        roomtypeId,
        currentData,
        dateRanges,
        bulkUpdates,
        selectedRows,
        rateplan
      );
      payload.productUpdates.push(productUpdate);
    });

    return payload;
  };

  const handleConfirm = (ariData) => {
    const isAnyCheckboxSelected = selectedRows.length > 0;
    setShowModal(false);
    const payload = buildPayload();
    if (
      currentData.length === 0 ||
      (currentData.length === ariData.length &&
        currentData.every((val) => ariData.includes(val))) ||
      (isAnyCheckboxSelected &&
        !hasAriTobeUpdate(payload?.productUpdates?.[0]?.updates, ariData))
    ) {
      toast.warn("No changes detected to save.");
      return;
    }

    setShowLoader(true);

    updateARI(payload)
      .then(() => {
        toast.success("Updates saved successfully.");
        setTimeout(() => {
          refetchAriData({
            propertyId: selectedProperty.propertyId,
            rateplanId: rateplan?.value,
            rooms,
            startDate: dateToYMD(dateRange[0]),
            endDate: dateToYMD(dateRange[1]),
          });
          setCurrentData(getDefaultCurrentData(selectedRoomtype));
        }, 5000);
      })
      .catch(() => {
        toast.error("Failed to save updates.");
      })
      .finally(() => {
        setTimeout(() => setShowLoader(false), 5000);
        setSelectedRows([]); // Clear selected rows
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNavigationModalClose = () => {
    setNavigationModal(false);
  };

  const handleOpen = () => setShowModal(true);

  const tabSwitch = (changeRoom) => {
    if (selectedRows.length === 0) {
      setNavigationModal(false);
      changeRoom();
    } else {
      setNavigationModal(true);
      setChangeRoomFunction(() => changeRoom);
    }
  };

  const confirmTabSwitch = () => {
    if (changeRoomFunction) {
      changeRoomFunction();
    }
    setSelectedRows([]);
    setNavigationModal(false);
  };

  const handleReset = (ariData) => {
    if (
      currentData.length === 0 ||
      (currentData.length === ariData.length &&
        currentData.every((val) => ariData.includes(val)))
    ) {
      toast.warn("No changes detected to reset.");
      return;
    }
    setCurrentData(ariData); // Clear the current data
    setErrorMessage(false);
    setSelectedRows([]); // Clear any selected rows
    toast.info("Data has been reset to its original state.");
  };

  const handleCheckboxChange = (checked, rowData) => {
    setSelectedRows((prevSelectedRows) =>
      checked
        ? [...prevSelectedRows, rowData]
        : prevSelectedRows.filter((row) => row.date !== rowData.date)
    );

    setCurrentData((prevData) => {
      const updatedData = [...prevData];

      // If the checkbox is checked, add the rowData to currentData
      if (checked) {
        const rowExists = updatedData.some(
          (row) =>
            row.roomtypeId === rowData.roomtypeId && row.date === rowData.date
        );

        if (!rowExists) {
          updatedData.push({
            roomtypeId: rowData.roomtypeId,
            date: rowData.date,
            day: rowData.day,
            currency: rowData.currency,
            price: rowData.price ?? null,
            sourceTax: rowData.sourceTax ?? null,
            isOpen: rowData.isOpen ?? false,
            inventory: rowData.inventory ?? null,
            minLos: rowData.minLos ?? null,
            maxLos: rowData.maxLos ?? null,
            minLeadTime: rowData.minLeadTime ?? null,
          });
        }
      } else {
        // If the checkbox is unchecked, remove the rowData from currentData
        return updatedData.filter(
          (row) =>
            !(
              row.roomtypeId === rowData.roomtypeId && row.date === rowData.date
            )
        );
      }

      return updatedData;
    });
  };

  const handleToggleChange = (checked, rowData) => {
    setCurrentData((prevData) => {
      // Find the dummy row
      const dummyRow = prevData.find((row) => row.isCustomRow);
      // Update the rows
      let updatedData = prevData.map((item) => {
        // Update the matching row for the specific roomtypeId and date
        if (
          item?.date === rowData?.date &&
          item?.roomtypeId === rowData?.roomtypeId &&
          !item.isCustomRow
        ) {
          return { ...item, isOpen: checked };
        }
        // Update the dummy row for bulk updates
        if (item?.isCustomRow && rowData.isCustomRow) {
          return { ...item, isOpen: checked };
        }
        return item;
      });
      // If a normal row toggle is changed, check if the row exists in the data
      if (!rowData.isCustomRow) {
        const rowExists = updatedData.some(
          (item) =>
            item?.roomtypeId === rowData?.roomtypeId &&
            item?.date === rowData?.date
        );

        // If the row doesn't exist, add it
        if (!rowExists) {
          updatedData.push({
            ...rowData,
            isOpen: checked,
          });
        }
      }
      const isDummyRowToggle =
        rowData.isCustomRow || (!rowData.date && rowData.roomtypeId);
      if (isDummyRowToggle) {
        if (!dummyRow) {
          // Add a dummy row only if it doesn't exist and toggle affects it
          updatedData.push({
            roomtypeId: rowData?.roomtypeId,
            date: "unless excluded",
            day: "Applies to all rows",
            price: null,
            sourceTax: null,
            isOpen: checked, // Set toggle value for the dummy row
            inventory: null,
            minLos: null,
            maxLos: null,
            minLeadTime: null,
            isCustomRow: true,
          });
        }
      } else if (dummyRow) {
        // If the dummy row exists but has no meaningful changes, remove it
        const hasMeaningfulChanges = Object.keys(dummyRow).some(
          (key) =>
            key !== "isCustomRow" &&
            dummyRow[key] !== null &&
            dummyRow[key] !== ""
        );
        if (!hasMeaningfulChanges) {
          updatedData = updatedData.filter((row) => !row.isCustomRow);
        }
      }
      return updatedData;
    });

    const isDateSelected = selectedRows.some(
      (row) => row.date === rowData.date
    );
    if (!isDateSelected) {
      setSelectedRows((prevSelectedRows) => {
        return [...prevSelectedRows, { date: rowData.date }];
      });
    }
  };

  const createDummyRow = (roomtypeId = 0) => ({
    roomtypeId,
    date: "unless excluded",
    day: "Applies to all rows",
    price: null,
    sourceTax: null,
    isOpen: true,
    inventory: null,
    minLos: null,
    maxLos: null,
    minLeadTime: null,
    isCustomRow: true,
  });

  const convertDateFormat = (inputDate, rowData) => {
    if (rowData.isCustomRow) {
      return rowData.date;
    }
    const [year, month, day] = inputDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const getDayFromDate = (rowData) => {
    if (rowData.isCustomRow) {
      return rowData.day;
    }
    const offSet = new Date().getTimezoneOffset();
    const date = new Date(rowData.date);
    date.setMinutes(date.getMinutes() + offSet);
    const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
    return dayOfWeek;
  };

  const preprocessAriData = (data, currentData) => {
    // Check for an existing dummyRow in currentData
    const dummyRow =
      currentData.find((row) => row.isCustomRow) ||
      createDummyRow(data?.[0]?.roomtypeId || 0);

    if (!data || data.length === 0) {
      return []; // Return only the dummyRow when no data
    }

    const currentDataMap = new Map(
      currentData.map((row) => [`${row?.roomtypeId}_${row?.date}`, row])
    );
    // Merge ariData and currentData
    const updatedData = data?.map((row) =>
      currentDataMap.has(`${row.roomtypeId}_${row.date}`)
        ? currentDataMap.get(`${row.roomtypeId}_${row.date}`)
        : row
    );

    return [
      dummyRow, // Ensure dummyRow is included at the top
      ...updatedData.map((item) => ({
        ...item,
        day: getDayFromDate(item), // Dynamically derive the "day"
        currency: defaultCurrency || item.currency,
      })),
    ];
  };

  const renderField = (rowData, field, handleFieldChange) => {
    const isOpenLabel = rowData?.isOpen ? "Open" : "Closed";
    const { adultPrices } = rowData;
    switch (field.type) {
      case "text":
        return (
          <div
            className={cx({
              "right-align-table-tax": field.key === "sourceTax",
            })}
          >
            {field.key === "date"
              ? convertDateFormat(rowData[field.key], rowData)
              : rowData[field.key]}
            {field.key === "sourceTax" &&
              rowData[field.key] !== null &&
              !rowData.isCustomRow && (
                <MessagePopOver
                  className="tax-info-icon"
                  message={getTaxPopOvermessage(adultPrices)}
                />
              )}
          </div>
        );
      case "input":
        return (
          <input
            type="text"
            // defaultValue={rowData[field.key]}
            value={rowData[field.key] ?? ""}
            onChange={(e) => {
              const value = e.target.value; // Update the rowData directly
              handleFieldChange(
                e,
                parseInt(value),
                rowData.roomtypeId,
                field.key,
                rowData.date
              );
            }}
            className="manageari-input"
          />
        );
      case "inputDecimal":
        return (
          <input
            type="text"
            value={rowData[field.key] ?? ""}
            onChange={(e) => {
              const value = e.target.value; // Update the rowData directly
              handleFieldChange(
                e,
                value,
                rowData.roomtypeId,
                field.key,
                rowData.date
              );
            }}
            className="manageari-input"
          />
        );
      case "toggle":
        return (
          <div className="manageari-toggle">
            <Toggle
              size="sm"
              role="switch"
              checked={rowData.isOpen}
              locale={{ on: "Open", off: "Closed" }}
              onChange={(checked) => {
                handleToggleChange(checked, rowData);
              }}
            >
              {isOpenLabel}
            </Toggle>
          </div>
        );
      default:
        return null;
    }
  };

  const downloadTemplate = () => {
    setHeaderMessage("Downloading ARI Template");
    setLoaderDesc("Please wait while we download the template");
    setShowLoader(true);
    downloadAriTemplate(selectedProperty.propertyId).then(
      (response) => {
        if (response.body) {
          let filename =
            response.headers["content-disposition"].split("filename=")[1];
          if (!filename) {
            toast.error("Error downloading the file");
            return;
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode.removeChild(link);
        }
        setHeaderMessage(null);
        setLoaderDesc(null);
        setShowLoader(false);
      },
      (error) => {
        setHeaderMessage(null);
        setLoaderDesc(null);
        setShowLoader(false);
        toast.error("Downloading ARI Template Failed");
      }
    );
  };

  const uploadAriFile = () => {
    if (files.length === 0) {
      toast.error(`No File selected to upload`);
      return;
    }

    setIsUploading(true);
    const token = localStorage.getItem("ROCP_idToken");
    const userId = jwtDecode(token)["_id"];
    const formData = new FormData();
    formData.append("uploadFile", files[0]);

    uploadAriByFile(userId, formData).then(
      (response) => {
        toast.success("Upload successful!");
        setIsUploading(false);
        setResetFiles(true);
      },
      (err) => {
        setIsUploading(false);
        setResetFiles(true);
        let errorMessage = `${err.errors.length} cells have bad entries.`;
        const errorLength = err.errors.length > 4 ? 4 : err.errors.length;
        for (let i = 0; i < errorLength; i++) {
          errorMessage += `\n\r\t${i + 1}. ${err.errors[i].description}`;
        }
        toast.error(errorMessage);
      }
    );
  };

  const renderColumns = (handleFieldChange) =>
    FIELDS.map((field) => {
      return (
        <Column key={field.key} width={field.width} data-testid="columnheader">
          <HeaderCell
            className="manageari-header"
            style={{
              backgroundColor: "#eaf0f5",
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "center",
              lineHeight: "1.2",
            }}
            dataKey={field.key}
          >
            {field.label}
          </HeaderCell>
          <Cell dataKey={field.key}>
            {(rowData) => renderField(rowData, field, handleFieldChange)}
          </Cell>
        </Column>
      );
    });

  const renderTable = (ariData) => {
    const updateRowData = (prevData, roomtypeId, fieldKey, date, value) => {
      // Clone the existing data to avoid side effects
      let updatedData = [...prevData];

      if (date !== "unless excluded") {
        updatedData = handleRegularRowUpdate(
          updatedData,
          roomtypeId,
          fieldKey,
          date,
          value
        );
      }

      if (date === "unless excluded") {
        updatedData = handleDummyRowUpdate(
          updatedData,
          roomtypeId,
          fieldKey,
          value
        );
      }

      updatedData = cleanUpDummyRow(updatedData);

      return updatedData;
    };

    // Helper function to handle regular row updates
    const handleRegularRowUpdate = (
      data,
      roomtypeId,
      fieldKey,
      date,
      value
    ) => {
      let rowUpdated = false;

      const updatedData = data.map((item) => {
        if (item.roomtypeId === roomtypeId && item.date === date) {
          rowUpdated = true;
          return { ...item, [fieldKey]: value }; // Update the matching row
        }
        return item;
      });

      if (!rowUpdated && date) {
        const originalRow = ariData.find(
          (item) => item.roomtypeId === roomtypeId && item.date === date
        );
        if (originalRow) {
          updatedData.push({ ...originalRow, [fieldKey]: value });
        }
      }

      return updatedData;
    };

    // Helper function to handle dummy row updates
    const handleDummyRowUpdate = (data, roomtypeId, fieldKey, value) => {
      const dummyRowIndex = data.findIndex((row) => row.isCustomRow);

      if (dummyRowIndex !== -1) {
        data[dummyRowIndex] = {
          ...data[dummyRowIndex],
          [fieldKey]: value,
        };
      } else {
        data.push({
          roomtypeId,
          date: "unless excluded",
          day: "Applies to all rows",
          price: null,
          sourceTax: null,
          isOpen: true,
          inventory: null,
          minLos: null,
          maxLos: null,
          minLeadTime: null,
          isCustomRow: true,
          [fieldKey]: value,
        });
      }

      return data;
    };

    // Helper function to clean up dummy rows
    const cleanUpDummyRow = (data) => {
      return data.filter((row) => {
        if (row.isCustomRow) {
          return hasMeaningfulValues(row); // Check if the dummy row has meaningful values
        }
        return true; // Keep all other rows
      });
    };

    // Function to check if a row has meaningful values
    const hasMeaningfulValues = (row) => {
      return Object.keys(row).some((key) => isValueMeaningful(key, row));
    };

    // Function to determine if a value is meaningful
    const isValueMeaningful = (key, row) => {
      return (
        key !== "isCustomRow" &&
        row[key] !== null &&
        row[key] !== undefined &&
        row[key] !== ""
      );
    };

    const handleFieldChange = (event, value, roomtypeId, fieldKey, date) => {
      let refinedValue = isNaN(value) ? "" : value;
      const input = event.target;
      const cursorPosition = input.selectionStart;

      setCurrentData((prevData) =>
        updateRowData(prevData, roomtypeId, fieldKey, date, refinedValue)
      );
      // Restore the cursor position
      setTimeout(() => {
        input.setSelectionRange(cursorPosition, cursorPosition);
      }, 5);
      const isDateSelected = selectedRows.some((row) => row.date === date);
      if (!isDateSelected) {
        setSelectedRows((prevSelectedRows) => {
          return [...prevSelectedRows, { date: date }];
        });
      }
    };

    const renderExcludeCell = (rowData) => {
      if (rowData.isCustomRow) {
        return "Bulk update:";
      }
      return (
        <input
          className="manageari-checkbox"
          type="checkbox"
          data-testid="checkbox"
          checked={selectedRows.some((row) => row.date === rowData.date)}
          onChange={(e) => handleCheckboxChange(e.target.checked, rowData)}
        />
      );
    };

    return (
      <Table
        height={500}
        data={preprocessAriData(ariData, currentData)}
        className="manageari-table"
        bordered
        cellBordered
        rowClassName={(rowData) => rowData?.isCustomRow && "custom-row"}
      >
        {/* Column Definitions */}
        <Column width={90} align="center" data-testid="columnheader">
          <HeaderCell
            style={{ backgroundColor: "#eaf0f5" }}
            className="manageari-header"
          >
            Exclude
            <MessagePopOver
              message={
                "Ticking the checkbox will exclude the row from bulk updates."
              }
            />
          </HeaderCell>
          <Cell>{(rowData) => renderExcludeCell(rowData)}</Cell>
        </Column>
        {renderColumns(handleFieldChange)}
      </Table>
    );
  };

  return (
    <Container className="manageari-page" data-testid="tabular-view-component">
      {loading && <Loader loading={loading} />}
      <Row>
        <Col xs={19} className="manageari-page-container">
          {errorMessage && (
            <div className="manageari-error-container">
              <img src={warning} color="red" alt="" />{" "}
              <div>
                <strong>Minimum Allowable Daily Rate</strong>
                <br />
                <span>The daily rate cannot be zero or negative</span>
              </div>
            </div>
          )}
          <RoomtypeTabs
            rateplanId={rateplan?.value}
            startDate={
              dateRange?.length > 1 ? dateToYMD(dateRange[0]) : undefined
            }
            endDate={
              dateRange?.length > 1 ? dateToYMD(dateRange[1]) : undefined
            }
            render={(ariData) => (
              <>
                {dateRange?.length > 1 ? (
                  <>
                    {renderTable(ariData)}
                    <div className="manageari-btn-container">
                      {preprocessAriData(ariData, currentData).length > 0 ? (
                        <Button
                          appearance="primary"
                          onClick={() => handleReset(ariData)}
                          className="manageari-reset"
                        >
                          Reset
                        </Button>
                      ) : null}
                      {preprocessAriData(ariData, currentData).length > 0 ? (
                        <Button
                          appearance="primary"
                          onClick={handleOpen}
                          className="manageari-save"
                          disabled={errorMessage}
                        >
                          Save Changes
                        </Button>
                      ) : null}

                      <ConfirmModal
                        show={showModal}
                        onClose={handleClose}
                        onConfirm={() => handleConfirm(ariData)}
                      />
                      <ConfirmModal
                        show={navigationModal}
                        onClose={handleNavigationModalClose}
                        onConfirm={confirmTabSwitch}
                        title="Your changes have not been saved"
                        message="The updates you made on this tab will be lost. Are you sure you want to leave without saving?"
                        confirmText="Yes, that's fine"
                      />
                      <LoaderModal
                        headerMessage={headerMessage}
                        loaderDesc={loaderDesc}
                        showLoader={showLoader}
                        setShowLoader={setShowLoader}
                      />
                    </div>
                  </>
                ) : (
                  <img
                    src={ariSelectRateplan}
                    alt="select rateplan"
                    className="mx-auto d-block"
                  />
                )}
              </>
            )}
            onConfirmTabChange={tabSwitch}
          />
        </Col>
        <Col xs={5}>
          <div className="upload-ari">
            <p>Prefer to upload an excel file?</p>
            <button onClick={downloadTemplate} className="download-button">
              Download template
            </button>
            <br />
            <div>
              <UploadFiles
                className="upload-section"
                text="Drag your updated file here"
                butttonText="Select"
                resetFiles={resetFiles}
                setResetFiles={setResetFiles}
                onFilesSelected={setFiles}
              />
              <br />
              <Button
                appearance="primary"
                className="upload-file-btn"
                onClick={() => uploadAriFile()}
                loading={isUploading}
                block
              >
                Upload file
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

TabularView.propTypes = {
  rateplan: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    data: PropTypes.shape({
      currencyCode: PropTypes.string,
    }),
  }),
};

export default TabularView;
