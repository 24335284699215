import React, { useEffect, useState } from "react";
import { Container, Row, Col, Animation } from "rsuite";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getPromotionsByRateplanId } from "../../api";
import Calendar from "../../components/calendar/Calendar";
import RoomtypeTabs from "../../components/roomtype-tabs/RoomtypeTabs";
import SpecificDateRightPanel from "./right-panel/SpecificDateRightPanel";
import BulkUpdateRightPanel from "./right-panel/BulkUpdateRightPanel";
import {
  selectQuickViewDateRange,
  setQuickViewDateRange,
} from "../../slices/ariDateRangeSlice";
import "./calender-view.scss";

const CalenderView = ({ rateplan, propertyId, isOffline }) => {
  const [selectedDateCell, setSelectedDateCell] = useState();

  // this state to control transistion of specific date modal, by controlling transistion by selected date cell as it starts hightlighting the fields in error state
  const [showingSpecificDateModal, setShowingSpecificDateModal] =
    useState(!!selectedDateCell);
  const [promos, setPromos] = useState([]);
  const dispatch = useDispatch();

  const currentMonth = useSelector(selectQuickViewDateRange);
  const setCurrentMonth = (date) => {
    setSelectedDateCell();
    dispatch(setQuickViewDateRange(date));
  };

  useEffect(() => {
    setShowingSpecificDateModal(!!selectedDateCell);
  }, [selectedDateCell, setShowingSpecificDateModal]);

  useEffect(() => {
    if (rateplan?.value && propertyId) {
      setSelectedDateCell();
      getPromotionsByRateplanId(propertyId, rateplan.value)
        .then((response) => {
          setPromos(
            response.data?.promos.filter(
              ({ bookingEnabled, disabled }) => bookingEnabled && !disabled
            )
          );
        })
        .catch((error) => {
          setPromos([]);
        });
    }
  }, [rateplan, propertyId]);

  const startDate = currentMonth.startOf("month").format("YYYY-MM-DD");
  const endDate = currentMonth.endOf("month").format("YYYY-MM-DD");

  const renderCalendar = (ariDataList) => {
    return (
      <Calendar
        ariDataList={ariDataList}
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
        selectedDateCell={selectedDateCell?.date}
        setSelectedDateCell={setSelectedDateCell}
      />
    );
  };

  return (
    <Container
      className="calender-view-page"
      data-testid="calender-view-component"
    >
      <Row>
        <Col xs={18}>
          <RoomtypeTabs
            startDate={startDate}
            endDate={endDate}
            rateplanId={rateplan?.value}
            render={renderCalendar}
            onTabSelect={setSelectedDateCell}
            includeNumberOfRoomsSold
          />
        </Col>
        <Col xs={6}>
          <Animation.Slide
            in={!!showingSpecificDateModal}
            placement="right"
            unmountOnExit={true}
          >
            {(props, ref) => (
              <SpecificDateRightPanel
                rateplan={rateplan}
                isOffline={isOffline}
                promos={promos}
                selectedDateCell={selectedDateCell}
                setSelectedDateCell={setSelectedDateCell}
                startDate={startDate}
                endDate={endDate}
                showingSpecificDateModal={showingSpecificDateModal}
                setShowingSpecificDateModal={setShowingSpecificDateModal}
                ref={ref}
                {...props}
              />
            )}
          </Animation.Slide>

          {!selectedDateCell && (
            <BulkUpdateRightPanel
              className="bulk-update-panel-wrapper"
              promos={promos}
              rateplan={rateplan}
              isOffline={isOffline}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

CalenderView.propTypes = {
  rateplan: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  isOffline: PropTypes.bool,
  propertyId: PropTypes.number.isRequired,
};

export default CalenderView;
