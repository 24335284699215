import React from "react";
import HTLoader from "../../assets/img/HT-loader.gif";
import cx from "classnames";
import "./progressloader.scss";
import PropTypes from "prop-types";

export const ProgressLoader = (props) => {
  const { className = "" } = props;
  return (
    <div className={cx("spinner", className)} data-testid="section-loader">
      <div className="loader-wrapper">
        <img className="loader-icon" src={HTLoader} alt="Loading..." />
      </div>
    </div>
  );
};

// Define propTypes
ProgressLoader.propTypes = {
  className: PropTypes.string, // Specify that className is optional and must be a string
};

export default ProgressLoader;
