import React, { useRef, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  Toggle,
  DatePicker,
  DateRangePicker,
  InputPicker,
  InputNumber,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { formatDate } from "../../constants/utils";
import { toast } from "react-toastify";

const adjustDateForTimezone = (date, offset) => {
  if (!date) return null;
  const adjustedDate = new Date(date.getTime());
  adjustedDate.setMinutes(adjustedDate.getMinutes() + offset);
  return adjustedDate;
};

function TargetingConditions({
  promo,
  handleFieldChange,
  tc,
  setTc,
  setIsNextBtnDisabled,
}) {
  const userTimezoneOffset = new Date().getTimezoneOffset();
  let fromBooking = promo?.rateRule?.ruleVO?.bookingVO?.bookingStartDate
    ? adjustDateForTimezone(
        new Date(promo.rateRule.ruleVO.bookingVO.bookingStartDate),
        userTimezoneOffset
      )
    : null;
  let toBooking = promo?.rateRule?.ruleVO?.bookingVO?.bookingEndDate
    ? adjustDateForTimezone(
        new Date(promo.rateRule.ruleVO.bookingVO.bookingEndDate),
        userTimezoneOffset
      )
    : null;
  let stayStartDate = promo?.rateRule?.ruleVO?.stayVO?.stayStartDate
    ? adjustDateForTimezone(
        new Date(promo.rateRule.ruleVO.stayVO.stayStartDate),
        userTimezoneOffset
      )
    : null;
  let stayEndDate = promo?.rateRule?.ruleVO?.stayVO?.stayEndDate
    ? adjustDateForTimezone(
        new Date(promo.rateRule.ruleVO.stayVO.stayEndDate),
        userTimezoneOffset
      )
    : null;

  let ast =
    promo?.rateRule?.ruleVO?.stayVO?.blackOutDateRanges?.[0]
      ?.blackOutStayThruEnabled || false;
  const [allowStayThrough, setAllowStayThrough] = useState(ast);

  let boDates = promo?.rateRule?.ruleVO?.stayVO?.blackOutDateRanges || [];
  if (boDates.length === 0) boDates = [null];
  else {
    boDates = boDates.map((bod) => {
      const startDate = bod?.blackOutStartDate
        ? adjustDateForTimezone(
            new Date(bod.blackOutStartDate),
            userTimezoneOffset
          )
        : null;
      const endDate = bod?.blackOutEndDate
        ? adjustDateForTimezone(
            new Date(bod.blackOutEndDate),
            userTimezoneOffset
          )
        : null;
      return [startDate, endDate];
    });
  }

  const [blackoutDates, setBlackoutDates] = useState(boDates);

  const setShowBooking = () => {
    if (tc.showBooking) {
      handleFieldChange("rateRule.ruleVO.bookingVO.bookingStartDate", null);
      handleFieldChange("rateRule.ruleVO.bookingVO.bookingEndDate", null);
    } else {
      handleFieldChange(
        "rateRule.ruleVO.bookingVO.bookingStartDate",
        formatDate(fromBooking)
      );
      handleFieldChange(
        "rateRule.ruleVO.bookingVO.bookingEndDate",
        formatDate(toBooking)
      );
    }
    setTc({ ...tc, showBooking: !tc.showBooking });
  };

  const handleBookingChange = (value, varb) => {
    const adjustedValue = value ? value : null;
    if (varb === "to") {
      toBooking = adjustedValue;
      handleFieldChange(
        "rateRule.ruleVO.bookingVO.bookingEndDate",
        formatDate(adjustedValue)
      );
    } else {
      fromBooking = adjustedValue;
      handleFieldChange(
        "rateRule.ruleVO.bookingVO.bookingStartDate",
        formatDate(adjustedValue)
      );
    }
  };

  const setShowPSD = () => {
    if (tc.showPSD) {
      handleFieldChange("rateRule.ruleVO.stayVO.stayStartDate", null);
      handleFieldChange("rateRule.ruleVO.stayVO.stayEndDate", null);
    } else {
      handleFieldChange(
        "rateRule.ruleVO.stayVO.stayStartDate",
        formatDate(stayStartDate)
      );
      handleFieldChange(
        "rateRule.ruleVO.stayVO.stayEndDate",
        formatDate(stayEndDate)
      );
    }
    setTc({ ...tc, showPSD: !tc.showPSD });
  };

  const handleStayChange = (value, varb) => {
    const adjustedValue = value ? value : null;
    if (varb === "to") {
      stayEndDate = adjustedValue;
      handleFieldChange(
        "rateRule.ruleVO.stayVO.stayEndDate",
        formatDate(adjustedValue)
      );
    } else {
      stayStartDate = adjustedValue;
      handleFieldChange(
        "rateRule.ruleVO.stayVO.stayStartDate",
        formatDate(adjustedValue)
      );
    }
  };

  const setShowBlackouts = () => {
    if (tc.showBlackouts) {
      handleFieldChange("rateRule.ruleVO.stayVO.blackOutDateRanges", []);
    } else {
      const bod = blackoutDates.map((d) => {
        return {
          blackOutStartDate: d ? formatDate(d[0]) : null,
          blackOutEndDate: d ? formatDate(d[1]) : null,
          blackOutStayThruEnabled: allowStayThrough,
        };
      });
      handleFieldChange("rateRule.ruleVO.stayVO.blackOutDateRanges", bod);
    }
    setTc({ ...tc, showBlackouts: !tc.showBlackouts });
  };

  const setBODates = (value, index) => {
    if (!value) {
      const dates = [...blackoutDates];
      dates[index] = null;
      dates.splice(index, 1);
      setFieldForBlackoutDates(dates);
      setBlackoutDates(dates);
      return;
    }
    if (value[0] === null || value[1] === null) return;
    if (fromBooking || stayStartDate) {
      if (value[0] < fromBooking || value[0] < stayStartDate) {
        toast.error(
          "Blackout start date cannot be before booking start date or stay start date"
        );
        setIsNextBtnDisabled(true);
        return;
      }
    }
    const dates = blackoutDates.map((item, idx) => {
      if (idx !== index) return item;
      return value ? [new Date(value[0]), new Date(value[1])] : null;
    });

    setIsNextBtnDisabled(false);
    //handleFieldChange("rateRule.ruleVO.stayVO.blackOutDateRanges", bod);
    setFieldForBlackoutDates(dates);
    setBlackoutDates(dates);
  };

  const setFieldForBlackoutDates = (dates) => {
    const bod = dates
      .filter((da) => !!da)
      .map((d) => {
        return {
          blackOutStartDate: formatDate(d[0]),
          blackOutEndDate: formatDate(d[1]),
          blackOutStayThruEnabled: allowStayThrough,
        };
      });
    handleFieldChange("rateRule.ruleVO.stayVO.blackOutDateRanges", bod);
  };

  const addBlackoutDate = () => {
    setBlackoutDates([...blackoutDates, null]);
    boDates = [...boDates, null];
  };

  const handleAllowStayThrough = () => {
    const dates = [...blackoutDates];
    const bod = dates.map((d) => {
      /*const startDate = adjustDateForTimezone(d[0], -userTimezoneOffset);
      const endDate = adjustDateForTimezone(d[1], -userTimezoneOffset);*/
      return {
        blackOutStartDate: formatDate(d[0]),
        blackOutEndDate: formatDate(d[1]),
        blackOutStayThruEnabled: allowStayThrough,
      };
    });
    handleFieldChange("rateRule.ruleVO.stayVO.blackOutDateRanges", bod);
    setAllowStayThrough(!allowStayThrough);
  };

  let ltt = "1";
  if (
    promo?.rateRule?.ruleVO?.bookingVO?.minBookingLeadTime &&
    promo?.rateRule?.ruleVO?.bookingVO?.maxBookingLeadTime
  ) {
    ltt = "3";
  } else if (promo?.rateRule?.ruleVO?.bookingVO?.maxBookingLeadTime) {
    ltt = "2";
  }

  let minBookingLeadTime =
    promo?.rateRule?.ruleVO?.bookingVO?.minBookingLeadTime;
  let maxBookingLeadTime =
    promo?.rateRule?.ruleVO?.bookingVO?.maxBookingLeadTime;

  const setShowLT = () => {
    if (tc.showLT) {
      handleFieldChange("rateRule.ruleVO.bookingVO.minBookingLeadTime", null);
      handleFieldChange("rateRule.ruleVO.bookingVO.maxBookingLeadTime", null);
    } else {
      handleFieldChange(
        "rateRule.ruleVO.bookingVO.minBookingLeadTime",
        minBookingLeadTime
      );
      handleFieldChange(
        "rateRule.ruleVO.bookingVO.maxBookingLeadTime",
        maxBookingLeadTime
      );
    }
    setTc({ ...tc, showLT: !tc.showLT });
  };

  const [ltType, setLTType] = useState(ltt);
  const ltTypes = [
    { label: "Minimum no. of days before check-in", value: "1" },
    { label: "Maximum no. of days before check-in", value: "2" },
    { label: "Range", value: "3" },
  ];

  const setLTMinMax = (value, type) => {
    if (value && value < 1) {
      toast.error("Minimum value is 1");
      return;
    }
    if (value && value > 999) {
      toast.error("Maximum value is 999");
      return;
    }
    if (type === "1") {
      minBookingLeadTime = value;
      handleFieldChange("rateRule.ruleVO.bookingVO.minBookingLeadTime", value);
      handleFieldChange("rateRule.ruleVO.bookingVO.maxBookingLeadTime", null);
    } else if (type === "2") {
      maxBookingLeadTime = value;
      handleFieldChange("rateRule.ruleVO.bookingVO.minBookingLeadTime", null);
      handleFieldChange("rateRule.ruleVO.bookingVO.maxBookingLeadTime", value);
    }
  };

  const setLTRange = (value, type) => {
    if (value && value < 1) {
      toast.error("Minimum value is 1");
      return;
    }
    if (value && value > 999) {
      toast.error("Maximum value is 999");
      return;
    }
    if (type === "min") {
      handleFieldChange("rateRule.ruleVO.bookingVO.minBookingLeadTime", value);
    } else {
      handleFieldChange("rateRule.ruleVO.bookingVO.maxBookingLeadTime", value);
    }
  };

  const getLTTypeInput = () => {
    if (ltType === "1") {
      return (
        <InputNumber
          value={minBookingLeadTime}
          min={1}
          max={999}
          onChange={(value) => setLTMinMax(value, ltType)}
          placeholder={"Min."}
        />
      );
    } else if (ltType === "2") {
      return (
        <InputNumber
          value={maxBookingLeadTime}
          min={1}
          max={999}
          onChange={(value) => setLTMinMax(value, ltType)}
          placeholder={"Max."}
        />
      );
    } else if (ltType === "3") {
      return (
        <Row>
          <Col md={6}>
            <div className={"d-flex align-items-center"}>
              Min:{" "}
              <InputNumber
                value={minBookingLeadTime}
                min={1}
                max={999}
                onChange={(value) => setLTRange(value, "min")}
                placeholder={"Min."}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className={"d-flex align-items-center"}>
              Max:{" "}
              <InputNumber
                value={maxBookingLeadTime}
                min={1}
                max={999}
                onChange={(value) => setLTRange(value, "max")}
                placeholder={"Max."}
              />
            </div>
          </Col>
        </Row>
      );
    }
    return null;
  };

  let minLos = promo?.rateRule?.ruleVO?.stayVO?.minLengthOfStay;
  let maxLos = promo?.rateRule?.ruleVO?.stayVO?.maxLengthOfStay;

  const setShowLOS = () => {
    if (tc.showLOS) {
      handleFieldChange("rateRule.ruleVO.stayVO.minLengthOfStay", null);
      handleFieldChange("rateRule.ruleVO.stayVO.maxLengthOfStay", null);
    } else {
      handleFieldChange("rateRule.ruleVO.stayVO.minLengthOfStay", minLos);
      handleFieldChange("rateRule.ruleVO.stayVO.maxLengthOfStay", maxLos);
    }
    setTc({ ...tc, showLOS: !tc.showLOS });
  };

  let lost = "1";
  if (
    promo?.rateRule?.ruleVO?.stayVO?.minLengthOfStay &&
    promo?.rateRule?.ruleVO?.stayVO?.maxLengthOfStay
  ) {
    lost = "3";
  } else if (promo?.rateRule?.ruleVO?.stayVO?.maxLengthOfStay) {
    lost = "2";
  }

  const [losType, setLOSType] = useState(lost);
  const losTypes = [
    { label: "Minimum length of stay", value: "1" },
    { label: "Maximum length of stay", value: "2" },
    { label: "Range", value: "3" },
  ];

  const setLosMinMax = (value, type) => {
    if (value && value < 1) {
      toast.error("Minimum value is 1");
      return;
    }
    if (value && value > 28) {
      toast.error("Maximum value is 28");
      return;
    }
    if (type === "1") {
      handleFieldChange("rateRule.ruleVO.stayVO.minLengthOfStay", value);
      handleFieldChange("rateRule.ruleVO.stayVO.maxLengthOfStay", null);
    } else if (type === "2") {
      handleFieldChange("rateRule.ruleVO.stayVO.minLengthOfStay", null);
      handleFieldChange("rateRule.ruleVO.stayVO.maxLengthOfStay", value);
    }
  };

  const setLosRange = (value, type) => {
    if (value && value < 1) {
      toast.error("Minimum value is 1");
      return;
    }
    if (value && value > 28) {
      toast.error("Maximum value is 28");
      return;
    }
    if (type === "min") {
      handleFieldChange("rateRule.ruleVO.stayVO.minLengthOfStay", value);
    } else {
      handleFieldChange("rateRule.ruleVO.stayVO.maxLengthOfStay", value);
    }
  };

  const getLosTypeInput = () => {
    if (losType === "1") {
      return (
        <InputNumber
          value={minLos}
          min={1}
          max={28}
          onChange={(value) => setLosMinMax(value, losType)}
          placeholder={"Min."}
        />
      );
    } else if (losType === "2") {
      return (
        <InputNumber
          value={maxLos}
          min={1}
          max={28}
          onChange={(value) => setLosMinMax(value, losType)}
          placeholder={"Max."}
        />
      );
    } else if (losType === "3") {
      return (
        <Row>
          <Col md={6}>
            <div className={"d-flex align-items-center"}>
              <InputNumber
                value={minLos}
                min={1}
                max={28}
                onChange={(value) => setLosRange(value, "min")}
                placeholder={"Min."}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className={"d-flex align-items-center"}>
              <InputNumber
                value={maxLos}
                min={1}
                max={28}
                onChange={(value) => setLosRange(value, "max")}
                placeholder={"Max."}
              />
            </div>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const ltTypePopUpRef = useRef(null);
  const losTypePopUpRef = useRef(null);
  const boDateCalendarPopupRef = useRef([]);

  return (
    <div className="targetting-conditions">
      <div className="tc-section">
        <div className="tc-section-title">
          <span className="title">
            1. Does your promotion require a booking window?
          </span>
          <Toggle checked={tc.showBooking} onChange={() => setShowBooking()} />
        </div>
        <Collapse isOpen={tc.showBooking}>
          <Row>
            <Col md={6}>
              {renderDateComponent(
                fromBooking,
                handleBookingChange,
                "From:",
                "from",
                useRef(null)
              )}
            </Col>
            <Col md={6}>
              {renderDateComponent(
                toBooking,
                handleBookingChange,
                "To:",
                "to",
                useRef(null)
              )}
            </Col>
          </Row>
        </Collapse>
      </div>

      <div className="tc-section">
        <div className="tc-section-title">
          <span className="title">
            2. Does your promotion require setting up particular stay dates?
          </span>
          <Toggle checked={tc.showPSD} onChange={() => setShowPSD()} />
        </div>
        <Collapse isOpen={tc.showPSD}>
          <Row>
            <Col md={6}>
              {renderDateComponent(
                stayStartDate,
                handleStayChange,
                "Stay Start:",
                "from",
                useRef(null)
              )}
            </Col>
            <Col md={6}>
              {renderDateComponent(
                stayEndDate,
                handleStayChange,
                "Stay End:",
                "to",
                useRef(null)
              )}
            </Col>
          </Row>
        </Collapse>
      </div>

      <div className="tc-section">
        <div className="tc-section-title">
          <span className="title">
            3. Does your promotion contain blackout dates?
          </span>
          <Toggle
            checked={tc.showBlackouts}
            onChange={() => setShowBlackouts()}
          />
        </div>
        <Collapse isOpen={tc.showBlackouts}>
          <Row>
            <Col md={6}>
              {blackoutDates &&
                blackoutDates.map((dates, index) => (
                  <>
                    <DateRangePicker
                      placement={"rightStart"}
                      key={index}
                      value={dates}
                      onChange={(value) => setBODates(value, index)}
                      label="BO Range:"
                      block
                      container={() => boDateCalendarPopupRef.current[index]}
                    />
                    <div
                      className="position-relative"
                      ref={(ref) =>
                        (boDateCalendarPopupRef.current[index] = ref)
                      }
                    />
                  </>
                ))}
              <br />
              <Button
                onClick={() => addBlackoutDate()}
                outline
                className={"mt-3"}
              >
                <i className="fa fa-plus"></i> Add another blackout
              </Button>
            </Col>
            <Col md={6}>
              <div
                onClick={handleAllowStayThrough}
                className={
                  allowStayThrough
                    ? "staythrough allow cursor-pointer"
                    : "staythrough cursor-pointer"
                }
              >
                <span>
                  <i
                    className={
                      allowStayThrough
                        ? "fa-lg fa fa-check-square-o"
                        : "fa-lg fa fa-square-o"
                    }
                  ></i>
                  &nbsp;Allow staythrough for your blackout dates
                </span>
              </div>
            </Col>
          </Row>
        </Collapse>
      </div>

      <div className="tc-section">
        <div className="tc-section-title">
          <span className="title">
            4. Does your promotion have any lead time requirements?
          </span>
          <Toggle checked={tc.showLT} onChange={() => setShowLT()} />
        </div>

        <Collapse isOpen={tc.showLT}>
          <Row>
            <Col md={6}>
              <InputPicker
                defaultValue={ltType}
                onChange={(value) => setLTType(value)}
                data={ltTypes}
                block
                container={() => ltTypePopUpRef.current}
              />
              <div className="position-relative" ref={ltTypePopUpRef} />
            </Col>
            <Col md={6}>{getLTTypeInput()}</Col>
          </Row>
        </Collapse>
      </div>

      <div className="tc-section">
        <div className="tc-section-title">
          <span className="title">
            5. Does your promotion apply to only a particular length of stay?
          </span>
          <Toggle checked={tc.showLOS} onChange={() => setShowLOS()} />
        </div>

        <Collapse isOpen={tc.showLOS}>
          <Row>
            <Col md={6}>
              <InputPicker
                defaultValue={losType}
                onChange={(value) => setLOSType(value)}
                placeholder="Choose an option"
                data={losTypes}
                block
                container={() => losTypePopUpRef.current}
              />
              <div className="position-relative" ref={losTypePopUpRef} />
            </Col>
            <Col md={6}>{getLosTypeInput()}</Col>
          </Row>
        </Collapse>
      </div>
    </div>
  );
}

function renderDateComponent(date, handleOnChange, label, verb, ref) {
  return (
    <>
      <DatePicker
        key={date || "empty"}
        value={date}
        onChange={(value) => handleOnChange(value, verb)}
        label={label}
        oneTap
        block
        className="position-relative"
        container={() => ref.current}
      />
      <div ref={ref} className="position-relative" />
    </>
  );
}

export default TargetingConditions;
