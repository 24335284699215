import React from "react";
import {
  Col,
  Form,
  Row,
  InputGroup,
  SelectPicker,
  DateRangePicker,
  Button,
} from "rsuite";
import PeoplesIcon from "@rsuite/icons/Peoples";
import CouponIcon from "@rsuite/icons/Coupon";
import GridIcon from "@rsuite/icons/Grid";
import CloseIcon from "@rsuite/icons/Close";
import PropTypes from "prop-types";
import "./resReport.scss";

const ResReportForm = ({
  isLoading,
  setReservationsPage,
  formSubmitted,
  source,
  setIsDrawerOpen,
  cancelledData,
  initFormValue,
  formValue,
  setFormValue,
}) => {
  const {
    lastName,
    firstName,
    htConfirmationCode,
    pmsId,
    sourceRoomConfirmationNumber,
    cancelledReservation,
    checkIn,
    checkOut,
    bookingDate,
  } = formValue;

  const handleSearch = async () => {
    // calling api
    // user can get reservation report even without entering any value
    setReservationsPage((prevState) => ({
      ...prevState,
      number: 0,
    }));

    formSubmitted(formValue);
  };

  const onFormChange = (key, value) => {
    setFormValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const crsIdField = (
    <Col xs={5} xsPush={2}>
      <Form.Group controlId="sourceRoomConfirmationNumber">
        <Form.ControlLabel>CRS ID</Form.ControlLabel>
        <InputGroup inside>
          <InputGroup.Addon>
            <CouponIcon />
          </InputGroup.Addon>
          <Form.Control
            name="sourceRoomConfirmationNumber"
            placeholder="Enter ID"
            value={sourceRoomConfirmationNumber}
            onChange={(value) =>
              onFormChange("sourceRoomConfirmationNumber", value)
            }
          />
        </InputGroup>
      </Form.Group>
    </Col>
  );

  return (
    <div className="res-form-container">
      <p className="page-header"> Reservation Report</p>
      <Form formValue={formValue}>
        <Row>
          <Col xs={5}>
            <Form.Group controlId="lastName">
              <Form.ControlLabel>Guest Last name</Form.ControlLabel>
              <InputGroup inside>
                <InputGroup.Addon>
                  <PeoplesIcon />
                </InputGroup.Addon>
                <Form.Control
                  name="lastName"
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={(value) => onFormChange("lastName", value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={5} xsPush={1}>
            <Form.Group controlId="firstName">
              <Form.ControlLabel>Guest First name</Form.ControlLabel>
              <InputGroup inside>
                <InputGroup.Addon>
                  <PeoplesIcon />
                </InputGroup.Addon>
                <Form.Control
                  name="firstName"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={(value) => onFormChange("firstName", value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          {crsIdField}
          {source === "drawer" && (
            <Col xs={2} xsPush={7}>
              <Button
                appearance="ghost"
                onClick={() => setIsDrawerOpen(false)}
                endIcon={<CloseIcon />}
              >
                Close
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={5}>
            <Form.Group controlId="htConfirmationCode">
              <Form.ControlLabel>HT confirmation code</Form.ControlLabel>
              <InputGroup inside>
                <InputGroup.Addon>
                  <GridIcon />
                </InputGroup.Addon>
                <Form.Control
                  name="htConfirmationCode"
                  placeholder="Enter code"
                  value={htConfirmationCode}
                  onChange={(value) =>
                    onFormChange("htConfirmationCode", value)
                  }
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={5} xsPush={1}>
            <Form.Group controlId="pmsId">
              <Form.ControlLabel>PMS ID</Form.ControlLabel>
              <InputGroup inside>
                <InputGroup.Addon>
                  <CouponIcon />
                </InputGroup.Addon>
                <Form.Control
                  name="pmsId"
                  placeholder="Enter number"
                  value={pmsId}
                  onChange={(value) => onFormChange("pmsId", value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={5} xsPush={2}>
            <Form.Group controlId="cancelledReservation">
              <Form.ControlLabel>Cancelled reservations</Form.ControlLabel>
              <SelectPicker
                data={cancelledData}
                searchable={false}
                name="cancelledReservation"
                block
                value={cancelledReservation}
                onChange={(value) =>
                  onFormChange("cancelledReservation", value)
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            <Form.Group controlId="checkIn">
              <Form.ControlLabel>Check-in dates</Form.ControlLabel>
              <DateRangePicker
                block
                value={checkIn}
                placeholder="Choose dates"
                onChange={(value) => onFormChange("checkIn", value)}
                format="yyyy-MM-dd"
              />
            </Form.Group>
          </Col>
          <Col xs={5} xsPush={1}>
            <Form.Group controlId="checkOut">
              <Form.ControlLabel>Check-out dates</Form.ControlLabel>
              <DateRangePicker
                block
                value={checkOut}
                placeholder="Choose dates"
                onChange={(value) => onFormChange("checkOut", value)}
                format="yyyy-MM-dd"
              />
            </Form.Group>
          </Col>
          <Col xs={5} xsPush={2}>
            <Form.Group controlId="bookingDate">
              <Form.ControlLabel>Booking creation dates</Form.ControlLabel>
              <DateRangePicker
                block
                value={bookingDate}
                placeholder="Choose dates"
                onChange={(value) => onFormChange("bookingDate", value)}
                format="yyyy-MM-dd"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Button
              appearance="ghost"
              onClick={() => setFormValue(initFormValue)}
            >
              Reset
            </Button>
          </Col>
          <Col xs={2}>
            <Button
              appearance="primary"
              loading={isLoading}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

ResReportForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getReservationReport: PropTypes.func.isRequired,
  formSubmitted: PropTypes.func.isRequired,
  source: PropTypes.string,
  setIsDrawerOpen: PropTypes.func,
  cancelledData: PropTypes.array,
  initFormValue: PropTypes.object,
  formValue: PropTypes.object,
  setFormValue: PropTypes.func,
};

export default ResReportForm;
