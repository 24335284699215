import { Badge, Button, Loader } from "rsuite";
import { useEffect, useRef, useState } from "react";
import "./notification.scss";
import { ReactComponent as NotificationIcon } from "../../assets/img/notification.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/close.svg";
import NotificationTile from "./NotificationTile";
import InfiniteScroll from "../infinite-scroll/InfiniteScroll";
import { getAlerts } from "../../api";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { OFFLINE_CRS_ID } from "../../constants";
import classNames from "classnames";
import { getLoggedInUserId } from "../../constants/utils";
import { isWindowsOS } from "../../utils/utils";

const PAGE_SIZE = isWindowsOS() ? 9 : 10; // number of notifications to fetch per page to support windows

const Notification = ({ propertyId, crsId }) => {
  const featureFlagNotification = crsId === OFFLINE_CRS_ID;

  const [showPopup, setShowPopup] = useState(false);
  const [count, setCount] = useState(0);

  // state for infinite scroll
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const loadMore = async () => {
    if (hasMore) {
      await fetchNotifications(page);
    }
  };

  const updateAlertReadStatus = (id, isRead) => {
    setAlerts((prevAlerts) =>
      prevAlerts.map((alert) =>
        alert.id === id ? getUpdatedAlertObject(alert, isRead) : alert
      )
    );
  };

  const getUpdatedAlertObject = (item, isRead) => ({
    ...item,
    userActions: updateUserActionsReadStatus(item.userActions, isRead),
  });

  const updateUserActionsReadStatus = (userActions, isRead) =>
    userActions.map((action) =>
      action.userId === getLoggedInUserId() ? { ...action, isRead } : action
    );

  const markAlertDeleted = (id, isDeleted) => {
    setAlerts((prevAlerts) =>
      prevAlerts.map((alert) =>
        alert.id === id ? { ...alert, isDeleted } : alert
      )
    );
  };

  const fetchNotifications = async (pageNo, showPopup) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await getAlerts(pageNo, PAGE_SIZE, propertyId);
      const { alerts, totalPage, unread } = data;
      if (alerts.length > 0) {
        setAlerts((prevItems) => [...prevItems, ...alerts]);
        setCount(unread);
      }
      if (showPopup && unread > 0) {
        setShowPopup(true);
      }
      setHasMore(totalPage > pageNo);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      toast.error("Failed to retrieve notifications");
    } finally {
      setLoading(false);
    }
  };

  const resetNotificationState = () => {
    setAlerts([]);
    setCount(0);
    setHasMore(true);
    setLoading(false);
    setPage(1);
  };

  useEffect(() => {
    resetNotificationState();
    if (featureFlagNotification) {
      fetchNotifications(1, true);
    }
    // based on the feature flag, we want to show the notification popup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  const toggleNotification = () => setShowPopup(!showPopup);

  const notificationContentRef = useRef(null);
  const notificationMainRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationMainRef.current &&
        !notificationMainRef.current.contains(event.target)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  if (!featureFlagNotification) {
    return null;
  }

  return (
    <div
      className="notification"
      data-testid="notification-popup"
      ref={notificationMainRef}
    >
      <Button
        className={classNames("notification__button", {
          "notification__button-active": showPopup,
        })}
        onClick={toggleNotification}
      >
        <NotificationIcon className="notification__icon" />
        Notifications
        <Badge
          content={loading && alerts.length === 0 ? <Loader /> : count}
          className="notification__badge"
        />
      </Button>
      {showPopup && (
        <div
          className={classNames("notification__popup", {
            zero__notification: alerts.length < 4,
          })}
          data-testid="notification-main"
        >
          {loading && page === 1 && (
            <div className="notification__overlay">
              <Loader />
            </div>
          )}
          <div className="notification__header">
            <span>
              You have <span className={"font-weight-700"}>{count}</span> unread
              notifications&nbsp;
            </span>
            <Button
              onClick={toggleNotification}
              ripple={false}
              className="notification__close"
            >
              Close <CloseIcon className="mx-1" width={18} />
            </Button>
          </div>
          <div className="notification__content" ref={notificationContentRef}>
            <InfiniteScroll
              onLoadMore={loadMore}
              isLoading={loading}
              hasMore={hasMore}
              loadingComponent={
                <Loader className="notification__content__loader" />
              }
              root={notificationContentRef.current}
              threshold={0.1}
            >
              {alerts
                .filter((alert) => alert?.isDeleted !== true)
                .map((alert) => (
                  <NotificationTile
                    key={alert.id}
                    data={alert}
                    updateReadInMainList={updateAlertReadStatus}
                    count={count}
                    setCount={setCount}
                    markDeletedInMainList={markAlertDeleted}
                  />
                ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
      <span className="notification__separator"></span>
    </div>
  );
};

Notification.propTypes = {
  propertyId: PropTypes.number.isRequired,
  crsId: PropTypes.number.isRequired,
};

export default Notification;
