import React from "react";
import { Modal, Button } from "rsuite";
import PropTypes from "prop-types"; // Import PropTypes
import "./confirmmodal.scss";

const ConfirmModal = ({
  show,
  onConfirm,
  onClose,
  title,
  message,
  confirmText,
}) => {
  return (
    <Modal className="confirm-modal" open={show} size="sm" backdrop="static">
      <Modal.Header>
        <Modal.Title>{title ?? "Confirm changes"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message ?? "Are you sure you want to make these ARI updates?"}
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={onClose} className="close">
          No, go back
        </Button>
        <Button appearance="primary" color="red" onClick={onConfirm}>
          {confirmText ?? "Yes, continue"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Add PropTypes validation
ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmText: PropTypes.string,
};

export default ConfirmModal;
