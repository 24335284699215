import React, { useEffect, useState } from "react";
import CloseIcon from "@rsuite/icons/Close";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import FilterIcon from "../../assets/img/cloud-upload.svg";
import PropTypes from "prop-types";
import "./uploadFiles.scss";
import { toast } from "react-toastify";

const UploadFiles = ({
  text,
  butttonText,
  resetFiles,
  setResetFiles,
  onFilesSelected,
  className,
  isSingleFile = true,
}) => {
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
    setResetFiles?.(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const fileExtension = droppedFiles[0].name.split(".").pop();
    const expectedSupportedFormats = ["xlsx", "csv", "xls"];
    if (!expectedSupportedFormats.includes(fileExtension.toLowerCase())) {
      toast.error(
        "Uploaded file format is not supported, please upload .xlsx and csv files"
      );
      return;
    }
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  useEffect(() => {
    resetFiles && setFiles([]) && onFilesSelected([]);
  }, [resetFiles, onFilesSelected]);

  const getFileSize = (size) => {
    const fSExt = ["Bytes", "KB", "MB", "GB"];
    let i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    const exactSize = Math.round(size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };

  return (
    <>
      {files.length > 0 ? (
        <div className={`selected-files ${className}`}>
          {files.map((file, index) => (
            <div key={`file-${index + 1}`} className="uploaded-files">
              <FileUploadIcon className="file-icon" />
              <div className="file-detail">
                <span>{file.name}</span>
                <span>{getFileSize(file.size)}</span>
              </div>
              <CloseIcon
                className="remove-icon"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>
      ) : (
        <section
          className={`upload-file-sec ${className}`}
          aria-label="Drop zone"
          aria-labelledby="upload-file"
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <img className="icon" src={FilterIcon} alt="filter" />
          <span className="drag-text">{text}</span>
          <span className="or">or</span>
          <input
            type="file"
            hidden
            id="browse"
            data-testid="file-input"
            onChange={handleFileChange}
            accept=".csv,.xlsx,.xls"
            multiple={!isSingleFile}
          />
          <label className="rs-btn rs-btn-ghost upload-btn" htmlFor="browse">
            {butttonText}
          </label>
          <span className="support-file-text">Supported format: XLSX</span>
        </section>
      )}
    </>
  );
};

UploadFiles.propTypes = {
  text: PropTypes.string,
  butttonText: PropTypes.string,
  resetFiles: PropTypes.bool,
  setResetFiles: PropTypes.func,
  onFilesSelected: PropTypes.func,
  className: PropTypes.string,
  isSingleFile: PropTypes.bool,
};

export default UploadFiles;
