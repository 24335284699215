import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as TickIcon } from "../../../assets/img/tick.svg";
import "./popUp.scss";

const PopUp = ({ success, failure, fileName }) => {
  return (
    <div>
      {success && (
        <div className="success-block">
          <div className="success-icon">
            <TickIcon />
          </div>
          {fileName && <div className="file">{fileName}</div>}
          <div className="success-text">
            File uploaded. PMS IDs updated successfully
          </div>
        </div>
      )}
      {failure && (
        <div className="failure-block">
          Upload failed. Please try again later.
        </div>
      )}
    </div>
  );
};

PopUp.propTypes = {
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  fileName: PropTypes.string,
};

export default PopUp;
