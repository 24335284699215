import React from "react";
import { Row, Col } from "rsuite";
import PropTypes from "prop-types";
import "./right-panel.scss";

const RoomsARIStatus = ({ roomsSold, allotment }) => {
  return (
    <Row className="rooms-container">
      <Col xs={12}>
        <div>Total rooms sold</div>
        <div>{roomsSold ?? "N/A"}</div>
      </Col>
      <Col xs={12}>
        <div>Rooms left to sell</div>
        <div>{allotment ?? "N/A"}</div>
      </Col>
    </Row>
  );
};

RoomsARIStatus.propTypes = {
  roomsSold: PropTypes.number,
  allotment: PropTypes.number,
};

export default RoomsARIStatus;
