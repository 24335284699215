import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Col, Modal, Row } from "rsuite";
import { DEFAULT_COLUMNS, ALL_COLUMNS } from "./utils";

const ReportShowHideColumnModal = ({
  isShowHideColumn,
  isOffline,
  setIsShowHideColumn,
  settingColumn,
}) => {
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);
  const updateColumns = () => {
    const checkedColumns = ALL_COLUMNS.filter((col) => {
      return columns.find((column) => column.dataKey === col.dataKey);
    });
    settingColumn(checkedColumns);
    setIsShowHideColumn(false);
  };

  const resetColumns = () => {
    setColumns(DEFAULT_COLUMNS);
  };

  const onColumnCheck = (column, dataKey) => (value, checked) => {
    if (checked) {
      setColumns((prevState) => [...prevState, column]);
    } else {
      const newColumns = [...columns];
      const updatedColumns = newColumns.filter(
        (col) => col.dataKey !== dataKey
      );
      setColumns(updatedColumns);
    }
  };

  const closeShowHideColumn = () => {
    setIsShowHideColumn(false);
  };

  const allColumns = ALL_COLUMNS;

  return (
    <Modal
      backdrop="static"
      size="lg"
      className="show-hide-column-modal"
      keyboard={false}
      open={isShowHideColumn}
      onClose={closeShowHideColumn}
    >
      <Modal.Header>
        <Modal.Title>Show / hide columns</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          {allColumns.map((column) => {
            const { label, dataKey } = column;
            const checked = columns.find((col) => col.dataKey === dataKey);
            return (
              <Col key={label} xs={8}>
                <div className="show-hide-cell">
                  <Checkbox
                    defaultChecked={!!checked}
                    value={dataKey}
                    checked={!!checked}
                    onChange={onColumnCheck(column, dataKey)}
                  >
                    {label}
                  </Checkbox>
                </div>
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={resetColumns} appearance="ghost">
          Reset
        </Button>
        <Button onClick={updateColumns} appearance="primary">
          Update table
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ReportShowHideColumnModal.propTypes = {
  isShowHideColumn: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  setIsShowHideColumn: PropTypes.func.isRequired,
  settingColumn: PropTypes.func.isRequired,
};

export default ReportShowHideColumnModal;
