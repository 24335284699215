import PropTypes from "prop-types";
import CalendarNavigation from "./CalendarNavigation";
import Cells from "./Cells";
import "./calendar.scss";
import dayjs from "dayjs";

const Calendar = ({
  ariDataList,
  currentMonth,
  setCurrentMonth,
  selectedDateCell,
  setSelectedDateCell,
}) => {
  return (
    <div className="calendar container-fluid">
      <CalendarNavigation
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
      />
      <Cells
        ariDataList={ariDataList}
        currentMonth={currentMonth}
        selectedDateCell={selectedDateCell}
        setSelectedDateCell={setSelectedDateCell}
      />
    </div>
  );
};

Calendar.propTypes = {
  ariDataList: PropTypes.array,
  selectedDateCell: PropTypes.instanceOf(dayjs),
  setSelectedDateCell: PropTypes.func.isRequired,
  currentMonth: PropTypes.instanceOf(dayjs).isRequired,
  setCurrentMonth: PropTypes.func.isRequired,
};

export default Calendar;
