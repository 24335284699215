import React, { useState } from "react";
import CloseIcon from "@rsuite/icons/Close";
import { Button, Modal } from "rsuite";
import { uploadOfflineExcel } from "../../api";
import { jwtDecode } from "jwt-decode";
import UploadFiles from "../../components/upload-file/uploadFiles";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import PopUp from "./pop-up/popUp";
import LoaderModal from "../../components/loader-modal/LoaderModal";

const UploadPms = (props) => {
  const {
    isUploadSidePanelOpen,
    getReservationReport,
    setIsUploadSidePanelOpen,
    exportReservation,
    isExportLoading,
    className,
  } = props;

  const [isShowDescriptionModal, setIsShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [resetFiles, setResetFiles] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fileName, setFileName] = useState("");
  const closeModal = () => {
    setIsShowDescriptionModal(false);
  };

  const openModal = (type = "pms") => {
    if (type === "pms") {
      setModalTitle("Uploading PMS IDs");
      setDescription(
        <ul>
          <li>
            In the <b>Reservation report</b> search for the bookings by
            selecting <b>Exclude cancelled reservations</b> and Booking date
            range from yesterday to today`s date and click on Search
          </li>
          <li>
            Download the bookings in excel format, by clicking on{" "}
            <b>Export to XLSX</b>
          </li>
          <li>Create the bookings in your system</li>
          <li>
            Enter the <b>PMS IDs</b> for all the bookings into the downloaded
            excel sheet
          </li>
          <li>
            Upload the excel sheet in the <b>PMS ID/CXL Code</b> panel.
          </li>
        </ul>
      );
    } else {
      setModalTitle("Uploading CXL codes");
      setDescription(
        <ul>
          <li>
            In the <b>Reservation report</b> search for the bookings by
            selecting <b>Only canceled reservations</b> and Cancellation date
            range from yesterday to today`s date and click on Search
          </li>
          <li>
            Download the bookings in excel format, by clicking on{" "}
            <b>Export to XLSX</b>
          </li>
          <li>Create the bookings in your system</li>
          <li>
            Enter the <b>CXL codes</b> for all the bookings into the downloaded
            excel sheet
          </li>
          <li>
            Upload the excel sheet in the <b>PMS ID/CXL Code</b> panel.
          </li>
        </ul>
      );
    }
    setIsShowDescriptionModal(true);
  };

  const uploadToServer = async () => {
    if (files.length === 0) {
      toast.error(`No File selected to upload`);
      return;
    }
    setIsUploading(true);
    try {
      const token = localStorage.getItem("ROCP_idToken");
      const username = jwtDecode(token).username;
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("modifiedBy", username);
      setFileName(formData.get("file").name);
      const response = await uploadOfflineExcel(formData);
      const res = response.data;
      if (response.status === 200 || response.status === 202) {
        if (!res.errorMessage && res.failures && res.failures.length !== 0) {
          toast.error(
            `${res.sucesses.length} reservations imported successfully. ${
              res.failures.length
            } reservations failed. The following reservations has an error and requires a fix before it can be imported: (${res.failures.join(
              ","
            )})`
          );
        } else if (res.errorMessage) {
          setIsError(true);
        }
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setTimeout(() => {
        setIsUploading(false);
        setResetFiles(true);
      }, 2000);
      await getReservationReport();
      setIsError(false);
    }
  };

  return (
    <div className={className}>
      <div className="upload-header">
        <span>Upload PMS ID / Cancellation ID</span>
        <CloseIcon
          className="close-icon"
          data-testid="close-icon"
          onClick={() => setIsUploadSidePanelOpen(!isUploadSidePanelOpen)}
        />
      </div>
      <div className="upload-body">
        <p>
          Use the Reservation Report Search to find the bookings you need and
          download them as an XLSX file. Update the PMS IDs on the downloaded
          file or add CXL codes and then upload the updated spreadsheet below.
        </p>
        <span>Step-by-step instructions:</span>
        <div className="modal-link">
          <button onClick={() => openModal("pms")}>PMS IDs</button>
          <button onClick={() => openModal("cxl")}>CXL codes</button>
        </div>
        <hr />
        <span>Download bookings:</span>
        <Button
          block
          loading={isExportLoading}
          appearance="primary"
          className="export-btn"
          onClick={() => exportReservation()}
        >
          Export XLSX
        </Button>
        <span>Upload updated file:</span>
        <PopUp success={isUploading} failure={isError} fileName={fileName} />
        <UploadFiles
          className="upload-section"
          text="Drag your updated file here"
          butttonText="Select"
          resetFiles={resetFiles}
          setResetFiles={setResetFiles}
          onFilesSelected={setFiles}
        />
        <Button
          appearance="primary"
          className="upload-btn"
          onClick={() => uploadToServer()}
          loading={isUploading}
          block
        >
          Upload file
        </Button>
      </div>
      <Modal
        backdrop="static"
        size="sm"
        className="description-modal"
        keyboard={false}
        open={isShowDescriptionModal}
        onClose={closeModal}
      >
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{description}</Modal.Body>
      </Modal>
      <LoaderModal
        headerMessage="Updating report"
        showLoader={isUploading}
        setShowLoader={setIsUploading}
      />
    </div>
  );
};

UploadPms.displayName = "Panel";

UploadPms.propTypes = {
  isUploadSidePanelOpen: PropTypes.bool.isRequired,
  getReservationReport: PropTypes.func.isRequired,
  setIsUploadSidePanelOpen: PropTypes.func.isRequired,
  exportReservation: PropTypes.func.isRequired,
  isExportLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default UploadPms;
