import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import locale from "dayjs/locale/en";

const initialState = {
  quickViewDateRange: dayjs().locale({
    ...locale,
    weekStart: 0, // set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
  }),
  manageARIDateRange: null,
};

const ariDateRangeSlice = createSlice({
  name: "ariDateRange",
  initialState,
  reducers: {
    setQuickViewDateRange(state, action) {
      state.quickViewDateRange = action.payload;
    },
    setManageARIDateRange(state, action) {
      state.manageARIDateRange = action.payload;
    },
    resetDateRanges(state) {
      state.quickViewDateRange = initialState.quickViewDateRange;
      state.manageARIDateRange = null;
    },
  },
  selectors: {
    selectQuickViewDateRange: (state) => state.quickViewDateRange,
    selectManageARIDateRange: (state) => state.manageARIDateRange,
  },
});

export const { setQuickViewDateRange, setManageARIDateRange, resetDateRanges } =
  ariDateRangeSlice.actions;

export const { selectQuickViewDateRange, selectManageARIDateRange } =
  ariDateRangeSlice.selectors;

export default ariDateRangeSlice;
