import { Badge } from "rsuite";
import { ReactComponent as MailIcon } from "../../assets/img/mail.svg";
import { ReactComponent as MailOpen } from "../../assets/img/mail-open.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/close.svg";
import { ReactComponent as CloseReadIcon } from "../../assets/img/close-read.svg";
import { deleteAlert, markAlertRead, markAlertUnread } from "../../api";
import { formatDateString, getLoggedInUserId } from "../../constants/utils";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useState } from "react";

const NotificationTile = ({
  data,
  count,
  setCount,
  updateReadInMainList,
  markDeletedInMainList,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { id, eventType, createdAt, alertText, userActions } = data;
  const read = userActions?.find(
    (action) => action?.userId === getLoggedInUserId()
  )?.isRead;

  const handleAlertAction = async (action) => {
    const originalRead = read;
    const originalCount = count;
    try {
      if (action === "read") {
        setCount(count - 1);
        updateReadInMainList(id, true);
        await markAlertRead(id);
      } else if (action === "unread") {
        setCount(count + 1);
        updateReadInMainList(id, false);
        await markAlertUnread(id);
      } else if (action === "delete") {
        setIsDeleting(true);
        setCount(read ? count : count - 1);
        await deleteAlert(id);
      }
    } catch (error) {
      updateReadInMainList(data?.id, originalRead);
      setCount(originalCount);
      markDeletedInMainList(data?.id, false);
      toast.error("Failed to perform action on notification");
    }
  };

  const formatAlertText = (text) => {
    const parts = text.split(/(<b>.*?<\/b>)/g);
    return parts.map((part, index) => {
      if (part.startsWith("<b>") && part.endsWith("</b>")) {
        const text = part.replace(/<\/?b>/g, "");
        const key = `bold-${text}-${text.indexOf(part)}`;
        return <strong key={key}>{text}</strong>;
      }
      return part;
    });
  };

  return (
    <div
      className={classNames("notification__tile", {
        active: !read,
        "delete-animation": isDeleting,
      })}
      onAnimationEnd={() => isDeleting && markDeletedInMainList(id, true)}
      data-testid="notification-tile"
    >
      <div className="notification__dot"></div>
      <div className="notification__message">
        <div className="notification__message__header">
          <div>
            <span>
              {eventType === "ARI_MISSING"
                ? "Add more ARI!"
                : "Rooms Sold out!"}
            </span>
            &nbsp;
            <Badge
              className="notification__tile__badge"
              content="Action needed"
            />
          </div>
          <span className="text-dark font-10">
            {formatDateString(createdAt)}
          </span>
        </div>
        <div className="notification__message__body">
          <div className="notification__message__body__text">
            {formatAlertText(alertText)}
          </div>
          <div className="notification__message__body__operations">
            {read ? (
              <button
                onClick={() => handleAlertAction("unread")}
                data-testid="mark-as-unread-icon"
                className="p-0 border-0 bg-transparent m-0"
                aria-label="Mark notification unread"
              >
                <MailOpen width={15} />
              </button>
            ) : (
              <button
                onClick={() => handleAlertAction("read")}
                data-testid="mark-as-read-icon"
                className="p-0 border-0 bg-transparent m-0"
                aria-label="Mark notification read"
              >
                <MailIcon width={15} />
              </button>
            )}
            <button
              onClick={() => handleAlertAction("delete")}
              data-testid="delete-icon"
              className="p-0 border-0 bg-transparent m-0"
              aria-label="Delete notification"
            >
              {read ? (
                <CloseReadIcon className="mx-1" width={15} />
              ) : (
                <CloseIcon className="mx-1" width={15} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationTile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    isRead: PropTypes.bool,
    eventType: PropTypes.string,
    createdAt: PropTypes.string,
    alertText: PropTypes.string,
    userActions: PropTypes.arrayOf(
      PropTypes.shape({
        isRead: PropTypes.bool,
      })
    ),
  }).isRequired,
  count: PropTypes.number.isRequired,
  setCount: PropTypes.func.isRequired,
  updateReadInMainList: PropTypes.func.isRequired,
  markDeletedInMainList: PropTypes.func.isRequired,
};

export default NotificationTile;
