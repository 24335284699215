import React, { useEffect, useMemo, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { Container, Row, Col, Toggle, Button } from "rsuite";
import { isNaN } from "lodash";
import cx from "classnames";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import advancedFormat from "dayjs/plugin/advancedFormat";
import PromotionSummary from "../../../components/promotion-summary/PromotionSummary";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import { ReactComponent as CloseIcon } from "./../../../assets/img/close.svg";
import AriList from "./AriList";
import AllotmentMessage from "./AllotmentMessage";
import RoomsARIStatus from "./RoomsARIStatus";
import TaxInfo from "./TaxInfo";
import { updateARI } from "../../../api";
import {
  selectRoomtypes,
  selectSelectedRoomtype,
} from "../../../slices/roomtypeSlice";
import { useAriContext } from "../../../contexts/AriContext";
import { usePropertyStore } from "../../../contexts/PropertyStoreContext";
import "./right-panel.scss";

dayjs.extend(advancedFormat);

export const hasNoARIChanges = (ariData, allotment, rate, isOpened) => {
  return (
    Number(ariData.inventory) === Number(allotment) &&
    Number(ariData.price) === Number(rate) &&
    ariData.isOpen === isOpened
  );
};

const SpecificDateRightPanel = React.forwardRef(
  (
    {
      rateplan,
      isOffline,
      promos,
      selectedDateCell,
      setSelectedDateCell,
      className = "",
      startDate,
      endDate,
      showingSpecificDateModal,
      setShowingSpecificDateModal,
      ...props
    },
    ref
  ) => {
    const { refetchAriData, ariData: contextAriData } = useAriContext();
    const { date, ariData: selectedAriData } = selectedDateCell ?? {};
    const ariData = useMemo(
      () =>
        contextAriData?.find(
          (d) =>
            d.date === selectedAriData?.date &&
            d.roomtypeId === selectedAriData?.roomtypeId
        ) || {},
      [contextAriData, selectedAriData]
    );

    const [showModal, setShowModal] = useState(false);
    const [allotment, setAllotment] = useState(ariData.inventory || 0);
    const [rate, setRate] = useState(ariData.price || 0);
    const [isOpened, setIsOpened] = useState(ariData.isOpen);
    const [allotmentError, setAllotmentError] = useState({
      isError: false,
      isBlocker: false,
      message: "",
    });

    const rooms = useSelector(selectRoomtypes);
    const selectedRoomtype = useSelector(selectSelectedRoomtype);
    const { currency, sourceTax, adultPrices } = ariData;

    const { displayName, code } =
      rooms.find(({ id }) => id === selectedRoomtype) ?? {};

    const isOpenLabel = isOpened ? "Open" : "Closed";
    const dayDiff = date?.diff(dayjs(), "day") + 1;
    const isRestricted = dayDiff < ariData.minLeadTime;
    const { selectedProperty } = usePropertyStore();

    const verifyAndSetAllotment = (inputValue = 0) => {
      const value = Number(inputValue);
      const isBlocker = isNaN(value) || value < 0;
      const isError = isBlocker || value === 0;
      let message = "";
      if (isBlocker) {
        message = "Enter valid allotment number";
      } else if (isError) {
        message = "Add more rooms";
      }

      setAllotmentError({ isError, isBlocker, message });
      setAllotment(inputValue);
    };

    useEffect(() => {
      verifyAndSetAllotment(ariData.inventory);
      setRate(ariData.price);
      setIsOpened(ariData.isOpen);
    }, [ariData]);

    const updateARIForDate = () => {
      const payload = {
        propertyId: ariData.propertyId,
        dateRange: {
          startDate: date.format("YYYY-MM-DD"),
          endDate: date.format("YYYY-MM-DD"),
        },
        productUpdates: [
          {
            rateplanId: ariData.rateplanId,
            roomtypeId: ariData.roomtypeId,
            updates: [
              {
                ...ariData,
                isOpen: isOpened,
                inventory: Number(allotment),
                price:
                  typeof rate === "string" ? rate : Number(rate.toFixed(2)),
              },
            ],
          },
        ],
      };

      setShowModal(false);
      updateARI(payload)
        .then(() => {
          setAllotmentError({
            isError: false,
            isBlocker: false,
            message: "ARI updated successfully.",
          });
          setTimeout(() => {
            refetchAriData({
              propertyId: selectedProperty?.propertyId,
              rateplanId: rateplan?.value,
              rooms,
              startDate,
              endDate,
              includeNumberOfRoomsSold: true,
            });
            toast.success("ARI updated successfully.");
          }, 2000);
        })
        .catch(() => {
          toast.error("Failed to save updates.");
        });
    };

    const applicablePromos = promos?.filter(({ roomtypeIds }) =>
      roomtypeIds.split(",").includes(selectedRoomtype?.toString())
    );

    return (
      <Container
        className={cx("right-panel-container right-panel-wrapper", className)}
        ref={ref}
        {...props}
      >
        <Row>
          <Col xs={22} className="right-panel-header-one">
            {date?.format("dddd, Do MMM")}
          </Col>
          <Col
            xs={2}
            className="date-clear"
            onClick={() => {
              setShowingSpecificDateModal(false);
              // to ensure that form gets hidden to avoid the validation error
              setTimeout(() => setSelectedDateCell(), 300);
            }}
          >
            <CloseIcon />
          </Col>
        </Row>
        <Row className="right-panel-header-two">
          <Col className="header-rateplan-name">
            <span>{displayName}</span>
          </Col>
          <Col>
            <span>{` | ${code}`}</span>
          </Col>
        </Row>

        <PromotionSummary
          promos={applicablePromos} // when date is selected from calender, rateplan details should not be shown.
          date={date}
        />
        <RoomsARIStatus
          roomsSold={ariData.roomsSold}
          allotment={ariData.inventory}
        />

        {isOffline ? (
          <div className="date-update-form">
            <AllotmentMessage allotmentError={allotmentError} />
            <FormGroup>
              <Row
                className={cx({
                  "error-input-row": allotmentError.isError,
                })}
              >
                <Col xs={12} className="row-label">
                  Allotment
                </Col>
                <Col xs={12}>
                  <Input
                    type="input"
                    name="allotment"
                    id="allotment"
                    disabled={isRestricted}
                    value={allotment ?? ""}
                    onChange={(event) => {
                      verifyAndSetAllotment(event?.target?.value);
                    }}
                  />
                </Col>
              </Row>
              <Row
                className={cx({
                  "error-input-row": isNaN(Number(rate)) || Number(rate) <= 0,
                })}
              >
                <Col xs={12} className="row-label">
                  {`Rate (${currency ?? rateplan?.data?.currencyCode ?? ""})`}
                </Col>
                <Col xs={12}>
                  <Input
                    type="input"
                    name="rate"
                    id="rate"
                    disabled={isRestricted}
                    value={rate ?? ""}
                    onChange={(event) => {
                      setRate(event?.target?.value);
                    }}
                  />
                </Col>
              </Row>
              <TaxInfo
                currency={currency ?? rateplan?.data?.currencyCode ?? ""}
                sourceTax={sourceTax}
                adultPrices={adultPrices}
              />
              <Row>
                <Col xs={12} className="row-label">
                  Availability
                </Col>
                <Col xs={12}>
                  <Toggle
                    size="sm"
                    disabled={isRestricted}
                    checked={isOpened}
                    onChange={() => setIsOpened(!isOpened)}
                    locale={{ on: "Open", off: "Closed" }}
                  >
                    {isOpenLabel}
                  </Toggle>
                </Col>
              </Row>
            </FormGroup>
          </div>
        ) : null}

        <AriList data={ariData} currency={currency} />

        {isOffline ? (
          <Row className="form-buttons">
            <Button
              className="cancel-btn"
              onClick={() => setSelectedDateCell()}
            >
              Cancel
            </Button>
            <Button
              className="save-btn"
              disabled={
                isRestricted ||
                allotmentError.isBlocker ||
                isNaN(Number(rate)) ||
                Number(rate) <= 0 ||
                hasNoARIChanges(ariData, allotment, rate, isOpened)
              }
              onClick={() => setShowModal(true)}
            >
              Save changes
            </Button>
          </Row>
        ) : null}
        <ConfirmModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={updateARIForDate}
          message={`You’re about to update the ARI (availability, rate, inventary) for ${date?.format("ddd Do MMMM")}. Are you happy to continue?`}
          title="Please confirm update"
        />
      </Container>
    );
  }
);

SpecificDateRightPanel.propTypes = {
  selectedDateCell: PropTypes.shape({
    ariData: PropTypes.shape({
      date: PropTypes.string,
      currency: PropTypes.string,
      inventory: PropTypes.number,
      roomsSold: PropTypes.number,
      isOpen: PropTypes.bool,
      price: PropTypes.number,
      sourceTax: PropTypes.string,
      adultPrices: PropTypes.arrayOf(PropTypes.number),
      propertyId: PropTypes.number,
      rateplanId: PropTypes.number,
      roomtypeId: PropTypes.number,
      minLeadTime: PropTypes.number,
    }).isRequired,
    date: PropTypes.instanceOf(dayjs).isRequired,
  }).isRequired,
  promos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      ruleName: PropTypes.string,
      ruleJson: PropTypes.string,
      bookingEnabled: PropTypes.bool,
      disabled: PropTypes.bool,
      roomtypeIds: PropTypes.string,
    })
  ),
  rateplan: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    data: PropTypes.shape({
      code: PropTypes.string,
      currencyCode: PropTypes.string,
    }),
  }),
  isOffline: PropTypes.bool,
  setSelectedDateCell: PropTypes.func.isRequired,
  className: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  showingSpecificDateModal: PropTypes.bool,
  setShowingSpecificDateModal: PropTypes.func.isRequired,
};

SpecificDateRightPanel.displayName = "SpecificDateRightPanel";

export default SpecificDateRightPanel;
