import React from "react";
import { Modal } from "rsuite";
import PropTypes from "prop-types"; // Import PropTypes
import ProgressLoader from "../progress-loader/ProgressLoader";

const LoaderModal = ({
  showLoader,
  setShowLoader,
  headerMessage = "Updating rate plan",
  description = "Please wait while we save your changes. This may take a few moments...",
}) => {
  return (
    <Modal
      open={showLoader}
      onClose={() => {
        setShowLoader(false);
      }}
      size="sm"
      backdrop="static"
      keyboard={false}
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px 20px",
        }}
      >
        {/* Centered Loader */}
        <ProgressLoader size="md" content="" />

        {/* Title */}
        <h5 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          {headerMessage}
        </h5>

        {/* Loading Message */}
        <p style={{ color: "#6C757D", fontSize: "14px", margin: 0 }}>
          {description}
        </p>
      </Modal.Body>
    </Modal>
  );
};

// Add PropTypes validation
LoaderModal.propTypes = {
  showLoader: PropTypes.bool.isRequired, // showLoader must be a boolean
  setShowLoader: PropTypes.func.isRequired, // setShowLoader must be a function
  headerMessage: PropTypes.string,
  description: PropTypes.string,
};

export default LoaderModal;
