import { Cell as RsuiteCell } from "rsuite-table";
import PropTypes from "prop-types";

const Cell = ({ dataKey, ...props }) => {
  return <RsuiteCell dataKey={dataKey} {...props} />;
};

Cell.propTypes = {
  dataKey: PropTypes.string.isRequired,
};

export default Cell;
