import React from "react";
import dayjs from "dayjs";
import { Row, Col } from "rsuite";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import GiftIcon from "../../assets/img/gift.svg";
import CoffeeIcon from "../../assets/img/coffee.svg";
import CoinHandIcon from "../../assets/img/coin-hand.svg";
import {
  getAppliesToRule,
  getDiscountValueFromRule,
  safeParseJson,
} from "../../constants/utils";
import DiscountPopover from "./DiscountPopover";
import { selectSelectedRateplanCurrency } from "../../slices/rateplanSlice";
import "./promotion-summary.scss";

const PromotionSummary = ({ promos, rateplan, date }) => {
  const currency = useSelector(selectSelectedRateplanCurrency);
  const {
    data: { isRefundable, isBreakfastIncluded },
  } = rateplan ?? { data: {} };
  return (
    <div className="promotion-container">
      {promos?.length
        ? promos.map((promo, index) => {
            const { id, ruleName, ruleJson } = promo;
            const rule = safeParseJson(ruleJson);
            const discountValue = getDiscountValueFromRule(rule, currency);
            const { stayStartDate, stayEndDate } = getAppliesToRule(rule);

            if (
              (date && stayStartDate && date.isBefore(dayjs(stayStartDate))) ||
              (date && stayEndDate && date.isAfter(dayjs(stayEndDate)))
            ) {
              return null;
            }

            return (
              <Row key={id}>
                <Col xs={3}>
                  {index === 0 ? (
                    <div className="promotion-wrapper-icon">
                      <img
                        src={GiftIcon}
                        className="promotion-icon"
                        alt="promotions"
                      />
                    </div>
                  ) : null}
                </Col>
                <Col xs={14} className="promotion-label text-truncate">
                  {ruleName}
                </Col>
                <Col xs={4} className="discount-value">
                  {discountValue}
                </Col>
                <Col xs={3}>
                  <DiscountPopover
                    rule={rule}
                    promo={promo}
                    discountValue={discountValue}
                  />
                </Col>
              </Row>
            );
          })
        : null}
      {isBreakfastIncluded ? (
        <Row className="mt-4">
          <Col xs={3}>
            <div className="breakast-wrapper-icon">
              <img
                src={CoffeeIcon}
                alt="Food icon"
                className="breakfast-icon"
              />
            </div>
          </Col>
          <Col xs={21} className="promotion-label">
            Bed & Breakfast included
          </Col>
        </Row>
      ) : null}

      {isRefundable ? (
        <Row className="mt-4">
          <Col xs={3}>
            <div className="coin-hand-wrapper-icon">
              <img
                src={CoinHandIcon}
                alt="promotion icon"
                className="coin-hand-icon"
              />
            </div>
          </Col>
          <Col xs={21} className="promotion-label">
            Refundable
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

PromotionSummary.propTypes = {
  promos: PropTypes.array,
  rateplan: PropTypes.shape({
    isRefundable: PropTypes.bool,
    isBreakfastIncluded: PropTypes.bool,
  }),
  date: PropTypes.instanceOf(dayjs),
};

export default PromotionSummary;
