import React, { useState } from "react";
import {
  Row,
  Col,
  DateRangePicker,
  CheckPicker,
  Tag,
  Form,
  Toggle,
  Button,
  Checkbox,
  Input,
} from "rsuite";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { beforeToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import Field from "./Field";
import { model, getBulkUpdatePayload } from "./utils";
import { updateARI } from "../../api";
import { selectRoomtypes } from "../../slices/roomtypeSlice";
import { selectSelectedRateplanCurrency } from "../../slices/rateplanSlice";
import { useAriContext } from "../../contexts/AriContext";
import { usePropertyStore } from "../../contexts/PropertyStoreContext";
import LoaderModal from "../loader-modal/LoaderModal";
import "./bulk-update.scss";

const BulkUpdate = ({ rateplan, startDate, endDate }) => {
  const formRef = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = useState({
    roomTypes: [],
    allotment: "",
    rate: "",
    availability: true,
    bulkDates: [],
  });

  const rooms = useSelector(selectRoomtypes);
  const currency = useSelector(selectSelectedRateplanCurrency);
  const { refetchAriData } = useAriContext();
  const { selectedProperty } = usePropertyStore();

  const renderValue = (values) => {
    if (values.length === 1) {
      const room = rooms.find(({ id }) => id === values[0]);
      return <Tag>{String(room.displayName)}</Tag>;
    }
    return <Tag>{`Selected ${values.length}/${rooms.length} rooms`}</Tag>;
  };

  const cleanSelectedRooms = () => {
    setFormValue({ ...formValue, roomTypes: [] });
  };

  const handleTagSelect = (values) => {
    setFormValue({ ...formValue, roomTypes: values });
  };

  const onAllRoomsChanged = (_, checked) => {
    if (checked) {
      setFormValue({ ...formValue, roomTypes: rooms.map((room) => room.id) });
    } else {
      cleanSelectedRooms();
    }
  };

  const handleAvailabilityChange = (value) => (value ? "Open" : "Closed");

  const updateBulkData = async () => {
    setIsLoading(true);
    try {
      if (!formRef.current.check()) {
        setIsLoading(false);
        return;
      }
      const payload = getBulkUpdatePayload(
        formValue,
        rateplan.value,
        rateplan.data.propertyId
      );
      await updateARI(payload);
      setTimeout(() => {
        refetchAriData({
          propertyId: selectedProperty?.propertyId,
          rateplanId: rateplan?.value,
          rooms,
          startDate,
          endDate,
          includeNumberOfRoomsSold: true,
        });
        toast.success("Successfully updated.");
        setIsLoading(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      toast.error("Failed to save updates.");
      setIsLoading(false);
    }
  };

  const allRoomCheckBox = (
    <div
      aria-selected={formValue.roomTypes.length === rooms.length}
      aria-disabled="false"
      id="rs-:rl:-opt-all"
      data-key="all-rooms"
      aria-posinset="1"
      aria-setsize={rooms.length}
      className="rs-picker-list-item"
      tabIndex="-1"
    >
      <Checkbox
        checked={formValue.roomTypes.length === rooms.length}
        onChange={onAllRoomsChanged}
        className="rs-check-item all-room-checkbox"
      >
        All Rooms
      </Checkbox>
    </div>
  );

  return (
    <div className="bulk-update-container">
      <span className="bulk-update-header">Bulk update</span>
      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        className="form-container"
      >
        <Row className="single-row">
          <Col xs={24}>
            <Field
              accepter={DateRangePicker}
              shouldDisableDate={beforeToday()}
              name="bulkDates"
              errorMessage={formError.bulkDates}
              editable={false}
              block
              value={formValue.bulkDates}
              placement="bottomEnd"
              placeholder="Select date range"
              format="yyyy-MM-dd"
              ranges={[]}
            />
          </Col>
        </Row>
        <Row className="single-row">
          <Col xs={24}>
            <Field
              className="room-type-picker"
              accepter={CheckPicker}
              name="roomTypes"
              errorMessage={formError.roomTypes}
              placeholder="Choose room types"
              block
              data={rooms}
              labelKey="displayName"
              valueKey="id"
              searchable
              value={formValue.roomTypes}
              onSelect={handleTagSelect}
              onClean={cleanSelectedRooms}
              renderValue={renderValue}
              renderMenu={(menu) => {
                return (
                  <>
                    {!(
                      React.isValidElement(menu) &&
                      menu.props?.children === "No results found"
                    ) && (
                      <>
                        {allRoomCheckBox}
                        {menu}
                      </>
                    )}
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <Row className="single-row">
          <Col xs={12}>
            <Field
              accepter={Input}
              name="allotment"
              label="Allotment"
              errorMessage={formError.allotment}
              editable={false}
              value={formValue.allotment}
            />
          </Col>
          <Col xs={12}>
            <Field
              accepter={Input}
              name="rate"
              label={`Rate (${currency})`}
              errorMessage={formError.rate}
              editable={false}
              value={formValue.rate}
            />
          </Col>
        </Row>
        <Row className="single-row">
          <Col xs={24}>
            <Field
              accepter={Toggle}
              name="availability"
              label="Availability"
              errorMessage={formError.availability}
              value={formValue.availability}
              child={handleAvailabilityChange(formValue.availability)}
            />
          </Col>
        </Row>
        <Row className="single-row row-btn">
          <Col xs={24}>
            <Button
              block
              appearance="primary"
              loading={isLoading}
              onClick={updateBulkData}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Form>
      <LoaderModal showLoader={isLoading} setShowLoader={setIsLoading} />
    </div>
  );
};

BulkUpdate.propTypes = {
  rateplan: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    data: PropTypes.shape({
      code: PropTypes.string,
      propertyId: PropTypes.number,
    }),
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default BulkUpdate;
