import PeoplesIcon from "@rsuite/icons/Peoples";
import CouponIcon from "@rsuite/icons/Coupon";
import GridIcon from "@rsuite/icons/Grid";
import BlockIcon from "@rsuite/icons/Block";
import CalendarIcon from "@rsuite/icons/Calendar";

export const searchFields = {
  lastName: {
    label: "Last Name",
    icon: PeoplesIcon,
  },
  firstName: {
    label: "First Name",
    icon: PeoplesIcon,
  },
  htConfirmationCode: {
    label: "HT Confirmation Code",
    icon: GridIcon,
  },
  pmsId: {
    label: "PMS ID",
    icon: CouponIcon,
  },
  sourceRoomConfirmationNumber: {
    label: "CRS ID",
    icon: CouponIcon,
  },
  cancelledReservation: {
    label: "Cancelled Reservation",
    icon: BlockIcon,
  },
  checkIn: {
    label: "Check In Dates",
    icon: CalendarIcon,
  },
  checkOut: {
    label: "Check Out Dates",
    icon: CalendarIcon,
  },
  bookingDate: {
    label: "Booking Dates",
    icon: CalendarIcon,
  },
};

export const cancelledData = [
  "Exclude cancelled",
  "Only cancelled",
  "Include cancelled",
].map((item, idx) => ({
  label: item,
  value: `${idx}`,
}));

export const DEFAULT_PAGE_SIZE = 10;

export const INIT_FORM_VALUE = {
  lastName: "",
  firstName: "",
  htConfirmationCode: "",
  pmsId: "",
  sourceRoomConfirmationNumber: "",
  cancelledReservation: null,
  checkIn: null,
  checkOut: null,
  bookingDate: null,
};

export const DEFAULT_COLUMNS = [
  {
    dataKey: "htConfirmationCode",
    exportKey: "includeHtConfirmationId",
    header: "Conf ID",
    label: "Conf ID",
    width: 150,
  },
  {
    dataKey: "demandClient",
    exportKey: "includeDemandClient",
    header: "Demand Client",
    label: "Demand Client",
    width: 150,
  },
  {
    dataKey: "status",
    exportKey: "includeStatus",
    header: "Status",
    label: "Status",
    width: 150,
  },
  {
    dataKey: "rateplan",
    exportKey: "includeRateplan",
    header: "Rate Plan",
    label: "Rate Plan",
    width: 100,
  },
  {
    dataKey: "pmsId",
    exportKey: "includePmsId",
    header: "PMS ID",
    label: "PMS ID",
    width: 100,
  },
  {
    dataKey: "sourceRoomConfirmationNumber",
    exportKey: "includeSourceRoomConfirmationNumber",
    header: "CRS ID",
    label: "CRS ID",
    width: 150,
  },
  {
    dataKey: "cxlId",
    exportKey: "includeCxlId",
    header: "CXL ID",
    label: "CXL ID",
    width: 100,
  },
  {
    dataKey: "repushVcc",
    header: "Repush VCC",
    label: "Repush VCC",
    width: 150,
  },
  {
    dataKey: "bookingCreationDate",
    exportKey: "includeBookingCreationDate",
    header: "Booking Creation Date",
    label: "Booking Creation Date",
    width: 200,
  },
  {
    dataKey: "arrival",
    exportKey: "includeArrivalDate",
    header: "Arrival",
    label: "Arrival Date",
    width: 150,
  },
  {
    dataKey: "departure",
    exportKey: "includeDepartureDate",
    header: "Departure",
    label: "Departure Date",
    width: 150,
  },
  {
    dataKey: "guestName",
    exportKey: "includeGuestName",
    header: "Guest Name",
    label: "Guest Name",
    width: 150,
  },
  {
    dataKey: "noOfGuests",
    exportKey: "includeNoOfGuests",
    header: "Number of guests",
    label: "Number of guests",
    width: 180,
  },
  {
    dataKey: "roomType",
    exportKey: "includeRoomType",
    header: "Room type",
    label: "Room type",
    width: 180,
  },
  {
    dataKey: "currency",
    exportKey: "includeCurrency",
    header: "Currency",
    label: "Currency",
    width: 150,
  },
  {
    dataKey: "payable",
    exportKey: "includePayable",
    header: "Payable",
    label: "Payable",
    width: 150,
  },
  {
    dataKey: "tax",
    exportKey: "includeTax",
    header: "Tax",
    label: "Tax",
    width: 100,
  },
  {
    dataKey: "payableTotal",
    exportKey: "includePayableTotal",
    header: "Payable total",
    label: "Payable total",
    width: 150,
  },
];

export const ALL_COLUMNS = [
  {
    dataKey: "serialNumber",
    header: "#",
    label: "Serial No",
    width: 50,
  },
  ...DEFAULT_COLUMNS,
  {
    dataKey: "lt",
    exportKey: "includeLt",
    header: "LT",
    label: "Lead Time",
    width: 70,
  },
  {
    dataKey: "bookingCreationTime",
    exportKey: "includeBookingCreationTime",
    header: "Booking Creation Time (EST)",
    label: "Booking Creation Time (EST)",
    width: 230,
  },
  {
    dataKey: "lengthOfStay",
    exportKey: "includeLengthOfStay",
    header: "LOS",
    label: "Length of stay",
    width: 70,
  },
  {
    dataKey: "dateAndTimeOfSale",
    exportKey: "includeDateAndTimeOfSale",
    header: "Date & Time of Sale",
    label: "Date and time of sale",
    width: 200,
  },
  {
    dataKey: "cancellationCutOff",
    exportKey: "includeCancellationCutOffEmt",
    header: "Cancellation Cut-Off(EST)",
    label: "Cancellation Cut-Off(EST)",
    width: 220,
  },
  {
    dataKey: "cancellationCutOffLocal",
    exportKey: "includeCancellationCutOffLocal",
    header: "Cancellation cut-off (Local)",
    label: "Cancellation cut-off (Local)",
    width: 230,
  },
  {
    dataKey: "payablePaymentType",
    exportKey: "includePayablePaymentType",
    header: "Payable Payment Type",
    label: "Payable Payment Type",
    width: 200,
  },
  {
    dataKey: "cancellationDate",
    exportKey: "includeCancellationDate",
    header: "Cancellation date",
    label: "Cancellation date",
    width: 180,
  },
  {
    dataKey: "paymentPolicy",
    exportKey: "includePaymentPolicy",
    header: "Payment policy",
    label: "Payment policy",
    width: 150,
  },
];
