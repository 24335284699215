import { combineSlices, configureStore } from "@reduxjs/toolkit";
import rateplanSlice from "./slices/rateplanSlice";
import roomtypeSlice from "./slices/roomtypeSlice";
import ariDateRangeSlice from "./slices/ariDateRangeSlice";

const rootReducer = combineSlices(
  rateplanSlice,
  roomtypeSlice,
  ariDateRangeSlice
);

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
  });

  return store;
};

export const store = makeStore();
