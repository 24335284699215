import { Button, Pagination } from "rsuite";
import PageNextIcon from "@rsuite/icons/PageNext";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import PropTypes from "prop-types";
import "./TableFooter.scss";

const TableFooter = ({
  total,
  loading,
  pageSize,
  currentPage,
  onPageChange,
  maxButtons,
}) => {
  return (
    <div className="table-footer">
      <div className="table-footer__left">
        <Button
          disabled={currentPage === 1}
          appearance={currentPage === 1 ? "default" : "ghost"}
          startIcon={<PagePreviousIcon />}
          loading={loading}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </Button>
      </div>
      <div className="table-footer__right">
        <Pagination
          activePage={currentPage}
          limit={pageSize}
          total={total}
          onChangePage={onPageChange}
          size="sm"
          layout={["pager"]}
          maxButtons={maxButtons}
          ellipsis
          boundaryLinks
          className="table-footer__pagination"
        />
      </div>

      <div className="table-footer__right">
        <Button
          disabled={currentPage === Math.ceil(total / pageSize)}
          appearance={
            currentPage === Math.ceil(total / pageSize) ? "default" : "ghost"
          }
          endIcon={<PageNextIcon />}
          loading={loading}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

TableFooter.propTypes = {
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  maxButtons: PropTypes.number.isRequired,
};

export default TableFooter;
