import React from "react";
import "./calendar-guide.scss";
import { Row, Col } from "rsuite";
import cx from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as DollarIcon } from "../../assets/img/dollar.svg";
const CalendarGuide = ({ className }) => {
  return (
    <div className={cx("calendar-guide-container", className)}>
      <div className="calendar-guide-sub-container">
        <span className="calendar-guide-header">Calendar guide</span>
        <Row className="calendar-guide-top-row">
          <Col xs={3}>
            <DollarIcon />
          </Col>

          <Col xs={3}>
            <span className="text-align dollar-text">
              Rate (before tax and promo)
            </span>
          </Col>
        </Row>

        <Row className="calendar-guide-row">
          <Col xs={3}>
            <span className="badge available rounded-circle">
              <span className="visually-hidden">available</span>
            </span>
          </Col>
          <Col xs={3}>
            <span className="text-align">Rooms to sell</span>
          </Col>
        </Row>
        <Row className="calendar-guide-row">
          <Col xs={3}>
            <span className="badge sold-out rounded-circle">
              <span className="visually-hidden">sold out</span>
            </span>
          </Col>
          <Col xs={3}>
            <span className="text-align">No rooms left</span>
          </Col>
        </Row>
        <Row className="calendar-guide-row">
          <Col xs={3}>
            <span className="badge restrictions-apply rounded-circle">
              <span className="visually-hidden">restrictions apply</span>
            </span>
          </Col>
          <Col xs={3}>
            <span className="text-align">Restrictions apply</span>
          </Col>
        </Row>
        <Row className="calendar-guide-row">
          <Col xs={3}>
            <span className="badge closed rounded-circle">
              <span className="visually-hidden">closed</span>
            </span>
          </Col>
          <Col xs={3}>
            <span className="text-align">Closed</span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

CalendarGuide.propTypes = {
  className: PropTypes.string,
};

export default CalendarGuide;
