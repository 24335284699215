import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button, Container, Form } from "rsuite";
import RatePlan from "../ratePlan/RatePlan";
import DateRange from "../dateRange/DateRange";
import {
  selectManageARIDateRange,
  setManageARIDateRange,
} from "../../slices/ariDateRangeSlice";
import { selectSelectedRateplan } from "../../slices/rateplanSlice";
import "./manageari.scss";

const ManageAri = () => {
  const storeDateRange = useSelector(selectManageARIDateRange);
  const [dateRange, setDateRange] = useState(storeDateRange);
  const selectedRateplanDetail = useSelector(selectSelectedRateplan);
  const dispatch = useDispatch();

  useEffect(() => {
    setDateRange(storeDateRange);
  }, [storeDateRange]);

  const handleManageARI = () => {
    if (selectedRateplanDetail && dateRange && dateRange.length === 2) {
      dispatch(setManageARIDateRange(dateRange));
    } else {
      toast.error("Please select Rate Plan and Date Range");
    }
  };
  return (
    <Container>
      <Form>
        <Row className="manageari-container">
          <RatePlan data-testid="rate-plan-component" />
          <DateRange
            value={dateRange}
            setValue={setDateRange}
            data-testid="date-range-component"
          />
          <Col>
            <Button
              appearance="primary"
              type="submit"
              onClick={handleManageARI}
              className="manageari-button"
            >
              Manage ARI
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ManageAri;
