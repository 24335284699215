import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Form } from "rsuite";

const Field = React.forwardRef((props, ref) => {
  const { name, message, label, accepter, error, child, ...rest } = props;
  const containerRef = useRef(null);

  return (
    <Form.Group
      controlId={`${name}-10`}
      ref={ref}
      className={error ? "has-error" : ""}
    >
      {label ? <Form.ControlLabel>{label} </Form.ControlLabel> : null}
      <div ref={containerRef}>
        {child ? (
          <Form.Control
            name={name}
            accepter={accepter}
            errorMessage={error}
            container={() => containerRef.current}
            {...rest}
          >
            {child}
          </Form.Control>
        ) : (
          <Form.Control
            name={name}
            accepter={accepter}
            errorMessage={error}
            container={() => containerRef.current}
            {...rest}
          />
        )}
        <Form.HelpText>{message}</Form.HelpText>
      </div>
    </Form.Group>
  );
});
Field.displayName = "Field";
Field.propTypes = {
  name: PropTypes.string.isRequired,
  message: PropTypes.string,
  label: PropTypes.string,
  accepter: PropTypes.any,
  error: PropTypes.string,
  child: PropTypes.node,
};

export default Field;
