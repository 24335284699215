import React from "react";
import PropTypes from "prop-types";

const TabsContext = React.createContext({
  activeTab: 0,
  setActiveTab: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  focusedTab: null,
  setFocusedTab: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

TabsContext.propTypes = {
  activeTab: PropTypes.number, // The index of the currently active tab
  setActiveTab: PropTypes.func.isRequired,
  focusedTab: PropTypes.number, // The index of the currently focused tab
  setFocusedTab: PropTypes.func.isRequired,
};

export default TabsContext;
