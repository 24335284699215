import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import objectPlugin from "dayjs/plugin/toObject";
import weekdayPlugin from "dayjs/plugin/weekday";
import weekOfYearPlugin from "dayjs/plugin/weekOfYear";
import isSameOrAfterPlugin from "dayjs/plugin/isSameOrAfter";
import DateCell from "./DateCell";

const formatDateObject = (date, currentMonth) => {
  const clonedObject = { ...date.toObject() };

  const formattedObject = {
    date: date,
    serverFormatDate: date.format("YYYY-MM-DD"),
    dayDiff: date.diff(dayjs(), "day") + 1,
    // month: clonedObject.months,
    // year: clonedObject.years,
    isCurrentMonth: clonedObject.months === currentMonth.month(),
    isPastDay: date.isBefore(dayjs(), "day"),
    isGreaterThanOneYear: date.isSameOrAfter(dayjs().add(1, "year"), "day"),
  };

  return formattedObject;
};

dayjs.extend(objectPlugin);
dayjs.extend(weekdayPlugin);
dayjs.extend(weekOfYearPlugin);
dayjs.extend(isSameOrAfterPlugin);

const Cells = ({
  ariDataList,
  currentMonth,
  selectedDateCell,
  setSelectedDateCell,
}) => {
  const [weeks, setWeeks] = useState([]);

  const getAllDays = useCallback(() => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formatted = formatDateObject(currentDate, currentMonth);

      weekDates.push(formatted);

      if (weekCounter === 7) {
        allDates.push({ dates: weekDates, week: currentDate.week() }); // .week() requires weekOfYearPlugin
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }

    setWeeks(allDates);
  }, [currentMonth]);

  useEffect(() => {
    getAllDays();
  }, [getAllDays]);

  return (
    <div className="month">
      {weeks.map(({ week, dates }) => {
        return (
          <div className="row week" key={week}>
            {dates.map(
              ({
                date,
                dayDiff,
                isPastDay,
                isCurrentMonth,
                serverFormatDate,
                isGreaterThanOneYear,
              }) => {
                const ariData = ariDataList?.find(
                  (d) => d.date === serverFormatDate
                );
                return (
                  <DateCell
                    key={date}
                    date={date}
                    isPastDay={isPastDay}
                    isCurrentMonth={isCurrentMonth}
                    isSelected={selectedDateCell === date}
                    onDateClick={setSelectedDateCell}
                    isGreaterThanOneYear={isGreaterThanOneYear}
                    ariData={ariData}
                    dayDiff={dayDiff}
                  />
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
};

Cells.propTypes = {
  ariDataList: PropTypes.array,
  selectedDateCell: PropTypes.instanceOf(dayjs),
  setSelectedDateCell: PropTypes.func.isRequired,
  currentMonth: PropTypes.object.isRequired,
};

export default Cells;
