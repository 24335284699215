import { HeaderCell as RsuiteHeaderCell } from "rsuite-table";
import PropTypes from "prop-types";

const HeaderCell = ({ children, ...props }) => {
  return <RsuiteHeaderCell {...props}>{children}</RsuiteHeaderCell>;
};

HeaderCell.defaultProps = {
  children: null,
};

HeaderCell.propTypes = {
  children: PropTypes.node,
};

export default HeaderCell;
